import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createHtmlPortalNode, OutPortal } from "react-reverse-portal";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getConnectionsWaitingForAdmission,
  getOwnerData
} from "./presenter-selectors";
import { WebSocketContext } from "../root/root-component";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { DialogAnswer } from "../modal/dialog";
import { OpenControlComponent } from "./presenter-components/open-control-component";
import { PresentationComponent } from "../presentation/presentation-component";
import { PresentationDownload } from "../content-management/presentation-download";
import { Control } from "../control/control-component";
import { DefaultScenePlaceholder } from "../presentation/presentation-components/default-scene";
import { VideoConferencePanel } from "../video-conference/video-conference-panel";
import { Hint } from "../ux/hint";

import { ReactComponent as IconEllipsis } from "client/assets/images/24/ellipsis.svg";
import { ReactComponent as IconPlay } from "client/assets/images/24/play.svg";
import { ReactComponent as IconStop } from "client/assets/images/24/stop.svg";

export const PresenterSession = (props: {
  sessionEnteredWithAction: boolean;
  onSessionEntered: () => void;
}) => {
  const [codeActive, setCodeActive] = useState(false);
  const [controlActive, setControlActive] = useState(false);
  const [drawerActive, setDrawerActive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);

  const connectionsWaiting = useSelector(getConnectionsWaitingForAdmission);
  const sessionData = useSelector(getOwnerData);

  const wsApi = useContext(WebSocketContext);
  const confirm = useCreateConfirmationDialog();

  const [portalsHolder] = useState({
    bigScreen: createHtmlPortalNode(),
    sidePanel: createHtmlPortalNode()
  });

  const controlLink = `${window.location.origin}/control/${sessionData?.sessionState.id}`;

  const toggleControl = useCallback(() => {
    setControlActive(!controlActive);
  }, [controlActive]);

  const toggleDrawer = useCallback(() => {
    setDrawerActive(!drawerActive);
  }, [drawerActive]);

  const handleSidebar = useCallback((show) => {
    setSidebarActive(show);
  }, []);

  useEffect(() => {
    (async () => {
      if (connectionsWaiting && connectionsWaiting.length !== 0) {
        const answer = await confirm({
          title: `Do you want to admit ${connectionsWaiting[0].username}?`,
          description: `The viewer is connected using invitation sent to ${connectionsWaiting[0].email}.`,
          denyText: "Deny"
        });
        if (answer === DialogAnswer.OK) {
          wsApi?.admitConnection(connectionsWaiting[0].socketId);
        } else {
          wsApi?.rejectAdmission(connectionsWaiting[0].socketId);
        }
      }
    })();
    // eslint-disable-next-line
  }, [wsApi, connectionsWaiting]);

  return (
    <div
      className={cx("app", {
        "has-control": controlActive,
        "has-people": sidebarActive
      })}
      data-view="presenter"
    >
      {/* FIXME:  */}
      {drawerActive && (
        <div
          onClick={toggleDrawer}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2
          }}
        />
      )}

      <header className="app__head" data-presenter="head">
        <div className={cx({ backdrop: codeActive })} />
        <div className="app__inset">
          {/* item */}
          <div>
            <button
              type="button"
              className={cx({
                "is-active": drawerActive
              })}
              data-toggle="menu"
              onClick={toggleDrawer}
            >
              <IconEllipsis />
            </button>

            {/* drawer */}
            <section
              data-presenter="drawer"
              className={cx({
                "is-active": drawerActive,
                "is-mute": codeActive
              })}
            >
              <div className="u-flex u-justify --between">
                <button
                  className="quit-session-button"
                  onClick={() => wsApi?.stopSession()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "arrow-circle-left"]}
                    size="2x"
                    color="#004aff"
                  />
                  Quit session
                </button>
              </div>

              <h4>
                {sessionData?.sessionState.scheduled
                  ? `Session – ${sessionData?.sessionState.scheduledTitle}`
                  : `Test Run – ${sessionData?.sessionState.presentationTitle}`}
              </h4>
              <div
                data-drawer="open-control"
                className={cx({ "is-active": codeActive })}
                onClick={() => setCodeActive(!codeActive)}
              >
                <OpenControlComponent controlLink={controlLink} primary />
              </div>
            </section>
            {/* drawer */}
          </div>

          {/* item */}
          <div>
            <Hint
              label={`${
                !sessionData?.presentationStarted ? "Start" : "Stop"
              } presentation`}
              placement="bottom"
            >
              <span
                className="control --large"
                data-control={
                  !sessionData?.presentationStarted ? "play" : "stop"
                }
                onClick={() =>
                  !sessionData?.presentationStarted
                    ? wsApi?.startPresentation()
                    : wsApi?.stopPresentation()
                }
              >
                <button type="button" className="control__element">
                  {!sessionData?.presentationStarted ? (
                    <IconPlay />
                  ) : (
                    <IconStop />
                  )}
                </button>
              </span>
            </Hint>
          </div>

          {/* item */}
          <div>
            {props.sessionEnteredWithAction &&
              sessionData?.sessionState &&
              sessionData.sessionState.id &&
              sessionData.conferenceState.presenterConferenceUserId && (
                <VideoConferencePanel
                  sessionId={sessionData.sessionState.id}
                  presenterId={
                    sessionData.conferenceState.presenterConferenceUserId
                  }
                  users={sessionData.connections}
                  bigScreenPortal={portalsHolder.bigScreen}
                  sidePanelPortal={portalsHolder.sidePanel}
                  sidebar={sidebarActive}
                  onSidebar={handleSidebar}
                />
              )}
          </div>
        </div>

        {/* FIXME: */}
        <div className="button-group --start --block" data-toggle="control">
          <button
            onClick={toggleControl}
            className={cx("control-button", {
              "is-active": controlActive
            })}
          >
            <FontAwesomeIcon
              icon={["fas", "arrow-circle-right"]}
              size="2x"
              color="#004aff"
            />
            Controls
          </button>
        </div>

        <hr className="u-none" />
      </header>

      <main className="app__body">
        <div className="scene-wrapper">
          {sessionData?.presentationStarted &&
          props.sessionEnteredWithAction ? (
            <PresentationComponent
              authenticated
              state={sessionData}
              volume={1}
            />
          ) : sessionData?.presentationStopped ? (
            <div
              className="container --narrow"
              style={{
                color: "WHITE",
                display: "grid",
                height: "100%",
                placeItems: "center"
              }}
            >
              <div>
                <div
                  className="u-text --center"
                  style={{ marginBottom: "4rem" }}
                >
                  <h2>Presentation Stopped</h2>
                  <p>
                    You can restart the presentation by clicking start or quit
                    the session.
                  </p>
                </div>
                <PresentationDownload
                  assets={sessionData.sessionState.assetUrls}
                  onlyRemove
                />
              </div>
            </div>
          ) : (
            <DefaultScenePlaceholder />
          )}
        </div>

        <OutPortal node={portalsHolder.bigScreen} />
      </main>

      <aside
        className={cx("app__sidebar --end", {
          "is-active": sidebarActive
        })}
        data-sidebar="people"
      >
        <hr className="u-off" />

        <OutPortal node={portalsHolder.sidePanel} />
      </aside>

      <aside
        className={cx("app__sidebar --start", {
          "is-active": controlActive
        })}
        data-sidebar="control"
      >
        <hr className="u-off" />

        <Control />
      </aside>

      {!props.sessionEnteredWithAction && (
        <div className="backdrop">
          <button
            className="button --primary --large"
            onClick={props.onSessionEntered}
          >
            Rejoin Session
          </button>
        </div>
      )}
    </div>
  );
};
