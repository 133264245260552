import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import debounce from "lodash/debounce";
import cx from "classnames";
import {
  SceneAssets,
  SceneAsset,
  WallSpecification
} from "shared/domain/presentation/presentation-selectors";
import { AssetType } from "shared/types/domain";
import { VideoAsset } from "./video-asset";
import { ImageAsset } from "./image-asset";
import { Logger as logger } from "purplex-logging";
import { WebSocketContext } from "client/modules/root/root-component";
import { PdfAsset } from "./pdf-asset";
import { UrlAsset } from "./url-asset";
import { TextAsset } from "./text-asset";

const BASE_Z_INDEX_FOR_ASSETS = 900;
const HIDDEN_Z_INDEX_FOR_ASSETS = 899;

const noop = (): void => {}; //eslint-disable-line

interface SceneAssetComponentProps {
  sceneAsset: SceneAsset;
  scaling: number;
  volume: number;
  wallSpecification?: WallSpecification;
  onClick: () => void;
  onTimeUpdated: (time: number, duration: number) => void;
  onPaused: () => void;
  onPlaying: () => void;
}

export const DraggableSceneAsset: React.FC<SceneAssetComponentProps> = (
  props
) => {
  const SERVER_SYNC_DEBOUNCE_MS = 250;
  const { left, top, id } = props.sceneAsset;
  const { wallSpecification } = props;
  const wallNumber = wallSpecification?.num || 1;

  const [localLeft, setLocalLeft] = useState<number | null>(null);
  const [localTop, setLocalTop] = useState<number | null>(null);

  const api = useContext(WebSocketContext);

  useEffect(() => {
    setLocalLeft((localLeft) => {
      if (localLeft === null) {
        return left;
      } else {
        return localLeft;
      }
    });
  }, [setLocalLeft, left]);

  useEffect(() => {
    setLocalTop((localTop) => {
      if (localTop === null) {
        return top;
      } else {
        return localTop;
      }
    });
  }, [setLocalTop, top]);

  const ref = useRef<(id: number, left: number, top: number) => void>();
  useEffect(() => {
    logger.debug("Recreating thorttled move asset handler");
    ref.current = debounce(
      (id: number, left: number, top: number) =>
        api?.moveAsset(id, left, top, wallNumber),
      SERVER_SYNC_DEBOUNCE_MS
    );
  }, [api, wallNumber]);

  useEffect(() => {
    if (localLeft !== null && localTop !== null) {
      if (ref.current) {
        ref.current(id, localLeft, localTop);
      }
    }
  }, [api, localLeft, localTop, id]);

  const [dragging, setDragging] = useState(false);

  const onDrag = useCallback(
    (_: DraggableEvent, data: DraggableData) => {
      setLocalLeft((left) => (left as number) + data.deltaX);
      setLocalTop((top) => (top as number) + data.deltaY);
    },
    [setLocalTop, setLocalLeft]
  );

  if (localLeft !== null && localTop !== null && wallSpecification) {
    return (
      <Draggable
        disabled={!props.sceneAsset.visible}
        bounds={{
          left: 0,
          top: 0,
          right:
            wallSpecification.end -
            wallSpecification.left -
            (props.sceneAsset.width * props.sceneAsset.scale) / 100,
          bottom:
            wallSpecification.height -
            (props.sceneAsset.height * props.sceneAsset.scale) / 100
        }}
        position={{ x: localLeft, y: localTop }}
        onDrag={(...params) => {
          setDragging(true);
          onDrag(...params);
        }}
        onStop={() => {
          if (!dragging) {
            props.onClick();
          }
          setDragging(false);
        }}
        scale={props.scaling}
      >
        <div>
          <SceneAssetComponent
            {...props}
            onClick={noop}
            sceneAsset={{ ...props.sceneAsset, top: localTop, left: localLeft }}
          />
        </div>
      </Draggable>
    );
  } else {
    return <></>;
  }
};

export const MoveableSceneAsset: React.FC<SceneAssetComponentProps> = (
  props
) => (
  <div
    className="assetPosition"
    style={{
      transform: `translate(${props.sceneAsset.left}px, ${props.sceneAsset.top}px)`
    }}
  >
    <SceneAssetComponent {...props} />
  </div>
);

const getSceneAssetDimension = (
  sceneAsset: SceneAssetComponentProps["sceneAsset"]
) => {
  // Text assets has dimensions auto
  if (sceneAsset.type === AssetType.ASSET_TEXT) {
    return {};
  } else {
    return {
      width: sceneAsset.cropWidth,
      height: sceneAsset.cropHeight
    };
  }
};

const SceneAssetComponent: React.FC<SceneAssetComponentProps> = ({
  sceneAsset,
  volume,
  onClick,
  onTimeUpdated,
  onPaused,
  onPlaying
}) => {
  return (
    <div
      className={cx({
        "asset": true,
        "is-active": sceneAsset.visible,
        "--link": sceneAsset.playAs === AssetType.ASSET_URL
      })}
      style={{
        ...getSceneAssetDimension(sceneAsset),
        zIndex: sceneAsset.layer,
        transform: `scale(${sceneAsset.scale / 100})`,
        transformOrigin: `top left`,
        borderRadius: sceneAsset.rounded
          ? `${(20 / sceneAsset.scale) * 100}px`
          : 0,
        overflow: "hidden"
      }}
      onClick={sceneAsset.visible ? onClick : noop}
    >
      <div
        className="asset__inset"
        style={{
          opacity: sceneAsset.opacity / 100
        }}
      >
        {sceneAsset.playAs === AssetType.ASSET_TEXT &&
          sceneAsset.textMetadata && (
            <TextAsset
              {...sceneAsset.textMetadata}
              width={sceneAsset.cropWidth}
              height={sceneAsset.cropHeight}
            />
          )}
        {sceneAsset.playAs === AssetType.ASSET_IMAGE && (
          <ImageAsset
            type={sceneAsset.type}
            glow={sceneAsset.glow}
            url={sceneAsset.url}
            cropOffsetY={sceneAsset.cropOffsetY}
            cropOffsetX={sceneAsset.cropOffsetX}
            cropWidth={sceneAsset.cropWidth}
            cropHeight={sceneAsset.cropHeight}
            width={sceneAsset.width}
            height={sceneAsset.height}
          />
        )}
        {sceneAsset.playAs === AssetType.ASSET_VIDEO && (
          <VideoAsset
            id={sceneAsset.id}
            type={sceneAsset.type}
            muted={false}
            volume={volume}
            glow={sceneAsset.glow}
            url={sceneAsset.url}
            autoplay={sceneAsset.autoplay}
            playing={sceneAsset.playing}
            loop={sceneAsset.loop}
            visible={sceneAsset.visible}
            onTimeUpdate={onTimeUpdated}
            onPaused={onPaused}
            onPlaying={onPlaying}
          />
        )}
        {sceneAsset.playAs === AssetType.ASSET_PDF && (
          <PdfAsset
            url={sceneAsset.url}
            page={sceneAsset.page as number}
            width={sceneAsset.width}
            height={sceneAsset.height}
            glow={sceneAsset.glow}
          />
        )}
        {sceneAsset.playAs === AssetType.ASSET_URL && (
          <UrlAsset title={sceneAsset.title} url={sceneAsset.url} />
        )}
      </div>
    </div>
  );
};

export const SceneAssetsComponent: React.FC<{
  authenticated?: boolean;
  volume: number;
  sceneAssets: SceneAssets;
  scaling: number;
  wallSpecification?: WallSpecification;
  onTimeUpdated: (assetId: number, time: number, duration: number) => void;
  onPaused: (assetId: number) => void;
  onPlaying: (assetId: number) => void;
}> = ({
  sceneAssets,
  scaling,
  volume,
  authenticated,
  wallSpecification,
  onTimeUpdated,
  onPaused,
  onPlaying
}) => {
  const api = useContext(WebSocketContext);
  const onAssetClicked = useCallback(
    (assetId: number) => {
      logger.debug(`Asset ${assetId} clicked`);
      api?.assetClicked(assetId);
    },
    [api]
  );

  const sortedSceneAssets = [
    ...sceneAssets.map((sceneAsset) => ({
      ...sceneAsset,
      layer: sceneAsset.visible
        ? BASE_Z_INDEX_FOR_ASSETS + sceneAsset.layer
        : HIDDEN_Z_INDEX_FOR_ASSETS
    }))
  ];
  sortedSceneAssets.sort((a, b) => a.layer - b.layer);

  return (
    <>
      {sortedSceneAssets.map((sceneAsset) => {
        const SceneAssetComponent =
          authenticated && !sceneAsset.positionLocked
            ? DraggableSceneAsset
            : MoveableSceneAsset;
        return (
          <SceneAssetComponent
            key={sceneAsset.id}
            sceneAsset={sceneAsset}
            scaling={scaling}
            volume={volume}
            onClick={() => onAssetClicked(sceneAsset.id)}
            onTimeUpdated={(value, duration) =>
              onTimeUpdated(sceneAsset.id, value, duration)
            }
            onPaused={() => onPaused(sceneAsset.id)}
            onPlaying={() => onPlaying(sceneAsset.id)}
            wallSpecification={wallSpecification}
          />
        );
      })}
    </>
  );
};
