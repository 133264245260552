import React from "react";
import { redirectToLogin } from "./redirect-to-login";
import { getConfig } from "../root/root-selectors";
import { useSelector } from "react-redux";

export const LoggedOut = () => {
  const appConfig = useSelector(getConfig);

  const logIn = () => {
    if (appConfig) {
      redirectToLogin(
        appConfig.CMS_URL as string,
        appConfig.OAUTH_CLIENT_ID as string,
        {
          url: window.location.origin
        }
      );
    }
  };

  return (
    <div className="app">
      <header className="app__head">
        <div className="app__inset --flex">
          <h1 className="app__title">Hyro Online Lobby</h1>
          <button className="button" onClick={logIn}>
            Log in with CMS
          </button>
        </div>

        <hr className="u-off" />
      </header>

      <main className="app__body">
        <div className="app__inset container --narrow">
          <p className="message --success">You have been logged out!</p>
        </div>
      </main>
    </div>
  );
};
