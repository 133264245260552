import { useEffect, useState } from "react";

const CACHED_ASSET_KEY = "assets";

export const useCachedAsset = (originalSrc: string | undefined) => {
  const [src, setSrc] = useState<undefined | string>(undefined);

  useEffect(() => {
    (async () => {
      if (originalSrc) {
        const cache = await caches.open(CACHED_ASSET_KEY);
        const result = await cache.match(originalSrc);

        if (result) {
          const blob = await result.blob();

          setSrc(URL.createObjectURL(blob));
        } else {
          setSrc(originalSrc);
        }
      }
    })();
  }, [originalSrc]);

  return src;
};
