import React, { useEffect } from "react";
import { useCachedAsset } from "./use-cached-asset";

export const CachedVideo = (
  props: React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > & {
    innerRef: React.MutableRefObject<HTMLVideoElement | null>;
    onCacheResolved: () => void;
  }
) => {
  const src = useCachedAsset(props.src);
  const { innerRef, onCacheResolved, ...restProps } = props;

  useEffect(() => {
    if (src) {
      onCacheResolved();
    }
  }, [onCacheResolved, src]);

  return src ? <video {...restProps} src={src} ref={innerRef} /> : <></>;
};
