import React, { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import * as ValidateLib from "validate.js";

import { Logger as logger } from "purplex-logging";
import { processEmailString } from "../../../shared/utils";
import { useApi } from "../api/use-api";
import { ChipInput } from "../ux/chip-input";
import { useNotifications } from "../ux/notifications";

export interface ScheduledSessionsFormData {
  date: string;
  description: string;
  end_time: string;
  instance_uuid: string;
  invitations: string;
  start_time: string;
  title: string;
  updated: boolean;
}

export const ScheduledSessionsForm = (props: {
  onSubmit: (values: ScheduledSessionsFormData) => Promise<void>;
  presentationId: number;
  initialValues?: ScheduledSessionsFormData;
  onSendInvitations?: () => void;
  onDeleteInvitations?: () => void;
}) => {
  const { presentationId, onSubmit, initialValues } = props;

  const [presentation, setPresentation] = useState<{
    instances: string[];
    title: string;
  }>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [instances, setInstances] = useState<any>([]);

  const api = useApi();
  const notifications = useNotifications();

  useEffect(() => {
    (async () => {
      if (presentationId) {
        try {
          const { presentation } = (await api?.getPresentation(
            presentationId
          )) as { presentation: { instances: string[]; title: string } };
          setPresentation(presentation);
          const instances = await Promise.all(
            presentation.instances.map(async (id: string) => {
              const { instance } = await api?.getInstanceConfig(id);
              return { id, ...instance };
            })
          );
          setInstances(instances);
        } catch (e) {
          setErrorMessage(
            "Error fetching presentation data, it does not exist, you don't have access to it or CMS connection is down."
          );
          notifications.error("Error fetching presentation data");
        }
      }
    })();
  }, [api, presentationId, initialValues, notifications]);

  const debounceSave = useCallback(
    _.debounce(async (values: any) => {
      if (errorMessage) {
        return;
      }
      logger.debug("submitting debounce");
      await onSubmit(values);
      logger.debug("submitting debounce done");
    }, 1000),
    [onSubmit]
  );

  const validate = useCallback((values: ScheduledSessionsFormData) => {
    const errors: Partial<ScheduledSessionsFormData> = {};
    if (
      moment(values.end_time, [moment.ISO_8601, "HH:mm"]) <=
      moment(values.start_time, [moment.ISO_8601, "HH:mm"])
    ) {
      errors.end_time = "Session must end after it starts. ";
    }
    if (
      moment(values.date) <
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    ) {
      errors.date = "Can not plan a session in past. ";
    }

    if (values.invitations) {
      const invitations = processEmailString(values.invitations);

      if (
        !invitations.every((value) => {
          const validationResult = ValidateLib.validate(
            { from: value },
            {
              from: {
                email: true
              }
            }
          );
          return !validationResult;
        })
      ) {
        errors.invitations = "There is an invalid email address";
      }
    }

    return errors;
  }, []);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={(formProps) => {
          const {
            handleSubmit,
            values,
            valid,
            dirty,
            submitting,
            active,
            errors
          } = formProps;

          (async () => {
            if (
              initialValues &&
              values &&
              valid &&
              dirty &&
              !submitting &&
              !active
            ) {
              await debounceSave(values);
              // Without reset form is still dirty after submitting is done and fires save again
              formProps.form.reset(values);
            }
          })();

          return (
            <div className="container">
              {!initialValues && (
                <h3
                  style={{
                    fontSize: "1.1rem",
                    display: "inline-flex",
                    alignItems: "center"
                  }}
                >
                  <FontAwesomeIcon
                    title="Presentation title"
                    icon={["fas", "angle-right"]}
                    style={{ marginRight: "0.5rem" }}
                  />
                  {presentation?.title}
                </h3>
              )}

              <form className="form" data-grid="true" onSubmit={handleSubmit}>
                <p style={{ margin: 0 }}>
                  <label className="u-off">Title</label>
                  <Field
                    name="title"
                    component="input"
                    placeholder="Session title"
                    required
                  />
                </p>
                <p style={{ margin: 0 }}>
                  <label className="u-off">Description</label>
                  <Field
                    name="description"
                    component="textarea"
                    placeholder="Description"
                    rows="1"
                  />
                </p>
                <p style={{ margin: 0 }}>
                  <label className="u-off">
                    Date (session is valid for the whole day)
                  </label>
                  <Field
                    name="date"
                    component="input"
                    type="date"
                    placeholder="Date"
                    required
                    className="u-transform --upper"
                  />
                  {errors.date && (
                    <span className="form-message --danger">{errors.date}</span>
                  )}
                </p>
                <p style={{ margin: 0 }}>
                  <label className="u-off">Time</label>
                  <span
                    data-grid="true"
                    style={{
                      ["--grid-column-min" as string]: "100px"
                    }}
                  >
                    <Field
                      name="start_time"
                      component="input"
                      type="time"
                      placeholder="From"
                      required
                    />
                    <Field
                      name="end_time"
                      component="input"
                      type="time"
                      placeholder="To"
                      required
                    />
                  </span>
                  {errors.end_time && (
                    <span className="form-message --danger">
                      {errors.end_time}
                    </span>
                  )}
                </p>
                <p style={{ margin: 0 }}>
                  <label className="u-off">Room</label>
                  <span className="form-select">
                    <Field
                      name="instance_uuid"
                      component="select"
                      required
                      defaultValue={instances[0]?.id}
                    >
                      {instances.map(
                        (instance: { id: string; name: string }) => {
                          return (
                            <option value={instance.id} key={instance.id}>
                              {instance.name}
                            </option>
                          );
                        }
                      )}
                    </Field>
                    <FontAwesomeIcon
                      className="form-select__control"
                      icon={["fal", "angle-down"]}
                    />
                  </span>
                </p>
                <div
                  className="form-element u-direction --column"
                  style={{
                    alignItems: "flex-start",
                    height: "auto",
                    minHeight: 100,
                    paddingBottom: 8,
                    paddingTop: 8
                  }}
                >
                  <label className="u-off">Invitations</label>
                  <Field name="invitations">
                    {(props) => (
                      <ChipInput
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        uniqueValues={true}
                        placeholder="email@email.com"
                      />
                    )}
                  </Field>
                </div>
                <div data-grid="ignore">
                  {initialValues && initialValues.updated && (
                    <p className="message --warning u-flex">
                      <FontAwesomeIcon
                        icon={["fad", "exclamation-triangle"]}
                        size="lg"
                        swapOpacity
                      />
                      Let your participants know what changed by resending the
                      session invite. Updates are not automatically shared with
                      participants.
                    </p>
                  )}
                </div>
                <div data-grid="ignore">
                  {initialValues ? (
                    <div className="button-group --block --end u-align --base">
                      <button
                        type="button"
                        className="button --large"
                        onClick={props.onDeleteInvitations}
                      >
                        Cancel Session
                      </button>
                      {initialValues.updated && (
                        <button
                          type="button"
                          className="button --large"
                          onClick={props.onSendInvitations}
                        >
                          Resend Invite
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="u-text --right">
                      <button
                        type="submit"
                        className="button --primary --large"
                      >
                        Plan & Invite
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          );
        }}
      />

      {errorMessage && errorMessage !== "" && (
        <p className="message --danger u-flex" style={{ marginTop: "2rem" }}>
          <FontAwesomeIcon
            icon={["fad", "exclamation-triangle"]}
            size="lg"
            swapOpacity
          />
          {errorMessage}
        </p>
      )}
    </>
  );
};
