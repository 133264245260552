import React, { useState } from "react";
import { useSelector } from "react-redux";

import "../../assets/stylesheets/views/presenter.scss";
import { LoadingIndicator } from "../ux/loading-indicator";
import { getOwnerData } from "./presenter-selectors";
import { PresenterLobby } from "./presenter-lobby";
import { PresenterSession } from "./presenter-session";

export const PresenterIndex = () => {
  const PresenterFrontEndData = useSelector(getOwnerData);
  const [sessionEnteredWithAction, setSessionEnteredWithAction] = useState(
    false
  );

  return (
    <>
      {!PresenterFrontEndData?.sessionState?.running ? (
        <PresenterLobby
          onSessionEntered={() => setSessionEnteredWithAction(true)}
        />
      ) : (
        <PresenterSession
          sessionEnteredWithAction={sessionEnteredWithAction}
          onSessionEntered={() => setSessionEnteredWithAction(true)}
        />
      )}
    </>
  );
};
