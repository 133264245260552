import { combineReducers } from "redux";

import { authReducer } from "../auth/auth-slice";
import { configReducer } from "../config/config-slice";
import { sessionReducer } from "../web-sockets/session";
import { volumeControlReducer } from "../volume-control/volume-control-slice";

export const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  session: sessionReducer,
  volumeControl: volumeControlReducer
});

export type RootState = ReturnType<typeof rootReducer>;
