import { createSelector } from "@reduxjs/toolkit";
import { SessionConnectionStatus } from "shared/domain/connections/connections-slice";
import { getSession } from "../root/root-selectors";

const PRESENTER_STATUES = [SessionConnectionStatus.OWNER];
const VIEWER_STATUSES = [SessionConnectionStatus.JOINED_PRESENTATION];

export const isPresenter = createSelector(
  getSession,
  ({ connection }) =>
    connection &&
    connection.status &&
    PRESENTER_STATUES.includes(connection.status)
);

export const isViewer = createSelector(
  getSession,
  ({ connection }) =>
    connection &&
    connection.status &&
    VIEWER_STATUSES.includes(connection.status)
);
