import cx from "classnames";
import React, { useState, useRef, useCallback } from "react";
import { AssetType } from "shared/types/domain";
import { CachedVideo } from "./cached-assets/cached-video";
import {
  useGetMediaProgress,
  useSynchronizeMediaOnComponentMount,
  useSynchronizeMediaPlayback,
  useKeepSynchornizedWithBackendPlaying
} from "./media-progress-sync";
import { useKeepVolumeSynchronized } from "./media-volume";

export const VideoAsset = ({
  id,
  type,
  url,
  glow,
  autoplay,
  playing,
  loop,
  muted,
  volume,
  visible,
  onTimeUpdate,
  onPlaying,
  onPaused
}: {
  id: number | string;
  type: AssetType;
  url: string;
  glow: boolean;
  autoplay: boolean;
  playing: boolean;
  loop: boolean;
  muted: boolean;
  volume: number;
  visible: boolean;
  onTimeUpdate: (time: number, duration: number) => void;
  onPaused: () => void;
  onPlaying: () => void;
}) => {
  // Ref holding reference to video element
  const ref = useRef<HTMLVideoElement | null>(null);

  // Since the underlying DOM element is resolved from cache
  // we have to keep flag information when the element is resolved
  const [cacheResolved, setCacheResolved] = useState(false);

  // Important flag which is used to *disable* sending
  // progress to the server during initial mounting of the component
  // so that it doesn't reset itself but rather *prepopulate*
  // the progress with information from server.
  const [sendingProgress, changeSendingProgress] = useState(false);

  // Data from back-end about current progress - could be undefined
  // if data still haven't been delivered or 0 if media hasn't started
  const videoProgress = useGetMediaProgress(type, id);

  // Run all the hooks to sync
  useSynchronizeMediaOnComponentMount(
    ref,
    sendingProgress,
    cacheResolved,
    changeSendingProgress,
    videoProgress
  );
  useSynchronizeMediaPlayback(ref, sendingProgress, videoProgress);
  useKeepSynchornizedWithBackendPlaying(ref, playing, visible);
  useKeepVolumeSynchronized(ref, volume);

  const onCacheResolved = useCallback(() => {
    setCacheResolved(true);
  }, [setCacheResolved]);

  return (
    <CachedVideo
      innerRef={ref}
      className={cx({ glow, [type]: true })}
      src={url}
      autoPlay={playing && autoplay}
      muted={muted}
      loop={loop}
      onCacheResolved={onCacheResolved}
      onPlay={() => {
        onPlaying();
      }}
      onPause={() => {
        onPaused();
      }}
      onTimeUpdate={(ev) => {
        // If video is in metadata loading phase, it can already emit
        // one time update with duration being null - obviously that's nonsense
        if (sendingProgress && ev.currentTarget.duration) {
          onTimeUpdate(ev.currentTarget.currentTime, ev.currentTarget.duration);
        }
      }}
    />
  );
};
