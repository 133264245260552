import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apply_patch } from "jsonpatch";

import {
  ClientState,
  StatePatch
} from "../../../shared/domain/client-state-selectors";

const initialState: ClientState = {};

const { actions, reducer } = createSlice({
  name: "session",
  initialState,
  reducers: {
    setState: (
      state,
      action: PayloadAction<{
        data: ClientState;
      }>
    ) => {
      return {
        ...action.payload.data
      };
    },
    stateChanged: (
      state,
      action: PayloadAction<{ data: { [key: string]: StatePatch } }>
    ) => {
      return apply_patch(state, Object.values(action.payload.data));
    },
    resetState: () => ({
      ...initialState
    })
  }
});

export const sessionActions = actions;
export const sessionReducer = reducer;
