import io from "socket.io-client";
import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { getToken } from "../auth/auth-selectors";
import { buildWsClient } from "./ws-client";
import { MessageType } from "shared/domain/message-type";
import { sessionActions } from "./session";
import { ClientType } from "shared/types/client-type";
import { Logger as logger } from "purplex-logging";
import {
  ClientState,
  StatePatch
} from "../../../shared/domain/client-state-selectors";
import { getSessionId as getSessionIdOwner } from "../presenter-view/presenter-selectors";
import { getSessionId as getSessionIdVisitor } from "../visitor-view/visitor-selectors";
import { OnlineSessionDescription } from "shared/types/online-session-description";

export interface ConnectionCommand {
  type: ConnectionCommandType;
  payload: any;
}

export enum ConnectionCommandType {
  CONNECT_OWNER = "CONNECT_OWNER",
  CONNECT_OWNER_CONTROL = "CONNECT_OWNER_CONTROL",
  CREATE_UNPLANNED_SESSION = "CREATE_UNPLANNED_SESSION",
  CONNECT_VIEWER = "CONNECT_VIEWER"
}

const buildSocket = (
  connectionCommand: ConnectionCommand,
  token?: string | null
) => {
  logger.info("Creating Socket instance");

  return io("/", {
    transports: ["websocket"],
    reconnectionDelay: 300,
    upgrade: false,
    reconnection: true,
    autoConnect: false,
    query: {
      type: connectionCommand.type,
      payload: JSON.stringify(connectionCommand.payload),
      token
    }
  });
};

export const useWs = (connectCommand: ConnectionCommand) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const sessionStateHasChanged = useCallback(
    (data: { [key: string]: StatePatch }) => {
      dispatch(sessionActions.stateChanged({ data }));
    },
    [dispatch]
  );

  const setSessionState = useCallback(
    (data) => {
      dispatch(sessionActions.setState({ data }));
    },
    [dispatch]
  );

  const resetState = useCallback(() => {
    logger.debug("Resetting session state");
    dispatch(sessionActions.resetState());
  }, [dispatch]);

  const [localSocket, setSocket] = useState<null | SocketIOClient.Socket>(null);

  const sessionId = useSelector(
    [
      ConnectionCommandType.CONNECT_OWNER,
      ConnectionCommandType.CONNECT_OWNER_CONTROL
    ].includes(connectCommand.type)
      ? getSessionIdOwner
      : getSessionIdVisitor
  );

  useEffect(() => {
    if (sessionId && localSocket) {
      const onlineSession: OnlineSessionDescription = {
        sessionId,
        socketId: localSocket.id
      };
      logger.debug("Got sesion id & socketId");
      logger.debug("Session:", onlineSession);

      const cookies = new Cookies();
      cookies.set("onlineSession", JSON.stringify(onlineSession), {
        path: "/"
      });
    }
  }, [sessionId, localSocket]);

  useEffect(() => {
    const socket = buildSocket(
      {
        type: connectCommand.type,
        payload: {
          ...connectCommand.payload
        }
      },
      token?.access_token
    );

    socket.on("message", (type: MessageType, payload: unknown) => {
      switch (type) {
        case MessageType.INITIAL_STATE:
          if (payload) {
            setSessionState(payload as ClientState);
          }
          break;
        case MessageType.INCREMENTAL_STATE:
          if (payload) {
            sessionStateHasChanged(payload as { [key: string]: StatePatch });
          }
          break;
      }
    });

    socket.on("disconnect", () => {
      if (connectCommand.payload.clientType === ClientType.PRESENTER) {
        logger.debug("Resetting state");
        resetState();
      }
    });
    socket.connect();
    setSocket(socket);
  }, [token, dispatch, sessionStateHasChanged, resetState]);

  useEffect(() => {
    return () => resetState();
  }, [resetState]);

  const disconnect = useCallback(() => {
    localSocket?.disconnect();
  }, [localSocket]);

  return localSocket && { ...buildWsClient(localSocket), disconnect };
};
