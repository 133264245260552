import React from "react";
import { TextAssetMetadata, TextAssetFontSize } from "shared/types/domain";
import { useSelector } from "react-redux";
import {
  getWallHeight as getWallHeightPresenter,
  getWallWidth as getWallWidthPresenter
} from "client/modules/presenter-view/presenter-selectors";
import {
  getWallHeight as getWallHeightVisitor,
  getWallWidth as getWallWidthVisitor
} from "client/modules/visitor-view/visitor-selectors";
import { isPresenter as isPresenterSelector } from "client/modules/presentation/presentation-selectors";

const calculateFontSize = (fontSize: TextAssetFontSize, wallHeight: number) => {
  switch (fontSize) {
    case TextAssetFontSize.XS:
      return Math.round(wallHeight * 0.035);
    case TextAssetFontSize.S:
      return Math.round(wallHeight * 0.06);
    case TextAssetFontSize.L:
      return Math.round(wallHeight * 0.11);
    case TextAssetFontSize.XL:
      return Math.round(wallHeight * 0.135);
    case TextAssetFontSize.XXL:
      return Math.round(wallHeight * 0.16);

    case TextAssetFontSize.M:
    default:
      return Math.round(wallHeight * 0.085);
  }
};

export const TextAsset = ({
  textContent,
  fontSize,
  alignment,
  bold,
  italic,
  font,
  underline,
  color,
  width,
  height
}: TextAssetMetadata & { width: number; height: number }) => {
  const isPresenter = useSelector(isPresenterSelector);

  const wallHeight = useSelector(
    isPresenter ? getWallHeightPresenter : getWallHeightVisitor
  );
  const wallWidth = useSelector(
    isPresenter ? getWallWidthPresenter : getWallWidthVisitor
  );

  return (
    <div
      className="text-asset"
      style={{
        fontFamily: font,
        fontSize: `${calculateFontSize(fontSize, wallHeight)}px`,
        textAlign: alignment,
        fontStyle: italic ? "italic" : "normal",
        textDecoration: underline ? "underline" : "auto",
        fontWeight: bold ? "bold" : "normal",
        color,
        padding: `${wallHeight * 0.025}px`,
        width: (width * wallWidth) / 100,
        height: (height * wallHeight) / 100,
        wordBreak: "break-word",
        overflow: "hidden"
      }}
      dangerouslySetInnerHTML={{ __html: textContent }}
    />
  );
};
