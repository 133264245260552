import { throttle } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";

import { useAssetCache } from "./use-asset-cache";
import ProgressIndicator from "../ux/progress-indicator";
import { Logger as logger } from "purplex-logging";
import { DialogAnswer } from "../modal/dialog";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { BytesRenderer } from "../ux/bytes-renderer";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {};

const getAssetSize = async (asset: string) => {
  const response = await fetch(`/assets/size${asset}`);
  const data: { size: number } = await response.json();
  return data.size;
};

const useDownloadPresentation = (
  assets: { asset: string; size: number }[],
  onCacheChange = NOOP
) => {
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(0);

  const totalSize = assets.reduce((memo, value) => memo + value.size, 0);
  const cache = useAssetCache();

  const download = useCallback(() => {
    if (cache) {
      const changeProgress = throttle((progress: number, time: number) => {
        setProgress(progress);
        setEstimatedTime(time);
      }, 1000);

      const start = Date.now();
      let progressLocal = 0;

      const controller = new AbortController();
      const { signal } = controller;

      (async () => {
        try {
          setDownloading(true);
          await Promise.all(
            assets.map(async (asset) => {
              if (asset.size === 0) {
                return;
              }
              const response = await fetch(asset.asset, {
                signal
              });
              const reader = response.clone().body?.getReader();

              if (reader) {
                // eslint-disable-next-line no-constant-condition
                while (true) {
                  const { done, value } = await reader.read();
                  if (done) {
                    await cache.put(asset.asset, response);
                    onCacheChange();
                    break;
                  } else if (value) {
                    progressLocal += value.length;

                    const progress = Math.floor(
                      (progressLocal / totalSize) * 100
                    );
                    const time =
                      ((Date.now() - start) / progressLocal) *
                      (totalSize - progressLocal);
                    changeProgress(progress, time);
                  }
                }
              }
            })
          );
          setDownloading(false);
        } catch (e) {
          setDownloading(false);
          logger.error("Error while downloading assets", e);
          throw e;
        }
      })();

      return controller;
    }
  }, [assets, cache, totalSize, onCacheChange]);

  return {
    download,
    estimatedTime,
    progress,
    downloading
  };
};

const useGetMissingAssets = (assets: { asset: string; size: number }[]) => {
  const cacheStorage = useAssetCache();
  const [missingAssets, setMissingAssets] = useState<
    { asset: string; size: number }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (cacheStorage) {
        const tempAssets: { asset: string; size: number }[] = [];
        await Promise.all(
          assets.map(async (asset) => {
            const matches = await cacheStorage.match(asset.asset);
            if (!matches) {
              tempAssets.push(asset);
            }
          })
        );
        setMissingAssets(tempAssets);
      }
    })();
  }, [assets, cacheStorage]);

  return missingAssets;
};

const useGetDownloadedAssets = (assets: { asset: string; size: number }[]) => {
  const cacheStorage = useAssetCache();
  const [downloadedAssets, setDownloadedAssets] = useState<
    { asset: string; size: number }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (cacheStorage) {
        const tempAssets: { asset: string; size: number }[] = [];
        await Promise.all(
          assets.map(async (asset) => {
            const matches = await cacheStorage.match(asset.asset);
            if (matches) {
              tempAssets.push(asset);
            }
          })
        );
        setDownloadedAssets(tempAssets);
      }
    })();
  }, [assets, cacheStorage]);

  return downloadedAssets;
};

export const useRemoveAllCachedAssets = (onCacheChange = NOOP) => {
  const cacheStorage = useAssetCache();

  return useCallback(async () => {
    if (cacheStorage) {
      const allAssets = await cacheStorage?.keys();
      await Promise.all(allAssets.map((asset) => cacheStorage.delete(asset)));
      onCacheChange();
    }
  }, [cacheStorage, onCacheChange]);
};

const useGetCacheQuota = (localAssets: LocalAsset[]) => {
  const [quota, setQuota] = useState<number | undefined>();
  const [usage, setUsage] = useState<number | undefined>();

  useEffect(() => {
    (async () => {
      if (navigator && navigator.storage) {
        const { quota, usage } = await navigator.storage.estimate();
        setQuota(quota);
        setUsage(usage);
      }
    })();
  }, [localAssets]);
  return { quota, usage };
};

interface LocalAsset {
  asset: string;
  size: number;
}

export const PresentationDownload = ({
  assets,
  versionChanged,
  onUpdatePresentation,
  onAssetsReady,
  onAssetsMissing,
  viewer,
  onlyRemove,
  removeOnLeave
}: {
  assets: string[];
  versionChanged?: boolean;
  onUpdatePresentation?: () => void;
  onAssetsReady?: () => void;
  onAssetsMissing?: () => void;
  viewer?: boolean;
  onlyRemove?: boolean;
  removeOnLeave?: { startTime: string | undefined };
}) => {
  const [localAssets, setLocalAssets] = useState<LocalAsset[]>([]);
  const [controller, setController] = useState<AbortController | null>(null);

  const [downloadingError, setDownloadingError] = useState(false);
  const [aborting, setAborting] = useState(false);
  const confirm = useCreateConfirmationDialog();

  useEffect(() => {
    (async () => {
      // Assets size fetching should be done in next cycle to avoid race
      // condition where it still doesn't contain session id
      await new Promise((res) => setTimeout(res, 0));

      const result = await Promise.all(
        assets.map(async (asset) => {
          try {
            const size = await getAssetSize(asset);
            return { size, asset };
          } catch (e) {
            return { asset, size: 0 };
          }
        })
      );
      setLocalAssets(result);
    })();
    // eslint-disable-next-line
  }, [versionChanged]);

  const totalSize = localAssets.reduce(
    (accumulator, value) => accumulator + value.size,
    0
  );

  const missingAssets = useGetMissingAssets(localAssets);

  const downloadedAssets = useGetDownloadedAssets(localAssets);
  const downloadedAssetsSize = downloadedAssets.reduce(
    (accumulator, value) => accumulator + value.size,
    0
  );

  useEffect(() => {
    if (missingAssets.length === 0 && onAssetsReady) {
      onAssetsReady();
    } else if (missingAssets.length !== 0 && onAssetsMissing) {
      onAssetsMissing();
    }
  }, [missingAssets, onAssetsReady, onAssetsMissing]);

  const {
    download,
    progress,
    downloading
  } = useDownloadPresentation(missingAssets, () =>
    setLocalAssets([...localAssets])
  );

  const removeAllCachedAssets = useRemoveAllCachedAssets(() =>
    setLocalAssets([...localAssets])
  );

  const onRemove = useCallback(async () => {
    const answer = await confirm({
      title: "Delete downloaded content",
      description:
        "Are you sure you want to delete all downloaded content of this presentation?"
    });
    if (answer !== DialogAnswer.OK) {
      return;
    }
    removeAllCachedAssets();
  }, [removeAllCachedAssets, confirm]);

  const { quota, usage } = useGetCacheQuota(localAssets);

  const onDownload = useCallback(() => {
    try {
      const controlLocal = download();
      if (controlLocal) {
        setController(controlLocal);
      }
    } catch (e) {
      if (aborting) {
        setAborting(false);
        return;
      }
      setDownloadingError(true);
    }
  }, [download, aborting]);

  const onCancelDownload = useCallback(() => {
    setAborting(true);
    if (controller) {
      controller.abort();
    }
  }, [controller]);

  const isAvailableSizeError =
    !!quota && !!usage && quota - usage < totalSize - downloadedAssetsSize;

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (
        moment(removeOnLeave?.startTime).toDate() < moment().toDate() &&
        downloadedAssetsSize &&
        removeOnLeave
      ) {
        e.preventDefault();
        const message =
          "Content will be deleted once you close the session. Do you wish to continue?";
        e.returnValue = message;
        return message;
      }
    };
    const unloadHandler = () => {
      if (
        moment(removeOnLeave?.startTime).toDate() < moment().toDate() &&
        downloadedAssetsSize &&
        removeOnLeave
      ) {
        if (
          navigator &&
          navigator.serviceWorker &&
          navigator.serviceWorker.controller
        ) {
          navigator.serviceWorker.controller.postMessage({
            type: "removeData",
            slot: 1
          });
        }
      }
    };
    if (removeOnLeave) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
      window.addEventListener("unload", unloadHandler);
    }

    return () => {
      if (removeOnLeave) {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        window.removeEventListener("unload", unloadHandler);
      }
    };
  }, [downloadedAssetsSize, removeOnLeave, removeAllCachedAssets]);

  if (removeOnLeave && onlyRemove) {
    return <></>;
  }

  if (onlyRemove) {
    return (
      <div className="card --tertiary u-text --center">
        <h3>Remove Downloaded Files</h3>
        {downloadedAssetsSize ? (
          <>
            <p>
              For this presentation you've downloaded{" "}
              <BytesRenderer value={downloadedAssetsSize} /> of files. Remove
              these files if you will not attend this presentation in the
              future. It keeps your system running smoothly. Thank us later!
            </p>
            <p>
              <button
                className="button --primary"
                onClick={removeAllCachedAssets}
              >
                Remove All Downloads
              </button>
            </p>
          </>
        ) : (
          <p>All files removed!</p>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="card">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th style={{ paddingRight: "1rem" }}>
                {`Presentation size (${localAssets.length}) files`}
              </th>
              <td className="u-text --right">
                <BytesRenderer value={totalSize} />
              </td>
            </tr>
            <tr>
              <th>Files downloaded</th>
              <td className="u-text --right">
                <BytesRenderer value={downloadedAssetsSize} />
              </td>
            </tr>
            <tr>
              <th>HDD Space available</th>
              <td className="u-text --right">
                <BytesRenderer value={quota ?? 0} />
              </td>
            </tr>
            <tr>
              <th>Total in use</th>
              <td className="u-text --right">
                <BytesRenderer value={usage ?? 0} />
              </td>
            </tr>
          </tbody>
        </table>
        {viewer ? (
          <p>
            Please wait until the presenter starts the presentation. Make sure
            to download the content before start for a more stable and smooth
            experience
          </p>
        ) : (
          <p>
            We recommend you to store the presentation media locally before
            starting the session. This is to ensure a smooth and stable
            experience. Downloading may take a few minutes depending on your
            internet connection.
          </p>
        )}
      </div>
      {downloading && (
        <ProgressIndicator
          primary
          progress={progress}
          style={{ marginBottom: "2em" }}
          title="Downloading asset"
        />
      )}
      <p className="button-group --end u-float">
        {versionChanged ? (
          <button className="button --warning " onClick={onUpdatePresentation}>
            Update presentation
          </button>
        ) : (
          <>
            {missingAssets.length !== 0 ? (
              downloading ? (
                <button
                  className={cx({
                    "button": true,
                    "--danger": true
                  })}
                  onClick={onCancelDownload}
                >
                  Cancel
                </button>
              ) : (
                <button
                  className={cx({
                    button: true
                  })}
                  onClick={onDownload}
                >
                  Download
                </button>
              )
            ) : (
              <button
                className="button --danger"
                onClick={onRemove}
                disabled={downloadingError}
              >
                Delete content
              </button>
            )}
          </>
        )}
      </p>
      {downloadingError ||
        (isAvailableSizeError && (
          <div style={{ marginTop: 100 }}>
            {downloadingError && (
              <p className="message --danger">
                Unknown error has occurred, there seems to be a browser error.
                Try to connect with a different browser or continue without
                downloading the content first. Mind that not pre-downloading the
                content may affect the overall experience and may result in
                connection problems.
              </p>
            )}
            {isAvailableSizeError && (
              <p className="message --danger">
                There is not enough disk space available to download all the
                content. Clear space on your HDD or join the presentation
                anyway. Mind that not pre-downloading the content may affect the
                overall experience and may result in connection problems.
              </p>
            )}
          </div>
        ))}
    </>
  );
};
