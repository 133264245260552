import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SessionConnectionStatus {
  OWNER = "OWNER",
  OWNER_CONTROL = "OWNER_CONTROL",
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
  ALREADY_CONNECTED = "ALREADY_CONNECTED",
  AUTHENTICATED = "AUTHENTICATED",
  JOINED_PRESENTATION = "JOINED_PRESENTATION",
  KICKED_OUT = "KICKED_OUT"
}

export enum AdmissionStatus {
  NOT_ASKED = "NOT_ASKED",
  WAITING_FOR_ADMISSION = "WAITING_FOR_ADMISSION",
  ADMITTED = "ADMITTED",
  REJECTED = "REJECTED"
}

export interface SessionConnection {
  socketId: string;
  email: string;
  status: SessionConnectionStatus;
  pin: string | null;
  pinError: boolean;
  username: string;
  admissionStatus: AdmissionStatus;
  admissionDeniedError: boolean;
  connected: boolean;
  sessionToken?: string;
  conference: { token: string | null; conferenceUserId: number };
}

export type SessionConnectionClient = {
  socketId: string;
  email: string;
  status: SessionConnectionStatus;
  pinError: boolean;
  username: string;
  admissionStatus: AdmissionStatus;
  admissionDeniedError: boolean;
};

export interface ConnectionState {
  lastConferenceUserId: number;
  connections: SessionConnection[];
}
const initialState: ConnectionState = {
  lastConferenceUserId: 0,
  connections: []
};

const { actions, reducer } = createSlice({
  name: "connection",
  initialState,
  reducers: {
    clientConnected: (
      state,
      action: PayloadAction<{
        socketId: string;
        status: SessionConnectionStatus;
        email: string;
        username?: string;
        sessionToken?: string;
      }>
    ) => {
      const newConferenceUserId = state.lastConferenceUserId + 1;

      return {
        ...state,
        lastConferenceUserId: newConferenceUserId,
        connections: [
          ...state.connections,
          {
            socketId: action.payload.socketId,
            email: action.payload.email,
            status: action.payload.status,
            pin: null,
            pinError: false,
            username: action.payload.username || "",
            sessionToken: action.payload.sessionToken,
            admissionStatus: AdmissionStatus.NOT_ASKED,
            admissionDeniedError: false,
            connected: true,
            conference: {
              token: null,
              conferenceUserId: newConferenceUserId
            }
          }
        ]
      };
    },
    clientDisconnected: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.socketId === action.payload.socketId) {
            return {
              ...connection,
              connected: false
            };
          } else {
            return connection;
          }
        })
      };
    },
    clientReconnected: (
      state,
      action: PayloadAction<{
        socketId: string;
        newSocketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.socketId === action.payload.socketId) {
            return {
              ...connection,
              connected: true,
              socketId: action.payload.newSocketId
            };
          } else {
            return connection;
          }
        })
      };
    },
    clientAuthenticated: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.socketId === action.payload.socketId) {
            return {
              ...connection,
              status: SessionConnectionStatus.AUTHENTICATED
            };
          } else {
            return connection;
          }
        })
      };
    },
    setWrongPinError: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                pinError: true
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    clearPinError: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                pinError: false
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    setConferenceToken: (
      state,
      action: PayloadAction<{
        token: string;
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.socketId === action.payload.socketId) {
            return {
              ...connection,
              conference: {
                ...connection.conference,
                token: action.payload.token
              }
            };
          } else {
            return connection;
          }
        })
      };
    },
    setUsername: (
      state,
      action: PayloadAction<{
        socketId: string;
        username: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                username: action.payload.username
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    setAdmissionDeniedError: (
      state,
      action: PayloadAction<{
        socketId: string;
        username: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                admissionDeniedError: true
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    askForAdmission: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                admissionStatus: AdmissionStatus.WAITING_FOR_ADMISSION
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    connectionAdmitted: (
      state,
      action: PayloadAction<{
        socketId: string;
        conferenceToken: string;
        userId: number;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                admissionStatus: AdmissionStatus.ADMITTED,
                status: SessionConnectionStatus.JOINED_PRESENTATION,
                conference: {
                  ...connection.conference,
                  token: action.payload.conferenceToken
                }
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    connectionRejected: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                admissionStatus: AdmissionStatus.REJECTED
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    kickOutViewer: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                status: SessionConnectionStatus.KICKED_OUT
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    joinPresentation: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                status: SessionConnectionStatus.JOINED_PRESENTATION
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    leavePresentation: (
      state,
      action: PayloadAction<{
        socketId: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                status: SessionConnectionStatus.AUTHENTICATED
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    leaveOnStopSession: (state) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (
              connection.status === SessionConnectionStatus.JOINED_PRESENTATION
            ) {
              return {
                ...connection,
                status: SessionConnectionStatus.AUTHENTICATED
              };
            } else {
              return connection;
            }
          }
        )
      };
    },
    setPinCode: (
      state,
      action: PayloadAction<{
        socketId: string;
        pin: string;
      }>
    ) => {
      return {
        ...state,
        connections: state.connections.map(
          (connection): SessionConnection => {
            if (connection.socketId === action.payload.socketId) {
              return {
                ...connection,
                pin: action.payload.pin
              };
            } else {
              return connection;
            }
          }
        )
      };
    }
  }
});

export const ConnectionsActions = actions;
export const connectionsReducer = reducer;
