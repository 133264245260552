import { CommandType } from "../domain/command-type";

export enum AssetType {
  ASSET_VIDEO = "ASSET_VIDEO",
  ASSET_IMAGE = "ASSET_IMAGE",
  ASSET_SOUND = "ASSET_SOUND",
  ASSET_URL = "ASSET_URL",
  ASSET_PDF = "ASSET_PDF",
  ASSET_PRESENTATION = "ASSET_PRESENTATION",
  ASSET_TEXT = "ASSET_TEXT",
  ASSET_WALL_VIDEO = "ASSET_WALL_VIDEO",
  ASSET_WALL_IMAGE = "ASSET_WALL_IMAGE"
}

export interface CDNAsset {
  etag: string;
  size: number;
}

export interface File {
  uuid: string;
  fileVersion: number;
}

interface PdfAssetMetadata {
  pageCount: number;
}

interface UrlAssetMetadata {
  url: string;
}

export enum TextAssetFontStyle {
  Underline = "underline",
  Italic = "italic",
  Bold = "bold"
}

export enum TextAssetFontSize {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl"
}

enum TextAssetAlignment {
  Left = "left",
  Center = "center",
  Right = "right"
}

export interface TextAssetMetadata {
  colour: string;
  fontSize: TextAssetFontSize;
  bold: boolean;
  underline: boolean;
  italic: boolean;
  font: string;
  color: string;
  alignment: TextAssetAlignment;
  textContent: string;
}

export interface Asset extends File {
  type: AssetType;
  formatName: string;
  slices: AssetSlice[];
  playAs: AssetType;
  scale: AssetScale;
  pad: AssetPad;
  title: string;
  backgroundVersion: number | undefined;
  instanceUuid?: string;
  presentationId?: number;
  sceneId?: number;
}

export interface PdfAsset extends Asset {
  metadata: PdfAssetMetadata;
}

export interface UrlAsset extends Asset {
  metadata: UrlAssetMetadata;
}

export interface TextAsset extends Asset {
  metadata: TextAssetMetadata;
}

export interface AssetWithSize extends Asset {
  size: number;
}

export interface WallAsset extends Asset {}

export interface AssetSlice {
  width: number;
  height: number;
  num: number;
  leftOffset: number;
}

export interface AssetPad {
  width: number;
  height: number;
  top: number;
  left: number;
  bottom: number;
  edited?: boolean;
}

export interface AssetScale {
  width: number;
  height: number;
  top: number;
}

export type AssetWithCDNInfo = Asset & CDNAsset;

export interface SceneAsset {
  id: number;
  positionLocked: boolean;
  asset: Asset;
  left: number;
  top: number;
  scale: number;
  layer: number;
  opacity: number;
  width: number;
  height: number;
  visible: boolean;
  autoplay: boolean;
  loop: boolean;
  glow: boolean;
  rounded: boolean;
  cropHeight: number;
  cropWidth: number;
  cropOffsetX: number;
  cropOffsetY: number;
}

export interface Scene {
  id: number;
  title: string;
  order: number;
  assets: SceneAsset[];
  loop: boolean;
  autoplay: boolean;
  wall: WallAsset;
  autoswitch: boolean;
  backgroundVersion: number;
  transitionLoop: boolean;
  transitionFadeMs: number;
  transitionUseWholeVideo: boolean;
  transitionAsset?: Asset;
}

export interface Presentation {
  id: number;
  title: string;
  scenes: Scene[];
  assetActions: AssetAction[];
  description: string;
  presentationVersion: number;
}

export interface Command<T> {
  type: CommandType;
  payload: T;
}

export interface RoomSpecification {
  walls: {
    num: number;
    width: number;
    height: number;
    start: number;
    end: number;
  }[];
}

export interface AssetAction {
  id: number;
  sceneAssetId: number;
  targetSceneAssetId: number;
  targetSceneId: number;
}
