import { useSelector } from "react-redux";
import { memoize } from "lodash";

import { getToken } from "../auth/auth-selectors";
import { getAuthenticatedClient } from "./client";
import { getConfig } from "../root/root-selectors";

const memoizedAuthenticatedClient = memoize(getAuthenticatedClient);

export const useApi = () => {
  const token = useSelector(getToken);
  const appConfig = useSelector(getConfig);
  if (token) {
    return memoizedAuthenticatedClient(token.access_token, appConfig);
  } else {
    return null;
  }
};
