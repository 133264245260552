import React, { useCallback } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";

import { useRemoveAllCachedAssets } from "../content-management/presentation-download";
import { DialogAnswer } from "../modal/dialog";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { ScheduledSessionsList } from "./scheduled-sessions-list";
import { CreateScheduledSession } from "./create-scheduled-session";
import { useSelector } from "react-redux";
import { getUserFullName } from "../auth/auth-selectors";
import { useLogout } from "../auth/use-auth";

export const ScheduledSessionsLobby = () => {
  const { url } = useRouteMatch();

  const user = useSelector(getUserFullName);
  const confirm = useCreateConfirmationDialog();
  const removeAllCachedAssets = useRemoveAllCachedAssets();

  const logout = useLogout();

  const onRemove = useCallback(async () => {
    const answer = await confirm({
      title: "Delete downloaded content",
      description:
        "Are you sure you want to delete all content of all downloaded presentations?"
    });
    if (answer !== DialogAnswer.OK) {
      return;
    }
    removeAllCachedAssets();
  }, [removeAllCachedAssets, confirm]);

  return (
    <div className="app">
      <header className="app__head">
        <div className="app__inset --flex">
          <h1 className="app__title">Online Lobby</h1>
          <h1 className="app__title">{user}</h1>
          <span>
            <button
              className="button --primary"
              onClick={onRemove}
              data-qa="btn-delete-all-downloads"
            >
              Delete All Downloads
            </button>
            <button
              className="button"
              onClick={logout}
              style={{ marginLeft: "1rem" }}
              data-qa="btn-logout"
            >
              Log Out
            </button>
          </span>
        </div>
        <hr className="u-off" />
      </header>

      <main className="app__body">
        <div className="app__inset">
          <Switch>
            <Route path={`${url}`} exact>
              <ScheduledSessionsList />
            </Route>
            <Route path={`${url}create/:presentationId`}>
              <CreateScheduledSession />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
};
