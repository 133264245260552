import React, { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { OutPortal, createHtmlPortalNode } from "react-reverse-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import { PresentationDownload } from "../content-management/presentation-download";
import { PresentationComponent } from "../presentation/presentation-component";
import { DefaultScenePlaceholder } from "../presentation/presentation-components/default-scene";
import { WebSocketContext } from "../root/root-component";
import { VideoConferencePanel } from "../video-conference/video-conference-panel";
import { getPresentationVolume } from "../volume-control/volume-control-selectors";
import { getJoinedPresentationData } from "./visitor-selectors";

export const VisitorPresentation = () => {
  const [sidebarActive, setSidebarActive] = useState(false);

  const data = useSelector(getJoinedPresentationData);
  const presentationVolume = useSelector(getPresentationVolume);
  const wsApi = useContext(WebSocketContext);

  const [portalsHolder] = useState({
    bigScreen: createHtmlPortalNode(),
    sidePanel: createHtmlPortalNode()
  });

  const handleSidebar = useCallback((show) => {
    setSidebarActive(show);
  }, []);

  return (
    <div
      className={cx("app", {
        "has-people": sidebarActive
      })}
      data-view="presenter"
    >
      <header className="app__head" data-presenter="head">
        <div className="app__inset">
          {/* item */}
          <div className="u-align --middle" style={{ paddingLeft: 24 }}>
            <button
              className="quit-session-button"
              onClick={async () => {
                wsApi?.leavePresentation();
              }}
            >
              <FontAwesomeIcon
                icon={["fas", "arrow-circle-left"]}
                size="2x"
                color="#004aff"
              />
              Exit session
            </button>
          </div>

          {/* item */}
          <div>
            {data?.title && (
              <h1 data-presenter="title" data-clamp="1">
                {data?.title}
              </h1>
            )}
          </div>

          {/* item */}
          <div>
            {data &&
              data.sessionId &&
              data.conferenceState.presenterConferenceUserId && (
                <VideoConferencePanel
                  sessionId={data.sessionId}
                  presenterId={data.conferenceState.presenterConferenceUserId}
                  users={data.connections}
                  bigScreenPortal={portalsHolder.bigScreen}
                  sidePanelPortal={portalsHolder.sidePanel}
                  sidebar={sidebarActive}
                  onSidebar={handleSidebar}
                />
              )}
          </div>
        </div>

        <hr className="u-off" />
      </header>

      <main className="app__body">
        <div className="scene-wrapper">
          {data?.presentationStarted ? (
            <PresentationComponent state={data} volume={presentationVolume} />
          ) : data?.presentationStopped ? (
            <div
              className="container --narrow"
              style={{
                color: "WHITE",
                display: "grid",
                height: "100%",
                placeItems: "center"
              }}
            >
              <div>
                <div
                  className="u-text --center"
                  style={{ marginBottom: "4rem" }}
                >
                  <h2>Presentation Stopped</h2>
                  <p>
                    The presenter has stopped the presentation. Follow the
                    instructions of the presenter or exit the presentation.
                  </p>
                </div>
                <PresentationDownload assets={data?.assetUrls} onlyRemove />
              </div>
            </div>
          ) : (
            <DefaultScenePlaceholder />
          )}
        </div>
        <OutPortal node={portalsHolder.bigScreen} />
      </main>

      <aside
        className={cx("app__sidebar --end", {
          "is-active": sidebarActive
        })}
        data-sidebar="people"
      >
        <hr className="u-off" />

        <OutPortal node={portalsHolder.sidePanel} />
      </aside>
    </div>
  );
};
