import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { Logger as logger } from "purplex-logging";
import "./assets/stylesheets/main.scss";
import "./icons";
import { getUnauthenticatedClient } from "./modules/api/client";
import { ConfigActions } from "./modules/config/config-slice";
import { RootComponent } from "./modules/root/root-component";
import { rootReducer } from "./modules/root/root-reducer";
import { ConfirmationServiceProvider } from "./modules/modal/dialog-context";

export const main = async () => {
  const store = configureStore({ reducer: rootReducer });

  const api = getUnauthenticatedClient();
  const config = await api.getConfig();

  logger.setupClientLogging(
    config.LOGGING_LEVEL,
    config.APP_ENVIRONMENT,
    {
      app: "CLIENT",
      instanceId: config.VIX_URL,
      versionInfo: config.VERSION_INFO
    },
    config.SENTRY_DSN !== undefined
      ? { dsn: config.SENTRY_DSN, Sentry }
      : undefined,
    config.LOGZIO_CLIENT_CONFIG
  );

  store.dispatch(ConfigActions.configFetched({ config }));

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConfirmationServiceProvider>
          <RootComponent />
        </ConfirmationServiceProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // expose store when run in Cypress
  // @ts-ignore
  if (window.Cypress) {
    // @ts-ignore
    window.store = store;
  }
};
