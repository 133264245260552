import React, { useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import { ReactComponent as IconClose } from "client/assets/images/16/close.svg";

export enum DialogType {
  INFO = "INFO",
  CONFIRM = "CONFIRM"
}

export enum DialogAnswer {
  OK = "OK",
  CANCEL = "CANCEL"
}

export interface ConfirmationOptions {
  variant?: DialogType;
  title?: string;
  description?: string;
  confirmText?: string;
  denyText?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  confirmText,
  denyText,
  onSubmit,
  onClose
}) => {
  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (open && ev.keyCode === 27) {
        onClose();
      }
    },
    [open, onClose]
  );

  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onClose, onKeyUp]);

  const dialogueType = variant ?? DialogType.CONFIRM;

  return (
    <div className={cx("dialog", { "is-active": open })}>
      <div className="dialog__backdrop" onClick={onClose} />
      <section className="dialog__stage">
        <header className="dialog__head">
          <h2 className="dialog__title" data-clamp="1">
            {title ?? ""}
          </h2>
          {dialogueType === DialogType.INFO && (
            <button
              type="button"
              className="dialog__control --close"
              onClick={onClose}
            >
              <IconClose />
            </button>
          )}
        </header>
        <div className="dialog__body">{description}</div>
        <footer className="dialog__foot">
          <div className="button-group --end">
            {dialogueType === DialogType.CONFIRM && (
              <>
                <button type="button" className="button" onClick={onClose}>
                  {denyText || "Cancel"}
                </button>
                <button
                  type="button"
                  className="button --primary"
                  onClick={onSubmit}
                >
                  {confirmText || "Yes"}
                </button>
              </>
            )}
            {dialogueType === DialogType.INFO && (
              <button
                type="button"
                className="button --primary --wide u-transform --upper"
                onClick={onSubmit}
              >
                Ok
              </button>
            )}
          </div>
        </footer>
      </section>
    </div>
  );
};

export const DialogTemplatePlaceholder = () => {
  return <div id="dialog" />;
};

interface DialogTemplateProps {
  children: React.ReactNode;
  onClose: () => void;
  title?: string;
}

export const DialogTemplate: React.FC<DialogTemplateProps> = ({
  children,
  onClose,
  title
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childArray: any[] = React.Children.toArray(children);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredChildArray: any[] =
    childArray[0].type === React.Fragment
      ? childArray[0].props.children
      : childArray;

  const body = filteredChildArray.find(
    (child) => child && child.type === DialogBody
  );
  const footer = filteredChildArray.find(
    (child) => child && child.type === DialogFooter
  );

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onClose, onKeyUp]);

  return createPortal(
    <div className="dialog is-active">
      <div className="dialog__backdrop" onClick={onClose} />
      <section className="dialog__stage">
        <DialogHead hasCloseButton onClose={onClose} title={title} />
        <DialogBody>{body ? body.props.children : null}</DialogBody>
        {footer && <DialogFooter>{footer.props.children}</DialogFooter>}
      </section>
    </div>,
    document.getElementById("dialog") as HTMLDivElement
  );
};

export const DialogHead: React.FC<{
  hasCloseButton: boolean;
  onClose: () => void;
  title?: string;
}> = ({ hasCloseButton, onClose, title }) => {
  return (
    <header className="dialog__head">
      <h2 className="dialog__title" data-clamp="1">
        {title ?? ""}
      </h2>
      {hasCloseButton && (
        <button
          type="button"
          className="dialog__control --close"
          onClick={onClose}
        >
          <IconClose />
        </button>
      )}
    </header>
  );
};

export const DialogBody: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  return <div className="dialog__body">{children}</div>;
};

export const DialogFooter: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="dialog__foot">
      <div className="button-group --end">{children}</div>
    </div>
  );
};

export const DialogInset: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  return <div className="dialog__inset">{children}</div>;
};
