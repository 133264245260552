import { MessageType } from "shared/domain/message-type";
import { CommandType } from "shared/domain/command-type";

export const buildWsClient = (socket: SocketIOClient.Socket) => {
  function sendCommand<T>(type: CommandType, payload?: T) {
    socket.send(MessageType.SEND_COMMAND, { type: type, payload });
  }

  return {
    startPresentation: () => {
      sendCommand(CommandType.START_PRESENTATION);
    },
    stopPresentation: () => {
      sendCommand(CommandType.STOP_PRESENTATION);
    },
    restartPresentation: () => {
      sendCommand(CommandType.RESTART_PRESENTATION);
    },
    stopSession: () => {
      sendCommand(CommandType.STOP_SESSION);
    },
    startSession: () => {
      sendCommand(CommandType.START_SESSION);
    },
    updateAssets: () => {
      sendCommand(CommandType.UPDATE_ASSETS);
    },
    changeScene: (sceneId: number) => {
      sendCommand(CommandType.CHANGE_SCENE, sceneId);
    },
    changeWall: (wallNum: number) => {
      sendCommand(CommandType.CHANGE_WALL, wallNum);
    },
    toggleAssetVisibility: (assetId: number) => {
      sendCommand(CommandType.TOGGLE_ASSET, assetId);
    },
    toggleAssetPlaying: (assetId: number) => {
      sendCommand(CommandType.TOGGLE_ASSET_PLAYING, assetId);
    },
    assetClicked: (assetId: number) => {
      sendCommand(CommandType.ASSET_CLICKED, assetId);
    },
    togglePlayPauseWall: () => {
      sendCommand(CommandType.TOGGLE_PLAY_PAUSE_WALL);
    },
    changePlayback: (
      value: number,
      duration: number,
      assetId?: number,
      assetUuid?: string
    ) => {
      sendCommand(CommandType.CHANGE_PLAYBACK, {
        value,
        duration,
        assetId,
        assetUuid
      });
    },
    moveAsset: (
      assetId: number,
      left: number,
      top: number,
      wallNum: number
    ) => {
      sendCommand(CommandType.MOVE_ASSET, { assetId, left, top, wallNum });
    },
    pdfNextPage: (assetId: number) =>
      sendCommand(CommandType.PDF_NEXT_PAGE, assetId),
    pdfPrevPage: (assetId: number) =>
      sendCommand(CommandType.PDF_PREV_PAGE, assetId),
    setPresenterConferenceUserId: (presenterUserId: string | null) => {
      sendCommand(
        CommandType.SET_PRESENTER_CONFERENCE_USER_ID,
        presenterUserId
      );
    },
    videoPaused: (assetId?: number, assetUuid?: string) => {
      sendCommand(CommandType.VIDEO_PAUSED, { assetUuid, assetId });
    },
    videoPlaying: (assetId?: number, assetUuid?: string) => {
      sendCommand(CommandType.VIDEO_PLAYING, { assetUuid, assetId });
    },
    restartWallVideo: () => {
      sendCommand(CommandType.RESTART_WALL_VIDEO);
    },
    changeVolume: (value: number) =>
      sendCommand(CommandType.CHANGE_VOLUME, value),
    userConnectedToSession: () => {
      sendCommand(CommandType.USER_CONNECTED_TO_SESSION);
    },
    userDisconnectedFromSession: () => {
      sendCommand(CommandType.USER_DISCONNECTED_FROM_SESSION);
    },
    submitPin: (pin: string) => {
      sendCommand(CommandType.SUBMIT_PIN_CODE, { pin });
    },
    sendPin: () => {
      sendCommand(CommandType.RESEND_PIN_CODE, {});
    },
    setUserName: (value: string) => {
      sendCommand(CommandType.CHANGE_NAME, { username: value });
    },
    admitConnection: (connectionId: string) => {
      sendCommand(CommandType.GRANT_ADMISSION, { connectionId });
    },
    kickOutViewer: (connectionId: string) => {
      sendCommand(CommandType.KICK_OUT_VIEWER, { connectionId });
    },
    askForAdmission: () => {
      sendCommand(CommandType.ASK_FOR_ADMISSION);
    },
    rejectAdmission: (connectionId: string) => {
      sendCommand(CommandType.REJECT_ADMISSION, { connectionId });
    },
    joinPresentation: () => {
      sendCommand(CommandType.JOIN_PRESENTATION, {});
    },
    leavePresentation: () => {
      sendCommand(CommandType.LEAVE_PRESENTATION, {});
    },
    updateSessionData: () => {
      sendCommand(CommandType.UPDATE_SESSION_DATA, {});
    },
    generateLink: () => {
      sendCommand(CommandType.GENERATE_LINK, {});
    },
    regenerateConferenceToken: () => {
      sendCommand(CommandType.REGENERATE_CONFERENCE_TOKEN, {});
    },
    sendChatMessage: (message: string) => {
      sendCommand(CommandType.SEND_CHAT_MESSAGE, { message });
    },
    sendScreenShare: (screenShare: boolean) => {
      sendCommand(CommandType.SEND_SCREEN_SHARE, { screenShare });
    },
    muteUser: (userId: number) => {
      sendCommand(CommandType.MUTE_USER, { userId });
    },
    changeMuteState: (mute: boolean) => {
      sendCommand(CommandType.CHANGE_MUTE_STATE, { mute });
    },
    muteAll: () => {
      sendCommand(CommandType.MUTE_ALL);
    },
    invitePeople: (emails: string) => {
      sendCommand(CommandType.INVITE_PEOPLE, { emails });
    },
    reportAgora: (hd: number, superHd: number) => {
      sendCommand(CommandType.REPORT_AGORA, { hd, superHd });
    }
  };
};

export type WSClient = ReturnType<typeof buildWsClient>;
