import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import cx from "classnames";

import { WebSocketContext } from "../root/root-component";
import { useNotifications } from "../ux/notifications";
import { getAssetUrls, getOwnerData } from "./presenter-selectors";
import { UpdateScheduledSession } from "../scheduled-sessions/update-scheduled-session";
import { PresentationDownload } from "../content-management/presentation-download";
import { OpenControlComponent } from "./presenter-components/open-control-component";
import { AssetsStatus } from "./presenter-components/assets-status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingIndicator } from "../ux/loading-indicator";
import { PresentationLink } from "./presenter-components/presentation-link";

export const PresenterLobby = (props: { onSessionEntered: () => void }) => {
  const { onSessionEntered } = props;

  const [firstLoad, setFirstLoad] = useState(true);

  const history = useHistory();
  const wsApi = useContext(WebSocketContext);
  const notifications = useNotifications();

  const sessionData = useSelector(getOwnerData);
  const assetUrls = useSelector(getAssetUrls);

  const controlLink = `${window.location.origin}/control/${sessionData?.sessionState.id}`;
  const ready = sessionData?.sessionState.ready;
  const versionChanged = sessionData?.sessionState.versionChanged;

  const updatePresentation = useCallback(() => {
    wsApi?.updateAssets();
    notifications.info("Started updating presentation");
  }, [notifications, wsApi]);

  const enterSession = useCallback(() => {
    onSessionEntered();
    wsApi?.startSession();
  }, [onSessionEntered, wsApi]);

  useEffect(() => {
    if (sessionData?.sessionState) {
      setFirstLoad(false);
    }
  }, [props, sessionData]);

  useEffect(() => {
    if (
      !sessionData?.sessionState.checkingVersion &&
      sessionData?.sessionState.versionChanged
    ) {
      notifications.warn("Presentation has changed");
    }
    // eslint-disable-next-line
  }, [sessionData?.sessionState.versionChanged]);

  useEffect(() => {
    if (versionChanged && !firstLoad && ready) {
      notifications.success("Presentation has been updated");
    }
    // eslint-disable-next-line
  }, [versionChanged, ready]);

  const scheduledStartTime = sessionData?.sessionState.scheduledStartTime;
  const scheduledEndTime = sessionData?.sessionState.scheduledEndTime;

  const scheduledStart = scheduledStartTime
    ? moment(scheduledStartTime + "")
    : moment();
  const scheduledEnd = scheduledEndTime
    ? moment(scheduledEndTime + "")
    : moment();

  return (
    <div className="app">
      <header className="app__head">
        <div className="app__inset --flex">
          <button
            className="quit-session-button"
            onClick={() => {
              history.push("/");
            }}
          >
            <FontAwesomeIcon
              icon={["fas", "arrow-circle-left"]}
              size="2x"
              color="#004aff"
            />
            Online Lobby
          </button>
          <h1>{sessionData?.sessionState.scheduledTitle}</h1>
          {sessionData?.sessionState.scheduled ? (
            <span>{`Scheduled for ${scheduledStart.format(
              "LL"
            )}, ${scheduledStart.format("HH:mm")} — ${scheduledEnd.format(
              "HH:mm"
            )}`}</span>
          ) : (
            <button
              className="button --primary"
              onClick={() =>
                history.push(
                  `/create/${sessionData?.sessionState.presentationId}`
                )
              }
            >
              Plan Session
            </button>
          )}
        </div>
        <hr className="u-off" />
      </header>

      <main className="app__body">
        <div className="app__inset">
          <div
            className={cx("container", {
              "--mean": sessionData?.sessionState.scheduled,
              "--narrow": !sessionData?.sessionState.scheduled
            })}
            data-grid={sessionData?.sessionState.scheduled ? true : undefined}
            style={{
              ["--grid-column-min" as string]: "340px",
              ["--grid-gap-column" as string]: "6rem",
              ["--grid-gap-row" as string]: "2rem"
            }}
          >
            <div>
              {sessionData?.sessionState.scheduled && (
                <UpdateScheduledSession
                  sessionId={sessionData?.sessionState.id as string}
                  versionChanged={sessionData?.sessionState.versionChanged}
                />
              )}
            </div>

            {sessionData ? (
              <div>
                <h2>{`Presentation – ${sessionData?.presentationTitle}`}</h2>
                {!sessionData.sessionState.scheduled && <PresentationLink />}

                {sessionData?.sessionState &&
                  (sessionData?.sessionState.error ? (
                    <div className="message --warning">
                      {sessionData?.sessionState.error}
                    </div>
                  ) : (
                    <>
                      {sessionData?.sessionState.ready ? (
                        <>
                          <PresentationDownload
                            assets={assetUrls || []}
                            versionChanged={
                              sessionData?.sessionState.versionChanged
                            }
                            onUpdatePresentation={updatePresentation}
                          />
                          <p>
                            <button
                              className="button --primary"
                              onClick={enterSession}
                            >
                              Start Session
                            </button>
                          </p>
                          {sessionData?.sessionState.warning && (
                            <div className="message --warning">
                              {sessionData?.sessionState.warning}
                            </div>
                          )}
                          <OpenControlComponent
                            controlLink={controlLink}
                            primary
                          />
                        </>
                      ) : (
                        <AssetsStatus
                          sessionState={sessionData?.sessionState}
                        />
                      )}
                    </>
                  ))}
              </div>
            ) : (
              <div
                className="app__body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40vh"
                }}
              >
                <LoadingIndicator size={4} />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
