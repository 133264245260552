import React from "react";
import { useCachedAsset } from "./use-cached-asset";

export const CachedImage = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const src = useCachedAsset(props.src);

  return src ? <img alt="" {...props} src={src} /> : <></>;
};
