import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useCallback, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { AssetType } from "shared/types/domain";
import "../../assets/stylesheets/views/controller.scss";
import { WebSocketContext } from "../root/root-component";
import { getControlState } from "./control-selectors";

const THUMBNAIL_ASSETS = [AssetType.ASSET_VIDEO, AssetType.ASSET_IMAGE];
const PLAYABLE_ASSETS = [AssetType.ASSET_SOUND, AssetType.ASSET_VIDEO];

export const Control = () => {
  const [selectedAssetId, changeSelectedAssetId] = useState<null | number>(
    null
  );
  const api = useContext(WebSocketContext);

  const [assetFilter, changeAssetFilter] = useState<AssetType | null>(null);
  const [activeTitle, setActiveTitle] = useState("");
  const [showScenePanel, setShowScenePanel] = useState(false);
  const [wallSwitchActive, setWallSwitchActive] = useState(false);
  const [assetFilterActive, setAssetFilterActive] = useState(false);

  const sessionData = useSelector(getControlState);

  const scenes = sessionData?.scenes;

  useEffect(() => {
    const activeScene = scenes?.find((scene) => scene.active);
    if (activeScene) {
      setActiveTitle(activeScene.title);
    }
  }, [scenes]);

  const selectPreviousScene = useCallback(() => {
    const activeSceneIndex = scenes?.findIndex((scene) => scene.active);
    if (activeSceneIndex !== undefined && activeSceneIndex >= 1 && scenes) {
      api?.changeScene(scenes[activeSceneIndex - 1].id);
    }
  }, [api, scenes]);

  const selectNextScene = useCallback(() => {
    const activeSceneIndex = scenes?.findIndex((scene) => scene.active);
    if (
      activeSceneIndex !== undefined &&
      activeSceneIndex + 1 !== scenes?.length &&
      scenes
    ) {
      api?.changeScene(scenes[activeSceneIndex + 1].id);
    }
  }, [api, scenes]);

  const onChangeScene = useCallback(
    (sceneId: number) => {
      api?.changeScene(sceneId);
      setShowScenePanel(false);
    },
    [api]
  );

  const togglePanel = useCallback(() => {
    setShowScenePanel(!showScenePanel);
  }, [showScenePanel]);

  const toggleWallSwitch = useCallback(() => {
    setWallSwitchActive(!wallSwitchActive);
  }, [wallSwitchActive]);

  const toggleAssetFilter = useCallback(() => {
    setAssetFilterActive(!assetFilterActive);
  }, [assetFilterActive]);

  const onChangeWall = useCallback(
    (wallNum: number) => {
      api?.changeWall(wallNum);
    },
    [api]
  );

  const toggleAssetVisibility = useCallback(
    (assetId: number) => {
      api?.toggleAssetVisibility(assetId);
    },
    [api]
  );

  const toggglePlayPauseAsset = useCallback(
    (assetId: number) => {
      api?.toggleAssetPlaying(assetId);
    },
    [api]
  );

  const togglePlayPauseWall = useCallback(() => {
    api?.togglePlayPauseWall();
  }, [api]);

  const pdfNextPage = useCallback(
    (assetId: number) => {
      api?.pdfNextPage(assetId);
    },
    [api]
  );

  const pdfPrevPage = useCallback(
    (assetId: number) => {
      api?.pdfPrevPage(assetId);
    },
    [api]
  );

  const filterByAssetType = (assetType: AssetType | null) => () =>
    changeAssetFilter(assetType);
  const getClassNameForFilter = (assetType: AssetType | null) =>
    cx({
      "is-active": assetFilter === assetType
    });

  const toggleSelectedAsset = useCallback(
    (sceneAssetId: number) => {
      const targetAsset = sessionData?.sceneAssets.find(
        (sceneAsset) => sceneAsset.id === sceneAssetId
      );

      if (
        !(
          targetAsset &&
          selectedAssetId !== targetAsset.id &&
          targetAsset?.visible
        )
      ) {
        toggleAssetVisibility(sceneAssetId);
      }

      changeSelectedAssetId((currentSelectedSceneAssetId) => {
        if (currentSelectedSceneAssetId === sceneAssetId) {
          return null;
        } else {
          return sceneAssetId;
        }
      });
    },
    [changeSelectedAssetId, sessionData, selectedAssetId, toggleAssetVisibility]
  );

  const restartWallVideo = useCallback(() => {
    api?.restartWallVideo();
  }, [api]);

  if (!sessionData?.presentationStarted) {
    return (
      <section className="controller">
        <header className="controller__head">
          <h2 className="controller__title u-truncate">
            Start presentation to see controls.
          </h2>
        </header>
      </section>
    );
  }

  if (sessionData) {
    const selectedAsset = sessionData.sceneAssets.find(
      (sceneAsset) => sceneAsset.id === selectedAssetId
    );

    return (
      <section className="controller">
        <header className="controller__head">
          <h2 className="controller__title u-truncate">{activeTitle}</h2>

          <div className="u-flex u-justify --between">
            <div>
              {sessionData.hasVideoBackground && (
                <span
                  title="Restart Wall Video"
                  className="control --primary"
                  onClick={restartWallVideo}
                >
                  <span className="control__element">
                    <FontAwesomeIcon icon={["fad", "undo"]} />
                  </span>
                </span>
              )}

              {/* control: Background video */}
              {sessionData.hasVideoBackground && (
                <span
                  title={`${
                    sessionData.wallPlaying ? "Pause" : "Play"
                  } Wall Video`}
                  className="control --primary"
                  style={{ ["--icon-scale" as string]: 2.25 }}
                  onClick={togglePlayPauseWall}
                >
                  <span className="control__element">
                    <FontAwesomeIcon
                      icon={[
                        "fad",
                        sessionData.wallPlaying ? "pause-circle" : "play-circle"
                      ]}
                    />
                  </span>
                </span>
              )}
              {/* control: Background video */}
            </div>

            <div>
              <span
                title="Toggle Scene list"
                className="control --primary"
                style={{
                  ["--icon-scale" as string]: "1.125"
                }}
                onClick={togglePanel}
              >
                <span className="control__element">
                  <FontAwesomeIcon icon={["fad", "users-class"]} />
                </span>
              </span>
              <span
                title="Previous scene"
                className="control --primary"
                style={{
                  ["--icon-dx" as string]: "-1px"
                }}
                onClick={selectPreviousScene}
              >
                <span className="control__element">
                  <FontAwesomeIcon icon={["fas", "angle-left"]} />
                </span>
              </span>
              <span
                title="Next scene"
                className="control --primary"
                style={{
                  ["--icon-dx" as string]: "1px"
                }}
                onClick={selectNextScene}
              >
                <span className="control__element">
                  <FontAwesomeIcon icon={["fas", "angle-right"]} />
                </span>
              </span>
            </div>
          </div>
        </header>
        <div className="controller__body">
          {showScenePanel ? (
            <>
              {/* switch: Scenes */}
              <p className="button-group --block --compact --start">
                {sessionData.scenes?.map((scene) => (
                  <button
                    key={scene.id}
                    onClick={() => {
                      onChangeScene(scene.id);
                    }}
                    className={`button ${cx({
                      "is-active": scene.active,
                      "from-transition":
                        sessionData?.sceneTransition &&
                        scene.id === sessionData.sceneTransition.fromSceneId,
                      "to-transition":
                        sessionData?.sceneTransition &&
                        scene.id === sessionData.sceneTransition.nextSceneId
                    })}`}
                    style={{ borderRadius: 30, marginBottom: "1rem" }}
                  >
                    {scene.title}
                  </button>
                ))}
              </p>
              {/* switch: Scenes */}
            </>
          ) : (
            sessionData.presentationStarted && (
              <>
                {/* list: Assets */}
                <section>
                  {!sessionData.sceneAssets.length && (
                    <div className="message --warning">
                      There are no assets in the scene
                    </div>
                  )}
                  <div className="list-asset">
                    {sessionData.sceneAssets
                      .filter(
                        (sceneAsset) =>
                          assetFilter === null ||
                          sceneAsset.type === assetFilter
                      )
                      .map((sceneAsset, index) => (
                        <article
                          key={sceneAsset.id}
                          className={`list-asset__item ${cx({
                            "is-active": sceneAsset.id === selectedAssetId,
                            "is-visible": sceneAsset.visible
                          })}`}
                          style={{ animationDelay: `${(index + 1) * 0.1}s` }}
                          onClick={() => toggleSelectedAsset(sceneAsset.id)}
                        >
                          {THUMBNAIL_ASSETS.includes(sceneAsset.type) && (
                            <img src={sceneAsset.thumbnailUrl} alt="" />
                          )}
                          {!THUMBNAIL_ASSETS.includes(sceneAsset.type) && (
                            <div
                              style={{
                                height: "121px"
                              }}
                            />
                          )}
                          <span
                            className={`list-asset__figure ${cx({
                              "is-active": sceneAsset.onActiveWall
                            })}`}
                          >
                            {sceneAsset.wallNumber}
                          </span>
                          <span className="list-asset__title">
                            {sceneAsset.title}
                          </span>
                        </article>
                      ))}
                  </div>
                </section>
                {/* list: Assets */}
              </>
            )
          )}
        </div>

        <footer className="controller__foot">
          <div
            style={{
              gridArea: "c",
              alignSelf: "center"
            }}
          >
            {selectedAsset && (
              <>
                {selectedAsset.type === AssetType.ASSET_PDF &&
                  selectedAsset.visible && (
                    <span
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        fontSize: "2rem",
                        color: "#004aff"
                      }}
                    >
                      <span className={"control --primary  --secondary"}>
                        <button
                          className={"control__element"}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            pdfPrevPage(selectedAsset.id);
                          }}
                        >
                          <FontAwesomeIcon
                            title="Previous Page"
                            icon={["fad", "caret-circle-left"]}
                          />
                        </button>
                      </span>

                      <span className={"control --primary  --secondary "}>
                        <button
                          className={"control__element"}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            pdfNextPage(selectedAsset.id);
                          }}
                        >
                          <FontAwesomeIcon
                            title="Next Page"
                            icon={["fad", "caret-circle-right"]}
                          />
                        </button>
                      </span>
                    </span>
                  )}
                {PLAYABLE_ASSETS.includes(selectedAsset.playAs) && (
                  <span
                    title={`${
                      selectedAsset.playing ? "Pause" : "Play"
                    } Wall Video`}
                    className="control --primary --secondary --large"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      toggglePlayPauseAsset(selectedAsset.id);
                    }}
                  >
                    <span className="control__element">
                      <FontAwesomeIcon
                        icon={[
                          "fad",
                          selectedAsset.playing ? "pause-circle" : "play-circle"
                        ]}
                      />
                    </span>
                  </span>
                )}
              </>
            )}
          </div>

          {/* switch: Wall */}
          <p
            className={cx({
              "is-active": wallSwitchActive
            })}
            style={{
              gridArea: "a"
            }}
            data-switch="wall"
          >
            <span
              className="control --primary"
              data-switch="master"
              onClick={toggleWallSwitch}
            >
              <span className="control__element">
                <FontAwesomeIcon icon={["fad", "eye"]} />
              </span>
              <span className="control__label">Wall</span>
            </span>
            {sessionData.walls?.map((wall) => (
              <span
                key={wall.num}
                className={cx("control --primary", {
                  "is-active": wall.active
                })}
                onClick={() => onChangeWall(wall.num)}
              >
                <span
                  className="control__element u-font --bold"
                  style={{
                    // boxShadow: "rgba(142, 143, 153, 0.33) 0 0 4px 2px",
                    border: "none"
                  }}
                >
                  {wall.num}
                </span>
              </span>
            ))}
          </p>
          {/* switch: Wall */}

          {/* filter: Assets */}
          <p
            className={cx({
              "is-active": assetFilterActive
            })}
            data-filter="asset"
          >
            <span
              className="control --primary"
              data-filter="master"
              onClick={toggleAssetFilter}
              style={{
                boxShadow: "rgba(142, 143, 153, 0.89) 0 0 4px",
                borderRadius: 30
              }}
            >
              <span
                className="control__element"
                style={{
                  border: "none",
                  color: "black",
                  boxShadow: "rgba(142, 143, 153, 0.33) 0 0 4px"
                }}
              >
                <FontAwesomeIcon icon={["fas", "filter"]} transform="down-1" />
              </span>
            </span>
            <span
              className={`button --small ${getClassNameForFilter(null)}`}
              onClick={filterByAssetType(null)}
            >
              All
            </span>
            <span
              className={`button --small ${getClassNameForFilter(
                AssetType.ASSET_IMAGE
              )}`}
              onClick={filterByAssetType(AssetType.ASSET_IMAGE)}
            >
              <FontAwesomeIcon
                icon={["far", "images"]}
                size="2x"
                color="#004aff"
              />
            </span>
            <span
              className={`button --small ${getClassNameForFilter(
                AssetType.ASSET_VIDEO
              )}`}
              onClick={filterByAssetType(AssetType.ASSET_VIDEO)}
            >
              <FontAwesomeIcon
                icon={["fad", "play-circle"]}
                size="2x"
                color="#004aff"
              />
            </span>
            <span
              className={`button --small ${getClassNameForFilter(
                AssetType.ASSET_SOUND
              )}`}
              onClick={filterByAssetType(AssetType.ASSET_SOUND)}
            >
              <FontAwesomeIcon
                icon={["far", "music"]}
                size="2x"
                color="#004aff"
              />
            </span>
            <span
              className={`button --small ${getClassNameForFilter(
                AssetType.ASSET_PDF
              )}`}
              onClick={filterByAssetType(AssetType.ASSET_PDF)}
            >
              <FontAwesomeIcon
                icon={["fas", "file-pdf"]}
                size="2x"
                color="#004aff"
              />
            </span>
          </p>
          {/* filter: Assets */}
        </footer>
      </section>
    );
  } else {
    return <></>;
  }
};
