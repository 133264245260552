import React from "react";
import URI from "urijs";
import querystring from "querystring";

export const LoginRedirect = () => {
  const uri = URI(window.location.href);
  const query = uri.query();
  const { state } = querystring.parse(query);
  if (state) {
    window.location.href = JSON.parse(state as string).url;
  }
  return <></>;
};
