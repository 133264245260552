import React from "react";
import { Background } from "shared/domain/presentation/presentation-selectors";
import { AssetType } from "../../../../shared/types/domain";
import { VideoAsset } from "./video-asset";
import { ImageAsset } from "./image-asset";
import { getProcessedAssetSliceUrl } from "shared/asset-to-url";

export const BackgroundComponent: React.FC<{
  wallNumber: number;
  background: Background;
  muted: boolean;
  volume: number;
  onTimeUpdated: (assetUuid: string, time: number, duration: number) => void;
  onPaused: (assetUuid: string) => void;
  onPlaying: (assetUuid: string) => void;
}> = ({
  muted,
  volume,
  wallNumber,
  background,
  onTimeUpdated,
  onPaused,
  onPlaying
}) => {
  if (background) {
    const url = getProcessedAssetSliceUrl(
      background,
      wallNumber,
      background.backgroundVersion,
      background.instanceUuid as string,
      background.sceneId
    );

    return (
      <>
        {background.type === AssetType.ASSET_WALL_IMAGE && (
          <ImageAsset
            glow={false}
            type={background.type}
            url={url}
            isWall={true}
          />
        )}
        {background.type === AssetType.ASSET_WALL_VIDEO && (
          <VideoAsset
            id={background.uuid}
            visible={true}
            glow={false}
            muted={muted}
            volume={volume}
            type={background.type}
            autoplay={background.autoplay}
            loop={background.loop}
            playing={background.playing}
            url={url}
            onTimeUpdate={(...rest) => onTimeUpdated(background.uuid, ...rest)}
            onPaused={() => onPaused(background.uuid)}
            onPlaying={() => onPlaying(background.uuid)}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};
