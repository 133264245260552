import { useContext } from "react";
import { noop } from "rxjs";

import { WebSocketContext } from "../root/root-component";

export const useGetApiOrMock = (authenticated?: boolean) => {
  const api = useContext(WebSocketContext);
  if (authenticated) {
    return api;
  } else {
    return {
      changePlayback: noop,
      videoPaused: noop,
      videoPlaying: noop
    };
  }
};
