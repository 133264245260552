import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useNotifications } from "../../ux/notifications";
import { WebSocketContext } from "../../root/root-component";
import { getGeneratedLinks } from "../presenter-selectors";

export const PresentationLink = () => {
  const notifications = useNotifications();

  const wsApi = useContext(WebSocketContext);
  const links = useSelector(getGeneratedLinks);

  const [localLinks, setLocalLinks] = useState<string[]>([]);
  const [waitingForLink, setWaitingForLink] = useState(false);

  const copyToClipboard = useCallback(
    (url: string) => {
      navigator.clipboard.writeText(url);
      notifications.success("Generated Link copied to the Clipboard");
    },
    [notifications]
  );

  const generateLink = useCallback(() => {
    setWaitingForLink(true);
    wsApi?.generateLink();
  }, [wsApi]);

  useEffect(() => {
    if (waitingForLink) {
      const newLink = links?.find((link) => !localLinks.includes(link));
      newLink && copyToClipboard(`${window.origin}/presentation/${newLink}`);
    }
    links && setLocalLinks(links);
    setWaitingForLink(false);
  }, [localLinks, links]);

  return (
    <p className="btn-group">
      <button className="button --primary" onClick={generateLink}>
        Generate Unique Link
      </button>
    </p>
  );
};
