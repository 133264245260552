import React, { useCallback, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AdmissionStatus } from "../../../shared/domain/connections/connections-slice";
import { PresentationDownload } from "../content-management/presentation-download";
import { WebSocketContext } from "../root/root-component";
import {
  getAuthenticatedData,
  getAuthenticatedError
} from "./visitor-selectors";
import { getSessionConnection } from "../web-sockets/session-selectors";
import { LoadingIndicator } from "../ux/loading-indicator";
import { Field, Form, FormSpy } from "react-final-form";
import { ErrorPage } from "./visitor-not-authenticated";

interface SessionDetailProps {
  admissionStatus: AdmissionStatus;
  running: boolean;
  scheduled: boolean;
  ownerName: string;
  start: string;
  end: string;
  description: string;
  assetsDownloaded: boolean;
  warningShown: boolean;
  onShowWarning: () => void;
  onJoinPresentation: (value?: string) => void;
}

interface JoinFormData {
  name: string;
}

const SessionDetail = (props: SessionDetailProps) => {
  const {
    admissionStatus,
    running,
    ownerName,
    scheduled,
    start,
    end,
    description,
    assetsDownloaded,
    warningShown,
    onShowWarning,
    onJoinPresentation
  } = props;

  const [joining, setJoining] = useState(false);

  const formValues = useRef<JoinFormData>({ name: "" });

  const scheduledStart = moment(`${start}`);
  const scheduledEnd = moment(`${end}`);

  const joinPresentation = useCallback(() => {
    if (assetsDownloaded) {
      setJoining(true);
    } else if (warningShown) {
      setJoining(true);
    } else {
      onShowWarning();
    }
  }, [assetsDownloaded, warningShown, onShowWarning]);

  const joinAdmitted = useCallback(() => {
    if (assetsDownloaded) {
      onJoinPresentation();
    } else if (warningShown) {
      onJoinPresentation();
    } else {
      onShowWarning();
    }
  }, [assetsDownloaded, warningShown, onShowWarning, onJoinPresentation]);

  const submitHandler = useCallback(
    (values: JoinFormData) => {
      onJoinPresentation(values.name);
    },
    [onJoinPresentation]
  );

  const askAgain = useCallback(() => {
    onJoinPresentation(formValues?.current.name);
  }, [onJoinPresentation]);

  const closeForm = useCallback(() => {
    setJoining(false);
  }, []);

  const validate = useCallback((values) => {
    const err: { name?: string } = {};
    if (!values.name || values.name === "") {
      err.name = "Please enter your name";
    }
    return err;
  }, []);

  const sessionDetail = (
    <>
      {admissionStatus === AdmissionStatus.NOT_ASKED && (
        <button
          className="button --primary"
          onClick={joinPresentation}
          disabled={!running}
          style={{ float: "right", marginTop: "2vh" }}
        >
          Join Session
        </button>
      )}
      {admissionStatus === AdmissionStatus.ADMITTED && (
        <button
          className="button --primary"
          onClick={joinAdmitted}
          disabled={!running}
          style={{ float: "right", marginTop: "2vh" }}
        >
          Join Session
        </button>
      )}
      {admissionStatus === AdmissionStatus.REJECTED && (
        <button
          className="button --primary"
          onClick={joinPresentation}
          disabled={!running}
          style={{ float: "right", marginTop: "2vh" }}
        >
          Rejoin Session
        </button>
      )}
      <h4>Presenter</h4>
      <p>{ownerName}</p>
      {scheduled && (
        <>
          <h4>Date</h4>
          <p>
            {`Scheduled for ${scheduledStart.format(
              "LL"
            )}, ${scheduledStart.format("HH:mm")} – ${scheduledEnd.format(
              "HH:mm"
            )}`}
          </p>

          <h4>Description</h4>
          <p>{description}</p>
        </>
      )}
    </>
  );

  const joinFormNotAsked = (
    <Form<JoinFormData>
      onSubmit={submitHandler}
      validate={validate}
      initialValues={{ name: "" }}
      render={({ handleSubmit, errors, touched }) => {
        return (
          <>
            <FormSpy<JoinFormData>
              subscription={{ values: true }}
              onChange={({ values }) => {
                formValues.current = values;
              }}
            />
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <h3 style={{ margin: "4vh 0" }}>What is your name?</h3>
              <Field
                name="name"
                component="input"
                type="text"
                style={{
                  width: "25vh",
                  textAlign: "center",
                  fontSize: 20,
                  borderColor: touched?.name && errors.name ? "red" : "#bec0c7"
                }}
              />
              <span
                style={{
                  margin: "1vh 0 3vh 0",
                  height: "1vh",
                  color: "red",
                  fontWeight: 700
                }}
              >
                {touched?.name && errors.name ? errors.name : ""}
              </span>
              <button className="button --primary" type="submit">
                Ask to join
              </button>
            </form>
          </>
        );
      }}
    />
  );

  const joinFormWaiting = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h3 style={{ margin: "4vh 0" }}>Asking to join...</h3>
      <p style={{ marginBottom: "4vh", width: "30vh", textAlign: "center" }}>
        You'll join the session when the presenter lets you in
      </p>
      <LoadingIndicator withText={false} />
    </div>
  );

  const joinFormRejected = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h3 style={{ margin: "4vh 0" }}>You got declined</h3>
      <p style={{ marginBottom: "4vh", width: "30vh", textAlign: "center" }}>
        The presenter has declined your request to join the session
      </p>
      <button className="button --primary" onClick={askAgain}>
        Ask Again
      </button>
    </div>
  );

  let joinForm;
  switch (admissionStatus) {
    case AdmissionStatus.NOT_ASKED:
      joinForm = joinFormNotAsked;
      break;
    case AdmissionStatus.WAITING_FOR_ADMISSION:
      joinForm = joinFormWaiting;
      break;
    case AdmissionStatus.REJECTED:
      joinForm = joinFormRejected;
      break;
    default:
      joinForm = <></>;
  }

  //TODO @refactoring @styling
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Session Info</h2>
        {admissionStatus === AdmissionStatus.REJECTED && joining && (
          <FontAwesomeIcon
            icon={["fas", "caret-down"]}
            style={{
              fontSize: "1.4rem",
              cursor: "pointer",
              marginTop: "0.5rem"
            }}
            onClick={closeForm}
          />
        )}
      </div>

      {joining ? joinForm : sessionDetail}
    </div>
  );
};

export const VisitorLobby = () => {
  const [assetsDownloaded, setAssetsDownloaded] = useState(false);
  const [warningShown, setWarningShown] = useState(false);

  const data = useSelector(getAuthenticatedData);
  const connection = useSelector(getSessionConnection);
  const wsApi = useContext(WebSocketContext);
  const sessionError = useSelector(getAuthenticatedError);

  const joinPresentation = useCallback(
    (name?: string) => {
      if (connection?.admissionStatus === AdmissionStatus.ADMITTED) {
        wsApi?.joinPresentation();
      } else {
        if (name) {
          wsApi?.setUserName(name);
        }

        wsApi?.askForAdmission();
      }
    },
    [wsApi, connection]
  );

  const assetsReady = useCallback(() => {
    setAssetsDownloaded(true);
    setWarningShown(false);
  }, []);

  const assetsMissing = useCallback(() => {
    setAssetsDownloaded(false);
  }, []);

  const showWarning = useCallback(() => {
    setWarningShown(true);
  }, []);

  if (sessionError) {
    return <ErrorPage />;
  }

  return (
    <div className="app">
      <header
        className="app__head"
        style={{
          background: "white",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "rgba(0, 0, 0, 0.14) 2px 2px 6px 2px",
          zIndex: 2,
          padding: "0 5rem"
        }}
      >
        <h1
          style={{
            fontWeight: 700,
            fontSize: 20,
            alignSelf: "flex-start",
            margin: 0
          }}
        >
          Welcome in the Online Lobby
        </h1>

        <span
          className={cx({
            "label": true,
            "--danger": !data?.running,
            "--success": data?.running
          })}
          style={{ margin: "0 0 0.5rem 0" }}
        >
          {data?.running ? "Live" : "Not started"}
        </span>
        <div className="u-text --center">
          <h2 style={{ margin: "1.5rem 0" }}>{data?.title}</h2>
        </div>
      </header>

      <main className="app__body">
        <div className="app__inset">
          <div
            className="container --mean"
            data-grid="true"
            style={{
              ["--grid-column-min" as string]: "340px",
              ["--grid-gap-column" as string]: "6rem",
              ["--grid-gap-row" as string]: "2rem",
              padding: "4rem 0"
            }}
          >
            <SessionDetail
              running={data?.running || false}
              scheduled={data?.scheduled || false}
              ownerName={data?.ownerName || ""}
              start={data?.startTime || ""}
              end={data?.endTime || ""}
              description={data?.description || ""}
              admissionStatus={
                connection?.admissionStatus || AdmissionStatus.NOT_ASKED
              }
              onShowWarning={showWarning}
              assetsDownloaded={assetsDownloaded}
              warningShown={warningShown}
              onJoinPresentation={joinPresentation}
            />

            <div>
              <PresentationDownload
                assets={data?.assetUrls || []}
                onAssetsReady={assetsReady}
                onAssetsMissing={assetsMissing}
                viewer
                removeOnLeave={
                  data ? { startTime: data?.startTime } : undefined
                }
              />
              {warningShown && (
                <p className="message --primary --warning u-flex">
                  <FontAwesomeIcon
                    icon={["fad", "exclamation-triangle"]}
                    size="lg"
                    swapOpacity
                  />
                  It seems you have not yet downloaded the content of
                  presentation. Mind that not predownloading content may affect
                  the overall experience and may result in connection problems
                </p>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
