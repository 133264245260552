import { createSelector } from "@reduxjs/toolkit";
import { getVolumeControl } from "../root/root-selectors";

export const getPresentationVolume = createSelector(
  getVolumeControl,
  (state) => state.presentationVolume
);

export const getConferenceVolume = createSelector(
  getVolumeControl,
  (state) => state.conferenceVolume
);
