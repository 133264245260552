import React from "react";
import { useSelector } from "react-redux";

import { SessionConnectionStatus } from "../../../shared/domain/connections/connections-slice";
import { LoadingIndicator } from "../ux/loading-indicator";
import { VisitorLobby } from "./visitor-lobby";
import { VisitorPresentation } from "./visitor-presentation";
import { NotAuthenticated } from "./visitor-not-authenticated";
import { KickedOut } from "./visitor-kicked-out";
import { AlreadyConnected } from "./visitor-already-connected";
import { getConnectionStatus } from "../web-sockets/session-selectors";

export const VisitorIndex = () => {
  const connectionStatus = useSelector(getConnectionStatus);

  switch (connectionStatus) {
    case SessionConnectionStatus.NOT_AUTHENTICATED:
      return <NotAuthenticated />;
    case SessionConnectionStatus.KICKED_OUT:
      return <KickedOut />;
    case SessionConnectionStatus.ALREADY_CONNECTED:
      return <AlreadyConnected />;
    case SessionConnectionStatus.AUTHENTICATED:
      return <VisitorLobby />;
    case SessionConnectionStatus.JOINED_PRESENTATION:
      return <VisitorPresentation />;
    default:
      return (
        <div className="app">
          <header
            style={{
              background: "#e1e1e1",
              height: "30vh",
              padding: "50px 100px"
            }}
          >
            <h1 style={{ fontWeight: 700, fontSize: 20 }}>
              Welcome in the Online Lobby
            </h1>
          </header>

          <div
            className="container --narrow"
            style={{ marginTop: "4rem", zIndex: 2 }}
          >
            <LoadingIndicator size={4} />
          </div>
        </div>
      );
  }
};
