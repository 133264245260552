import Axios from "axios";
import querystring from "querystring";
import { redirectToLogin } from "../auth/redirect-to-login";
import { AppConfig } from "shared/types/app-config";

export interface Token {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export const getUnauthenticatedClient = () => {
  const api = Axios.create({
    baseURL: "/api"
  });

  return {
    getConfig: async () => {
      const { data } = await api.get("/config");
      return data as AppConfig;
    },
    getToken: async (code: string): Promise<Token> => {
      const { data } = await api.post(
        "/oauth/token",
        querystring.stringify({
          code
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      return data as Token;
    }
  };
};

export interface LoggedInUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export const getAuthenticatedClient = (
  token: string,
  appConfig: Partial<AppConfig>
) => {
  const api = Axios.create({
    baseURL: "/api/",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  api.interceptors.response.use(undefined, (err) => {
    if (
      err.response.status === 401 &&
      appConfig.CMS_URL &&
      appConfig.OAUTH_CLIENT_ID
    ) {
      redirectToLogin(appConfig.CMS_URL, appConfig.OAUTH_CLIENT_ID, {
        url: window.location.href
      });
    }
  });

  const getUserInfo = async () => {
    const { data } = await api.get(`/whoami`);
    return data;
  };

  const getInstanceConfig = async (instanceId: string) => {
    const { data } = await api.get(`/instance-config/${instanceId}`);
    return data;
  };

  const createScheduledSession = async (sessionData: {
    presentationId: number;
    instanceUuid: string;
    title: string;
    description: string;
    date: Date;
    startTime: string;
    endTime: string;
    invitations: string;
  }) => {
    const { data } = await api.post(`/scheduled-session`, sessionData);
    return data;
  };

  const updateScheduledSession = async (
    uuid: string,
    sessionData: {
      presentationId: number;
      instanceUuid: string;
      title: string;
      description: string;
      date: string;
      startTime: string;
      endTime: string;
      invitations: string;
    }
  ) => {
    const { data } = await api.put(`/scheduled-session/${uuid}`, sessionData);
    return data;
  };

  const sendInvitations = async (uuid: string) => {
    const { data } = await api.post(
      `/scheduled-session/${uuid}/send-invitations`
    );
    return data;
  };

  const listScheduledPresentations = async () => {
    const { data } = await api.get(`/scheduled-sessions`);
    return data;
  };

  const deleteScheduledSession = async (id: string) => {
    const { data } = await api.delete(`/scheduled-session/${id}`);
    return data;
  };

  const getScheduledSession = async (id: string) => {
    const { data } = await api.get(`/scheduled-session/${id}`);
    return data;
  };

  const getPresentation = async (id: number) => {
    const { data } = await api.get(`/presentation/${id}`);
    return data;
  };

  return {
    getPresentation,
    createScheduledSession,
    listScheduledPresentations,
    getUserInfo,
    getInstanceConfig,
    deleteScheduledSession,
    getScheduledSession,
    updateScheduledSession,
    sendInvitations
  };
};

export type APIAuthenticatedClient = ReturnType<typeof getAuthenticatedClient>;
