import React from "react";
import { useSelector } from "react-redux";
import { getPresenterEmail } from "./visitor-selectors";

import "../../assets/stylesheets/views/visitor-errors.scss";
import Image from "../../assets/images/error-expired.webp";

// FIXME: refactor ¯\(°_o)/¯

export const AlreadyConnected = () => {
  const presenterEmail = useSelector(getPresenterEmail);

  return (
    <div className="app app-errors">
      <header className="header">
        <h1>This link is already being used.</h1>
      </header>
      <div className="bottom-container">
        <div className="left-column">
          <h2>Walking the same path twice</h2>
          <p>
            This link is already being used or is expired. Please check if the
            link is correct and try again or contact the presenter
          </p>
          <a href={`mailto:${presenterEmail}`} className="button --primary">
            Contact presenter
          </a>
        </div>
        <img src={Image} alt="" className="visitor-error-image" />
      </div>
    </div>
  );
};
