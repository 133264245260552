import cx from "classnames";
import React, { useState, useRef, useCallback } from "react";
import { AssetType } from "shared/types/domain";
import { CachedAudio } from "./cached-assets/cached-audio";
import {
  useGetMediaProgress,
  useSynchronizeMediaOnComponentMount,
  useSynchronizeMediaPlayback,
  useKeepSynchornizedWithBackendPlaying
} from "./media-progress-sync";
import { useKeepVolumeSynchronized } from "./media-volume";

export const SoundAsset = ({
  id,
  url,
  autoplay,
  playing,
  loop,
  volume,
  muted,
  visible,
  onTimeUpdate
}: {
  id: number;
  url: string;
  autoplay: boolean;
  playing: boolean;
  loop: boolean;
  volume: number;
  muted: boolean;
  visible: boolean;
  onTimeUpdate: (time: number, duration: number) => void;
}) => {
  // Ref holding reference to audio element
  const ref = useRef<HTMLAudioElement | null>(null);

  // Since the underlying DOM element is resolved from cache
  // we have to keep flag information when the element is resolved
  const [cacheResolved, setCacheResolved] = useState(false);

  // Important flag which is used to *disable* sending
  // progress to the server during initial mounting of the component
  // so that it doesn't reset itself but rather *prepopulate*
  // the progress with information from server.
  const [sendingProgress, changeSendingProgress] = useState(false);

  // Data from back-end about current progress - could be undefined
  // if data still haven't been delivered or 0 if media hasn't started
  const audioProgress = useGetMediaProgress(AssetType.ASSET_SOUND, id);

  // Run all the hooks to sync
  useSynchronizeMediaOnComponentMount(
    ref,
    sendingProgress,
    cacheResolved,
    changeSendingProgress,
    audioProgress
  );
  useSynchronizeMediaPlayback(ref, sendingProgress, audioProgress);
  useKeepSynchornizedWithBackendPlaying(ref, playing, visible);
  useKeepVolumeSynchronized(ref, volume);

  const onCacheResolved = useCallback(() => {
    setCacheResolved(true);
  }, [setCacheResolved]);

  return (
    <CachedAudio
      innerRef={ref}
      className={cx(AssetType.ASSET_SOUND)}
      src={url}
      autoPlay={playing && autoplay}
      muted={muted}
      loop={loop}
      onCacheResolved={onCacheResolved}
      onTimeUpdate={(ev) => {
        if (sendingProgress) {
          onTimeUpdate(ev.currentTarget.currentTime, ev.currentTarget.duration);
        }
      }}
    />
  );
};
