import React from "react";

import "../../assets/stylesheets/views/visitor-errors.scss";
import Image from "../../assets/images/error-denied.webp";

// FIXME: refactor ¯\(°_o)/¯

export const KickedOut = () => {
  return (
    <div className="app app-errors">
      <header className="header">
        <h1>You've been removed from the session</h1>
      </header>
      <div className="bottom-container">
        <div className="left-column">
          <h2>Lights out</h2>
          <p>
            You've been removed from the session by the presenter. The session
            link became invalid. You can not enter the session anymore.
          </p>
        </div>
        <img src={Image} alt="" className="visitor-error-image" />
      </div>
    </div>
  );
};
