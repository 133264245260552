import { createSelector } from "@reduxjs/toolkit";
import {
  getWallHeightProjector,
  getWallWidthProjector
} from "../presentation/presentation-projectors";
import { getSession } from "../root/root-selectors";

export const getNotauthenticatedData = createSelector(
  getSession,
  (state) => state.NOT_AUTHENTICATED
);

export const getAuthenticatedData = createSelector(
  getSession,
  (state) => state.AUTHENTICATED
);

export const getJoinedPresentationData = createSelector(
  getSession,
  (state) => state.JOINED_PRESENTATION
);

export const getSessionId = createSelector(
  getAuthenticatedData,
  getJoinedPresentationData,
  (authenticated, joined) => {
    if (!authenticated && !joined) {
      return null;
    }

    if (joined) {
      return joined.sessionId;
    } else {
      return authenticated?.sessionId;
    }
  }
);

export const isScreenSharing = createSelector(
  getJoinedPresentationData,
  (state) => state?.sharingScreen
);

export const getMutedUsers = createSelector(
  getJoinedPresentationData,
  (state) => state?.mutedUsers
);

export const getParticipants = createSelector(
  getJoinedPresentationData,
  (state) => state?.participants
);

export const getChat = createSelector(
  getJoinedPresentationData,
  (state) => state?.chat
);

export const getAlreadyConnectedData = createSelector(
  getSession,
  (state) => state.ALREADY_CONNECTED
);

export const getPresenterEmail = createSelector(
  getAlreadyConnectedData,
  (state) => state?.email
);

export const getVisitorUserList = createSelector(
  getJoinedPresentationData,
  (state) => state?.connections
);

export const getVisitorSessionId = createSelector(
  getJoinedPresentationData,
  (state) => state?.sessionId
);

export const getNotauthenticatedError = createSelector(
  getNotauthenticatedData,
  (state) => state?.error
);

export const getAuthenticatedError = createSelector(
  getAuthenticatedData,
  (state) => state?.error
);

export const getWallHeight = createSelector(
  getJoinedPresentationData,
  getWallHeightProjector
);

export const getWallWidth = createSelector(
  getJoinedPresentationData,
  getWallWidthProjector
);
