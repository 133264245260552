import QRCode from "qrcode.react";
import React from "react";

export const OpenControlComponent = (props: {
  controlLink: string;
  primary?: boolean;
}) => {
  const { controlLink, primary } = props;

  if (primary) {
    return (
      <section className="card" data-presenter="open-control">
        <QRCode value={controlLink} />
        <div>
          <h3 className="card__title">Scan QR code for controls</h3>
          <p>
            We recommend to start the presentation controls from a mobile phone
            or table. Scan the code to start the controls for presentation.
          </p>
        </div>
      </section>
    );
  } else {
    return <QRCode value={controlLink} />;
  }
};
