import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppConfig } from "shared/types/app-config";

const initialState: Partial<AppConfig> = {};

const { actions, reducer } = createSlice({
  name: "config",
  initialState,
  reducers: {
    configFetched: (state, action: PayloadAction<{ config: AppConfig }>) =>
      action.payload.config
  }
});

export const ConfigActions = actions;
export const configReducer = reducer;
