import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext
} from "react";
import { useSelector } from "react-redux";

import { getChat as getChatVisitor } from "client/modules/visitor-view/visitor-selectors";
import { WebSocketContext } from "client/modules/root/root-component";
import { getChat as getChatPresenter } from "../presenter-view/presenter-selectors";

import { ReactComponent as IconClose } from "client/assets/images/16/close.svg";
import { ReactComponent as IconPlane } from "client/assets/images/16/paper-plane.svg";

import "client/assets/stylesheets/views/people.scss";

interface ChatComponentProps {
  isPresenter: boolean;
  onSidebar?: (show: boolean) => void;
}

export const ChatComponent = ({
  isPresenter,
  onSidebar
}: ChatComponentProps) => {
  const wsApi = useContext(WebSocketContext);
  const [message, setMessage] = useState<string | null>(null);

  const chat = useSelector(isPresenter ? getChatPresenter : getChatVisitor);

  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chat || !chat.length || !lastMessageRef.current) {
      return;
    }

    lastMessageRef.current.scrollIntoView({
      behavior: "smooth"
    });
  }, [chat, lastMessageRef]);

  const onSendTextMessage = useCallback(() => {
    if (message && wsApi) {
      wsApi.sendChatMessage(message);
      setMessage(null);
    }
  }, [message, setMessage, wsApi]);

  const onMessageKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!message) {
        return;
      }

      if (event.keyCode === 13) {
        onSendTextMessage();
        event.preventDefault();
      }
    },
    [message, onSendTextMessage]
  );

  const hideSidebar = useCallback(() => {
    onSidebar && onSidebar(false);
  }, [onSidebar]);

  return (
    <section className="people">
      <header className="people__head">
        <div>
          <h3 className="people__title">Chat</h3>
          <button
            type="button"
            className="people__control --close"
            onClick={hideSidebar}
          >
            <IconClose />
          </button>
        </div>
      </header>
      <div className="people__body">
        {chat && chat.length > 0 && (
          <ul className="list-chat">
            {chat.map(({ message, author }, index) => (
              <li className="list-chat__item" key={index}>
                <div className="list-chat__item__head">
                  <h5 className="list-chat__item__title" data-clamp="1">
                    {author}
                  </h5>
                  <span className="list-chat__item__meta">10:34</span>
                </div>
                <div className="list-chat__item__body">{message}</div>
              </li>
            ))}
          </ul>
        )}
        <div className="u-float --left u-clear --both" ref={lastMessageRef} />
      </div>

      <footer className="people__foot">
        <div
          className="form-textarea --growing has-control"
          style={{ ["--size" as string]: "100px" }}
          data-textarea={message}
        >
          <textarea
            rows={1}
            value={message || ""}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => onMessageKeyDown(e)}
          />
          <button
            type="button"
            className="form-textarea__control button --primary"
            onClick={onSendTextMessage}
          >
            <IconPlane />
          </button>
        </div>
      </footer>
    </section>
  );
};
