import React, { useEffect } from "react";
import { useCachedAsset } from "./use-cached-asset";

export const CachedAudio = (
  props: React.DetailedHTMLProps<
    React.AudioHTMLAttributes<HTMLAudioElement>,
    HTMLAudioElement
  > & {
    innerRef: React.MutableRefObject<HTMLAudioElement | null>;
    onCacheResolved: () => void;
  }
) => {
  const src = useCachedAsset(props.src);
  const { onCacheResolved, innerRef, ...rest } = props;

  useEffect(() => {
    if (src) {
      onCacheResolved();
    }
  }, [onCacheResolved, src]);

  return src ? <audio {...rest} ref={innerRef} src={src} /> : <></>;
};
