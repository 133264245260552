import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VolumeControlState {
  presentationVolume: number;
  conferenceVolume: number;
}

const initialState: VolumeControlState = {
  presentationVolume: 0.1,
  conferenceVolume: 0.7
};

const { actions, reducer } = createSlice({
  name: "volume-control",
  initialState,
  reducers: {
    setPresentationVolume: (
      state,
      action: PayloadAction<{ volume: number }>
    ) => ({ ...state, presentationVolume: action.payload.volume }),
    setConferenceVolume: (
      state,
      action: PayloadAction<{ volume: number }>
    ) => ({ ...state, conferenceVolume: action.payload.volume })
  }
});

export const VolumeControlActions = actions;
export const volumeControlReducer = reducer;
