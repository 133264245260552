import { createSelector } from "@reduxjs/toolkit";
import { getConfig as getState } from "../root/root-selectors";

export const getVersion = createSelector(getState, (state) => {
  if (state.VERSION_INFO) {
    const {
      VERSION_INFO: { version, tag }
    } = state;

    return `${version}-${tag}`;
  }
});

export const getAgoraId = createSelector(getState, (state) => state.AGORA_ID);
