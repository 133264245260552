export function setLocalStorageItem<T>(key: string, item: T) {
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItem<T>(key: string): T | null {
  try {
    return JSON.parse(localStorage.getItem(key) as string);
  } catch (ex) {
    return null;
  }
}

export const TOKEN_KEY = "api-token";
export const EXPIRATION_KEY = "api-token-expiration";
export const WS_RECONNECT_TOKEN_KEY = "ws-reconnect-token";
