import React from "react";

export const DefaultScenePlaceholder = () => {
  return (
    <div
      className="container --narrow"
      style={{
        color: "WHITE",
        display: "grid",
        height: "100%",
        placeItems: "center"
      }}
    >
      <div className="u-text --center">
        <h1>Welcome to Hyro Online</h1>
        {/* <p>In just a moment the presenter will start te presentation.</p> */}
      </div>
    </div>
  );
};
