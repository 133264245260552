import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { Logger as logger } from "purplex-logging";
import { LoadingIndicator } from "../ux/loading-indicator";
import { getSessionId } from "./presenter-selectors";

export const PresenterStartUnplannedSession = () => {
  const { instanceUuid, presentationId } = useParams();
  const parsedPresentationId = parseInt(presentationId || "");

  const sessionId = useSelector(getSessionId);

  const history = useHistory();

  useEffect(() => {
    logger.debug("Sessionid", sessionId);
    if (sessionId) {
      logger.debug(`Got new session id (${sessionId}) - redirecting`);
      history.push(`/presentation/authenticated/${sessionId}`);
    }
  }, [sessionId, history]);

  // TODO: Make it DRY!
  if (!instanceUuid || isNaN(parsedPresentationId)) {
    return (
      <div className="app">
        <header className="app__head" />
        <div className="app__body">
          <div className="app__inset container --narrow">
            <p className="message --error">Unexpected error</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <header className="app__head" />
      <div className="app__body">
        <div className="app__inset container --narrow">
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
};
