import React from "react";
import cx from "classnames";
import { WallSpecification } from "shared/domain/presentation/presentation-selectors";

export const WallWrapperComponent: React.FC<{
  wallSpecification: WallSpecification;
  roomWidth: number;
  scale: number;
  visible: boolean;
}> = ({ wallSpecification, visible, children, roomWidth, scale }) => {
  if (wallSpecification) {
    return (
      <div
        className={cx(`wall-wrapper wall-wrapper--${wallSpecification.num}`, {
          visible
        })}
        style={{
          width: wallSpecification.width,
          height: wallSpecification.height,
          transform: [
            `translateZ(${roomWidth / -2}px) scaleX(${scale})`,
            `translateZ(${roomWidth / 2}px) rotateY(-90deg) scaleX(${scale})`,
            `translateZ(${
              roomWidth / 2
            }px) scaleX(-${scale}) translateX(-100%)`,
            `translateZ(${roomWidth / 2}px) rotateY(90deg) scaleX(${scale})`
          ][wallSpecification.num - 1]
        }}
      >
        {children}
      </div>
    );
  } else {
    return <></>;
  }
};
