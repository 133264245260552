export const getWallHeightProjector = (data?: {
  walls: { num: number; height: number }[];
}) => {
  if (data && data.walls.length > 0) {
    return data.walls[0].height;
  } else {
    return 0;
  }
};

export const getWallWidthProjector = (data?: {
  walls: { num: number; width: number }[];
  activeWallNumber: number | undefined;
}) => {
  if (data && data.walls.length > 0) {
    return (
      data.walls.find((wall) => wall.num === data.activeWallNumber)?.width || 0
    );
  } else {
    return 0;
  }
};
