import React, { useCallback, useState } from "react";
import { HtmlPortalNode, InPortal } from "react-reverse-portal";
import cx from "classnames";

import { Hint } from "../ux/hint";
import { AVControls } from "./av-controls";

import { ReactComponent as IconCam } from "client/assets/images/16/cam.svg";
import { ReactComponent as IconCollapse } from "client/assets/images/16/collapse.svg";
import { ReactComponent as IconExpand } from "client/assets/images/16/expand.svg";
import { ReactComponent as IconMic } from "client/assets/images/16/mic.svg";

interface PresenterVideoComponentProps extends AVControls {
  videoComponentWrapperId: string;
  loggedInUserIsPresenter: boolean;
  isScreenSharing: boolean;
  bigScreenPortal?: HtmlPortalNode;
}

const invert = (value: boolean) => !value;

export const PresenterVideoComponent = ({
  videoComponentWrapperId,
  loggedInUserIsPresenter,
  isScreenSharing,
  muted,
  disabledVideo,
  bigScreenPortal,
  onToggleDisableVideo,
  onToggleMute
}: PresenterVideoComponentProps) => {
  const [undockedPresenter, setUndockedPresenter] = useState(false);

  const toggleDockedMode = useCallback(() => {
    setUndockedPresenter(invert);
  }, []);

  const videoWrapper = (
    <div
      className={cx({
        "is-disabled": loggedInUserIsPresenter && disabledVideo
      })}
      data-video="container"
      id={videoComponentWrapperId}
    />
  );

  const videoWrapperWithDockControls = (
    <div className={cx({ "is-active": undockedPresenter })} data-video="dock">
      {videoWrapper}
      {!loggedInUserIsPresenter && (
        <div data-video="controls">
          <Hint
            label={undockedPresenter ? "Dock" : "Undock"}
            placement="bottom"
          >
            <span
              className="control"
              data-control="dock"
              onClick={toggleDockedMode}
            >
              <button type="button" className="control__element">
                {undockedPresenter ? <IconCollapse /> : <IconExpand />}
              </button>
            </span>
          </Hint>
        </div>
      )}
    </div>
  );

  return (
    <>
      {isScreenSharing && bigScreenPortal && !loggedInUserIsPresenter ? (
        <InPortal node={bigScreenPortal}>{videoWrapper}</InPortal>
      ) : (
        videoWrapperWithDockControls
      )}

      {loggedInUserIsPresenter && (
        <div data-video="controls">
          <Hint
            label={disabledVideo ? "Enable video" : "Disable video"}
            placement="bottom"
          >
            <span
              className={cx("control", { "is-active": disabledVideo })}
              data-control="cam"
              onClick={onToggleDisableVideo}
            >
              <button type="button" className="control__element">
                <IconCam />
              </button>
            </span>
          </Hint>
          <Hint label={muted ? "Unmute" : "Mute"} placement="bottom">
            <span
              className={cx("control", { "is-active": muted })}
              data-control="mic"
              onClick={onToggleMute}
            >
              <button type="button" className="control__element">
                <IconMic />
              </button>
            </span>
          </Hint>
        </div>
      )}
    </>
  );
};
