import React from "react";
import Slider, { Handle, SliderProps } from "rc-slider";

import "rc-slider/assets/index.css";

interface ChangeVolumeProps extends SliderProps {
  handleIcon: React.ReactChild;
  onChange: (value: number) => void;
  value: number;
}

export const ChangeVolume: React.FC<ChangeVolumeProps> = ({
  handleIcon,
  onChange,
  value,
  ...props
}): JSX.Element => {
  return (
    <div data-control="volume">
      <Slider
        value={value}
        min={0}
        max={1}
        step={0.01}
        onChange={onChange}
        handle={(handleProps) => <Handle {...handleProps}>{handleIcon}</Handle>}
        {...props}
      />
    </div>
  );
};
