import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Control } from "../control/control-component";

import { PresenterStartUnplannedSession } from "../presenter-view/presenter-start-unplanned-session";
import { useAuth } from "../auth/use-auth";
import { ConnectionCommandType, useWs } from "../web-sockets/use-ws";
import { WSClient } from "../web-sockets/ws-client";
import { LoginRedirect } from "../auth/login-redirect";
import { ScheduledSessionsLobby } from "../scheduled-sessions/scheduled-sessions-lobby";
import { LoggedOut } from "../auth/logged-outs";
import { PresenterIndex } from "../presenter-view/presenter-index";
import { VisitorIndex } from "../visitor-view/visitor-index";
import { VersionInfo } from "./version-info";
import { SessionFlags } from "../../../shared/types/session-flags";
import { ClientType } from "../../../shared/types/client-type";
import { Logger as logger } from "purplex-logging";

const WithAuth: React.FC = ({ children }) => {
  const token = useAuth(true);

  if (token) {
    return <>{children}</>;
  } else {
    return <LoggedOut />;
  }
};

export const WebSocketContext = React.createContext<WSClient | null>(null);

const WithWs: React.FC<{ connectionCommandType: ConnectionCommandType }> = ({
  children,
  connectionCommandType
}) => {
  const params = useParams<{
    sessionId?: string;
    sessionToken?: string;
    instanceUuid?: string;
    presentationId?: string;
  }>();

  let payload;

  switch (connectionCommandType) {
    case ConnectionCommandType.CREATE_UNPLANNED_SESSION: {
      payload = {
        sessionId: SessionFlags,
        presentationId: params.presentationId,
        instanceUuid: params.instanceUuid,
        clientType: ClientType.PRESENTER
      };
      break;
    }
    case ConnectionCommandType.CONNECT_OWNER: {
      payload = {
        sessionId: params.sessionId,
        clientType: ClientType.PRESENTER
      };
      break;
    }
    case ConnectionCommandType.CONNECT_OWNER_CONTROL: {
      payload = {
        sessionId: params.sessionId,
        clientType: ClientType.CONTROL
      };
      break;
    }
    case ConnectionCommandType.CONNECT_VIEWER: {
      payload = {
        sessionToken: params.sessionToken,
        clientType: ClientType.PRESENTER
      };
      break;
    }
  }

  const client = useWs({ type: connectionCommandType, payload });

  const clientRef = useRef(client);

  useEffect(() => {
    clientRef.current = client;
  }, [client]);

  useEffect(() => {
    return () => {
      logger.debug("Disconnecting web-socket session connection.");
      clientRef.current?.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={client}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const RootComponent = () => {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path="/start-unplanned/:instanceUuid/:presentationId">
            <WithAuth>
              <WithWs
                connectionCommandType={
                  ConnectionCommandType.CREATE_UNPLANNED_SESSION
                }
              >
                <PresenterStartUnplannedSession />
              </WithWs>
            </WithAuth>
          </Route>

          <Route path="/control/:sessionId">
            <WithAuth>
              <WithWs
                connectionCommandType={
                  ConnectionCommandType.CONNECT_OWNER_CONTROL
                }
              >
                <Control />
              </WithWs>
            </WithAuth>
          </Route>
          <Route path="/presentation/authenticated/:sessionId">
            <WithAuth>
              <WithWs
                connectionCommandType={ConnectionCommandType.CONNECT_OWNER}
              >
                <PresenterIndex />
              </WithWs>
            </WithAuth>
          </Route>
          <Route path="/presentation/:sessionToken">
            <WithWs
              connectionCommandType={ConnectionCommandType.CONNECT_VIEWER}
            >
              <VisitorIndex />
            </WithWs>
          </Route>
          <Route path="/login/redirect">
            <WithAuth>
              <LoginRedirect />
            </WithAuth>
          </Route>
          <Route path="/">
            <WithAuth>
              <ScheduledSessionsLobby />
            </WithAuth>
          </Route>
        </Switch>
      </BrowserRouter>
      <VersionInfo />
    </>
  );
};
