import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Token, LoggedInUser } from "../api/client";

export interface AuthState {
  token: Token | null;
  user: LoggedInUser | null;
}

const initialState: AuthState = { user: null, token: null };

const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    tokenObtained: (state, action: PayloadAction<{ token: Token }>) => ({
      ...state,
      token: action.payload.token
    }),
    userLoggedIn: (state, action: PayloadAction<{ user: LoggedInUser }>) => ({
      ...state,
      user: action.payload.user
    }),
    logOut: (state) => ({
      ...state,
      token: null,
      user: null
    })
  }
});

export const AuthActions = actions;
export const authReducer = reducer;
