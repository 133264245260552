import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { Hint } from "../ux/hint";
import { ChangeVolume } from "../volume-control/change-volume";
import {
  getConferenceVolume,
  getPresentationVolume
} from "../volume-control/volume-control-selectors";
import { VolumeControlActions } from "../volume-control/volume-control-slice";
import { AVControls } from "./av-controls";

import { ReactComponent as IconAudio } from "client/assets/images/16/audio.svg";
import { ReactComponent as IconCam } from "client/assets/images/16/cam.svg";
import { ReactComponent as IconMic } from "client/assets/images/16/mic.svg";
import { ReactComponent as IconSpeech } from "client/assets/images/16/speech.svg";

export const VisitorAVControls: React.FC<AVControls> = ({
  disabledVideo,
  muted,
  onToggleDisableVideo,
  onToggleMute
}): JSX.Element => {
  const dispatch = useDispatch();

  const conferenceVolume = useSelector(getConferenceVolume);
  const changeConferenceValue = useCallback(
    (value: number) => {
      dispatch(VolumeControlActions.setConferenceVolume({ volume: value }));
    },
    [dispatch]
  );

  const presentationVolume = useSelector(getPresentationVolume);
  const changePresentationVolume = useCallback(
    (value: number) => {
      dispatch(VolumeControlActions.setPresentationVolume({ volume: value }));
    },
    [dispatch]
  );

  return (
    <div data-visitor="controls">
      <div className="group">
        <div>
          <Hint label="Presentation volume" placement="top">
            <span>
              <ChangeVolume
                handleIcon={<IconAudio />}
                onChange={changePresentationVolume}
                value={presentationVolume}
                vertical
              />
              <span
                className={cx("control", {
                  "is-active": presentationVolume === 0
                })}
                data-control="audio"
              >
                <span className="control__element">
                  <IconAudio />
                </span>
              </span>
            </span>
          </Hint>
        </div>
        <div>
          <Hint label="Conference volume" placement="top">
            <span>
              <ChangeVolume
                handleIcon={<IconSpeech />}
                onChange={changeConferenceValue}
                value={conferenceVolume}
                vertical
              />
              <span
                className={cx("control", {
                  "is-active": conferenceVolume === 0
                })}
                data-control="speech"
                onClick={onToggleDisableVideo}
              >
                <span className="control__element">
                  <IconSpeech />
                </span>
              </span>
            </span>
          </Hint>
        </div>
      </div>
      <div className="group">
        <Hint
          label={disabledVideo ? "Enable video" : "Disable video"}
          placement="top"
        >
          <span
            className={cx("control", { "is-active": disabledVideo })}
            data-control="cam"
            onClick={onToggleDisableVideo}
          >
            <button type="button" className="control__element">
              <IconCam />
            </button>
          </span>
        </Hint>
        <Hint label={muted ? "Unmute" : "Mute"} placement="top">
          <span
            className={cx("control", { "is-active": muted })}
            data-control="mic"
            onClick={onToggleMute}
          >
            <button type="button" className="control__element">
              <IconMic />
            </button>
          </span>
        </Hint>
      </div>
    </div>
  );
};
