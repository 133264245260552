import { useCallback, useEffect } from "react";
import moment from "moment";
import querystring from "querystring";
import URI from "urijs";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Logger as logger } from "purplex-logging";
import { getUnauthenticatedClient, Token } from "../api/client";
import { AuthActions } from "./auth-slice";
import { redirectToLogin } from "./redirect-to-login";
import { getConfig } from "../root/root-selectors";
import { useApi } from "../api/use-api";
import { getToken } from "./auth-selectors";
import {
  EXPIRATION_KEY,
  getLocalStorageItem,
  setLocalStorageItem,
  TOKEN_KEY
} from "../../local-storage-utils";

const unauthenticatedApi = getUnauthenticatedClient();

export const useLogout = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    dispatch(AuthActions.logOut());
  }, [dispatch]);
};

export const useAuth = (loginOnFail: boolean, redirectOnAuthUrl?: string) => {
  const dispatch = useDispatch();
  const appConfig = useSelector(getConfig);
  const api = useApi();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (appConfig.CMS_URL && appConfig.OAUTH_CLIENT_ID) {
        logger.debug("Starting authentication process");

        const uri = URI(window.location.href);
        const query = uri.query();
        const { code } = querystring.parse(query);

        if (code) {
          logger.debug("Found authorization code, getting token.");
          const token = await unauthenticatedApi.getToken(code as string);
          const tokenExpiration = moment().add(token.expires_in, "seconds");
          setLocalStorageItem(TOKEN_KEY, token);
          setLocalStorageItem(EXPIRATION_KEY, tokenExpiration);
        }

        const token = getLocalStorageItem<Token>(TOKEN_KEY);
        const tokenExpiration = getLocalStorageItem<number>(EXPIRATION_KEY);
        const hasTokenExpired = () => {
          if (!tokenExpiration) {
            return true;
          }

          return moment(tokenExpiration).isBefore(moment());
        };

        if (!token || hasTokenExpired()) {
          if (loginOnFail) {
            redirectToLogin(appConfig.CMS_URL, appConfig.OAUTH_CLIENT_ID, {
              url: window.location.href
            });
          }
          return;
        } else {
          logger.debug("Token obtained");
          dispatch(AuthActions.tokenObtained({ token }));
        }
      } else {
        logger.warn(
          "Could not start authentication process because OAUTH_CLIENT_ID or CMS_URL is missing"
        );
      }
    })();
  }, [dispatch, appConfig, loginOnFail, history, redirectOnAuthUrl]);

  useEffect(() => {
    (async () => {
      if (api) {
        const user = await api?.getUserInfo();
        if (user) {
          dispatch(AuthActions.userLoggedIn({ user: user.user }));
        }
        if (user && redirectOnAuthUrl && history) {
          history.push(redirectOnAuthUrl);
        }
      }
    })();
  }, [dispatch, api, history, redirectOnAuthUrl]);

  return useSelector(getToken);
};
