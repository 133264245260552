import { useEffect, useState } from "react";
import { Logger as logger } from "purplex-logging";

export const ASSETS_CACHE_KEY = "assets";

let cache: Cache | null = null;

export const getCache = async () => {
  if (!cache) {
    try {
      cache = await caches.open(ASSETS_CACHE_KEY);
    } catch (e) {
      logger.error("Cannot get cache");
    }
  }

  return cache;
};
export const useAssetCache = () => {
  const [localCache, setLocalCache] = useState<Cache>();

  useEffect(() => {
    (async () => {
      const cache = await getCache();
      if (cache) {
        setLocalCache(cache);
      }
    })();
  }, []);

  return localCache;
};
