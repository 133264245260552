import React from "react";

export const UrlAsset = ({ url, title }: { url: string; title: string }) => {
  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    </div>
  );
};
