import { createSelector } from "@reduxjs/toolkit";
import { getSession } from "../root/root-selectors";

export const getSessionConnection = createSelector(
  getSession,
  (state) => state.connection
);

export const geSessionConnectionId = createSelector(
  getSessionConnection,
  (state) => state?.socketId
);

export const getPinError = createSelector(
  getSessionConnection,
  (state) => state?.pinError
);

export const getConnectionStatus = createSelector(
  getSessionConnection,
  (state) => state?.status
);

export const getConferenceUserId = createSelector(
  getSessionConnection,
  (session) => session?.conference.conferenceUserId
);

export const getConferenceToken = createSelector(
  getSessionConnection,
  (session) => session?.conference.token
);
