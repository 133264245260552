import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutoSizer } from "react-virtualized";
import _ from "lodash";

import { BackgroundComponent } from "./presentation-components/background-component";
import { SceneAssetsComponent } from "./presentation-components/scene-assets-component";
import { WallWrapperComponent } from "./presentation-components/wall-wrapper-component";
import { PresentationClientData } from "../../../shared/types/front-end-data";
import { Logger as logger } from "purplex-logging";
import { AssetType } from "shared/types/domain";
import { SceneSoundAssetsComponent } from "./presentation-components/scene-sound-assets-component";
import { PresentationSceneSwitchOverlay } from "./presentation-scene-switch-overlay";
import { useGetApiOrMock } from "./use-get-api-or-mock";
import { usePreloadFonts } from "./use-preload-fonts";

import "client/assets/stylesheets/views/presenter.scss";
import "client/assets/stylesheets/views/visitor.scss";

interface PresenterProps {
  authenticated?: boolean;
  state: PresentationClientData;
  volume: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {};

export const PresentationComponent = (props: PresenterProps) => {
  usePreloadFonts();
  const api = useGetApiOrMock(props.authenticated);

  const { state, volume } = props;

  // eslint-disable-next-line
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line
  const [width, setWidth] = useState(0);

  const stageCanvasRef = useRef(null);

  let parentWidth = 0;
  let parentHeight = 0;
  if (stageCanvasRef && stageCanvasRef.current) {
    // @ts-ignore
    parentWidth = stageCanvasRef.current.offsetWidth;
    // @ts-ignore
    parentHeight = stageCanvasRef.current.offsetHeight;
  }

  const resizeListener = useCallback(
    _.throttle(
      (height: number, width: number) => {
        setHeight(height);
        setWidth(width);
      },
      100,
      { trailing: true }
    ),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (stageCanvasRef && stageCanvasRef.current) {
        resizeListener(
          // @ts-ignore
          stageCanvasRef.current.offsetHeight || 0,
          // @ts-ignore
          stageCanvasRef.current.offsetWidth || 0
        );
      }
    });
  }, [resizeListener]);

  setTimeout(() => {
    if (stageCanvasRef && stageCanvasRef.current) {
      resizeListener(
        // @ts-ignore
        stageCanvasRef.current.offsetHeight,
        // @ts-ignore
        stageCanvasRef.current.offsetWidth
      );
    }
  }, 300);

  useEffect(() => {
    resizeListener(parentHeight, parentWidth);
  }, [resizeListener, parentHeight, parentWidth]);

  const onTimeUpdatedWall = useCallback(
    (assetUuid: string, value: number, duration: number) => {
      api?.changePlayback(value, duration, undefined, assetUuid);
    },
    [api]
  );

  const onPausedWall = useCallback(
    (assetUuid: string) => {
      api?.videoPaused(undefined, assetUuid);
    },
    [api]
  );

  const onPausedSceneAsset = useCallback(
    (assetId: number) => {
      logger.debug(`Scene asset ${assetId} pasued`);
      api?.videoPaused(assetId, undefined);
    },
    [api]
  );

  const onPlayingSceneAsset = useCallback(
    (assetId: number) => {
      logger.debug(`Scene asset ${assetId} started playing`);
      api?.videoPlaying(assetId, undefined);
    },
    [api]
  );

  const onPlayingWall = useCallback(
    (assetUuid: string) => {
      logger.debug(`Wall ${assetUuid} started playing`);
      api?.videoPlaying(undefined, assetUuid);
    },
    [api]
  );

  const onTimeUpdatedSceneAsset = useCallback(
    (assetId: number, value: number, duration: number) => {
      api?.changePlayback(value, duration, assetId, undefined);
    },
    [api]
  );

  // clone walls and enrich them with animation props
  const wallsCloned = state.walls.map((w) => ({ ...w, scale: 1 }));

  const sortedWallsByWidth = wallsCloned
    .slice()
    .sort((a, b) => a.width - b.width);

  // In cases like NY room - where the room is not rectangular,
  // we want to scale up shorter wall a little bit and make
  // illusion of rectangular room. Let's keep our life simple.
  const shortWallWidth =
    sortedWallsByWidth[sortedWallsByWidth.length === 4 ? 1 : 0].width;
  const longWallWidth = sortedWallsByWidth[sortedWallsByWidth.length - 1].width;

  if (sortedWallsByWidth.length === 4) {
    sortedWallsByWidth[0].scale =
      sortedWallsByWidth[1].width / sortedWallsByWidth[0].width;
    sortedWallsByWidth[2].scale =
      sortedWallsByWidth[3].width / sortedWallsByWidth[2].width;
  }

  // find height of the hightest wall
  const wallHeight = wallsCloned.reduce(
    (max, wall) => (wall.height > max ? wall.height : max),
    state.walls[0].height
  );

  const activeWallWidth =
    wallsCloned[(state.activeWallNumber || 1) - 1].width *
    wallsCloned[(state.activeWallNumber || 1) - 1].scale;

  const sideWall =
    sortedWallsByWidth.length === 1
      ? wallsCloned[0]
      : wallsCloned[Math.abs((state.activeWallNumber || 1) - 1 - 1)];
  const sideWallWidth = sideWall.width * sideWall.scale;

  const perspective = shortWallWidth / 2;

  // find perfect scale for browser viewport
  const getScale = (width: number, height: number) => {
    const perspectiveCorrection = sideWallWidth / shortWallWidth + 1;
    const verticalScale = (height / wallHeight) * perspectiveCorrection;
    const horizontalScale = (width / activeWallWidth) * perspectiveCorrection;
    return horizontalScale < verticalScale ? horizontalScale : verticalScale;
  };

  if (!state) {
    return <></>;
  }

  const allSceneAssets = state.sceneAssets;
  const soundSceneAssets = allSceneAssets.filter(
    (sceneAsset) => sceneAsset.playAs === AssetType.ASSET_SOUND
  );

  return (
    <AutoSizer>
      {({ width, height }) => {
        const scale = getScale(width, height);

        const roomStyles = {
          width: shortWallWidth,
          height: wallHeight,
          transform: "none"
        };

        if (scale !== 0) {
          roomStyles.transform = `scale(${scale}) rotateY(${
            ((state.activeWallNumber || 1) - 1) * 90
          }deg)`;
        }

        return (
          <div
            className="scene"
            style={{
              perspective,
              width: shortWallWidth,
              height: wallHeight,
              marginLeft: shortWallWidth / -2,
              marginTop: wallHeight / -2
            }}
            ref={stageCanvasRef}
          >
            <div className="room" style={roomStyles}>
              <SceneSoundAssetsComponent
                volume={volume}
                sceneAssets={soundSceneAssets}
                onTimeUpdated={onTimeUpdatedSceneAsset}
              />
              {wallsCloned
                .sort((a, b) => a.num - b.num)
                .map((wall, index) => {
                  const firstWall = index === 0;
                  const activeWall = wall.num === state.activeWallNumber;

                  const wallSceneAssets = _.difference(
                    allSceneAssets,
                    soundSceneAssets
                  ).filter((sceneAsset) => sceneAsset.wallNumber === wall.num);

                  return (
                    <React.Fragment key={index}>
                      <WallWrapperComponent
                        wallSpecification={wall}
                        scale={wall.scale}
                        visible={activeWall}
                        roomWidth={longWallWidth}
                      >
                        <PresentationSceneSwitchOverlay
                          transition={state.sceneTransition}
                          wallNumber={wall.num}
                          volume={volume}
                          authenticated={props.authenticated}
                        />
                        <BackgroundComponent
                          wallNumber={wall.num}
                          background={state.background}
                          volume={volume}
                          onTimeUpdated={activeWall ? onTimeUpdatedWall : NOOP}
                          onPaused={activeWall ? onPausedWall : NOOP}
                          onPlaying={activeWall ? onPlayingWall : NOOP}
                          muted={!firstWall}
                        />
                        <SceneAssetsComponent
                          authenticated={props.authenticated}
                          sceneAssets={wallSceneAssets}
                          wallSpecification={wall}
                          volume={volume}
                          scaling={scale / 2}
                          onTimeUpdated={
                            activeWall ? onTimeUpdatedSceneAsset : NOOP
                          }
                          onPaused={activeWall ? onPausedSceneAsset : NOOP}
                          onPlaying={activeWall ? onPlayingSceneAsset : NOOP}
                        />
                      </WallWrapperComponent>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        );
      }}
    </AutoSizer>
  );
};
