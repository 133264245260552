import React from "react";
import { SceneAsset } from "shared/domain/presentation/presentation-selectors";
import { SoundAsset } from "./sound-asset";

export const SceneSoundAssetsComponent = ({
  sceneAssets,
  volume,
  onTimeUpdated
}: {
  sceneAssets: SceneAsset[];
  volume: number;
  onTimeUpdated: (assetId: number, value: number, duration: number) => void;
}) => (
  <>
    {sceneAssets.map((sceneAsset, index) => (
      <SoundAsset
        key={index}
        id={sceneAsset.id}
        muted={false}
        volume={volume}
        url={sceneAsset.url}
        autoplay={sceneAsset.autoplay}
        playing={sceneAsset.playing}
        loop={sceneAsset.loop}
        visible={sceneAsset.visible}
        onTimeUpdate={(time: number, duration: number) =>
          onTimeUpdated(sceneAsset.id, time, duration)
        }
      />
    ))}
  </>
);
