import React from "react";
import { useSelector } from "react-redux";

import { getVersion } from "../config/config-selectors";

export const VersionInfo = () => {
  const version = useSelector(getVersion);

  if (version) {
    return <span id="version">Version {version}</span>;
  } else {
    return <></>;
  }
};
