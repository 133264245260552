import { useEffect, MutableRefObject, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Logger as logger } from "purplex-logging";
import { AssetType } from "shared/types/domain";
import {
  getSceneAssetsProgress,
  getWallProgress
} from "../../presenter-view/presenter-selectors";

export const useGetMediaProgress = (type: AssetType, id: number | string) => {
  const wallProgress = useSelector(getWallProgress);
  const sceneAssetsProgress = useSelector(getSceneAssetsProgress);

  if (type === AssetType.ASSET_WALL_VIDEO) {
    return wallProgress && wallProgress[id as string];
  } else {
    return id !== undefined && sceneAssetsProgress
      ? sceneAssetsProgress[id as number]
      : 0;
  }
};

// Hook responsible for keeping play/pause
// synchronized with what server is dictating
export const useKeepSynchornizedWithBackendPlaying = (
  ref: MutableRefObject<HTMLMediaElement | null>,
  playing: boolean,
  visible: boolean
) => {
  useEffect(() => {
    if (ref.current) {
      logger.debug(`Media tag has changed its state deps`);
      if (playing && ref.current.paused) {
        logger.debug("Playing the media tag");
        ref.current.play();
      } else if ((!playing || !visible) && !ref.current.paused) {
        logger.debug("Pausing the media tag");
        ref.current.pause();
      }
    }
  }, [playing, visible, ref]);
};

export const useSynchronizeMediaOnComponentMount = (
  ref: MutableRefObject<HTMLMediaElement | null>,
  sendingProgress: boolean,
  cacheResolved: boolean,
  changeSendingProgress: Dispatch<SetStateAction<boolean>>,
  mediaProgress?: number
) => {
  // This effect makes sure that it
  // presets the time of media on initial load of the component
  // That's why it sets sendingProgress to true afterwards
  // so that component is considered "initialized"
  //
  // Also, all the undelying DOM nodes are resolved dynamically from cache
  // that's why it needs to be parameterized by cahceResolved flag
  // so that the app knows exactly when the DOM node is ready
  useEffect(() => {
    if (
      !sendingProgress &&
      mediaProgress !== undefined &&
      ref.current &&
      cacheResolved
    ) {
      ref.current.currentTime = mediaProgress;
    }

    if (!sendingProgress && ref.current && cacheResolved) {
      changeSendingProgress(true);
    }
  }, [
    ref,
    sendingProgress,
    mediaProgress,
    changeSendingProgress,
    cacheResolved
  ]);
};

// Hook responsible for keeping the progress in sync
// with what server is dictating
export const useSynchronizeMediaPlayback = (
  ref: MutableRefObject<HTMLMediaElement | null>,
  shouldSynchronize: boolean,
  mediaProgress?: number
) => {
  const SYNC_MEDIA_TRESHOLD_SEC = 1;

  useEffect(() => {
    // Make sure media progress has been delivered from BE as well as
    // DOM ref has been instantiated.
    // And by shouldSynchronize we can basically say to ignore synchronization
    if (mediaProgress !== undefined && ref.current && shouldSynchronize) {
      logger.debug(
        `Backend media progress ${mediaProgress} - frontend media progress ${ref.current.currentTime}`
      );

      const dt = Math.abs(mediaProgress - ref.current.currentTime);
      logger.debug(`Delta ${dt}`);
      if (dt > SYNC_MEDIA_TRESHOLD_SEC) {
        logger.debug("Syncing media");
        ref.current.currentTime = mediaProgress;
      }
    }
  }, [ref, mediaProgress, shouldSynchronize]);
};
