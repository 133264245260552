import moment from "moment";
import { randomBytes } from "crypto";

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const isSessionExpired = (date: string) => {
  const dateObject = moment(date);
  const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return dateObject < now;
};

export const processEmailString = (emails: string) =>
  emails
    .replace(";", ",")
    .replace(" ", ",")
    .trim()
    .split(",")
    .filter((email: string) => !!email);

export const getShareLink = (token: string) =>
  `${process.env.VIX_URL}/presentation/${token}`;

export const createUniquePin = (): string => {
  return `${parseInt(randomBytes(4).toString("hex"), 16)}`.slice(0, 4);
};
