import { useEffect, MutableRefObject } from "react";
import { Logger as logger } from "purplex-logging";

export const useKeepVolumeSynchronized = (
  ref: MutableRefObject<HTMLMediaElement | null>,
  volume: number
) => {
  useEffect(() => {
    if (ref.current) {
      logger.debug(
        `Media tag has changed its volume deps changing to ${volume}`
      );
      ref.current.volume = volume;
    }
  }, [volume, ref]);
};
