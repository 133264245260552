import React, { useRef, createContext } from "react";
import {
  ConfirmationDialog,
  ConfirmationOptions,
  DialogAnswer,
  DialogTemplatePlaceholder
} from "./dialog";

const ConfirmationServiceContext = createContext<
  (options: ConfirmationOptions) => Promise<DialogAnswer>
>(() => {
  const promise: Promise<DialogAnswer> = Promise.resolve(DialogAnswer.OK);
  return promise;
});

export const useCreateConfirmationDialog = () =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider: React.FC = ({ children }) => {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: (answer: DialogAnswer) => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    const promise: Promise<DialogAnswer> = new Promise((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
    return promise;
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(DialogAnswer.CANCEL);
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(DialogAnswer.OK);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <DialogTemplatePlaceholder />

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
