import React, { useCallback, useContext } from "react";

import { DialogAnswer } from "../modal/dialog";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { WebSocketContext } from "../root/root-component";

import { ReactComponent as IconBan } from "client/assets/images/16/ban.svg";
import { ReactComponent as IconMic } from "client/assets/images/16/mic.svg";

interface AttendeeVideoComponentProps {
  loggedInUserIsPresenter: boolean;
  socketId?: string;
  isLocalClient: boolean;
  disabledVideo: boolean;
  videoComponentWrapperId: string;
  userName?: string;
  userId: number;
  mutedUsers?: number[];
}

export const AttendeeVideoComponent = ({
  loggedInUserIsPresenter,
  socketId,
  videoComponentWrapperId,
  isLocalClient,
  disabledVideo,
  userName,
  userId,
  mutedUsers
}: AttendeeVideoComponentProps) => {
  const wsApi = useContext(WebSocketContext);

  const muteUser = useCallback(() => {
    wsApi?.muteUser(userId);
  }, [userId, wsApi]);

  const isMuted = mutedUsers && mutedUsers.includes(userId);
  const nameInitials = userName?.substring(0, 1);

  const confirm = useCreateConfirmationDialog();

  const onKickOut = useCallback(() => {
    (async () => {
      const answer = await confirm({
        title: `Remove Participant`,
        description: `Are you sure you want to remove ${userName} from this session?`
      });
      if (answer === DialogAnswer.OK && socketId) {
        wsApi?.kickOutViewer(socketId);
      }
    })();
  }, [confirm, wsApi, socketId, userName]);

  return (
    <li className="list-user__item">
      <div className="list-user__item__title" data-clamp="1">
        {userName} {isLocalClient && <>(you)</>}
      </div>
      <div className="list-user__item__preview">
        <span className="list-user__item__avatar">
          <span className="avatar">{nameInitials}</span>
        </span>
        <div
          hidden={isLocalClient && disabledVideo}
          id={videoComponentWrapperId}
        />
        {isMuted && (
          <span className="list-user__item__labels">
            <span
              title="Muted"
              className="control is-active"
              data-control="mic"
            >
              <span className="control__element">
                <IconMic />
              </span>
            </span>
          </span>
        )}
        <div className="list-user__item__controls">
          {!isLocalClient && !isMuted && (
            <span
              title="Mute"
              className="control"
              data-control="mic"
              onClick={muteUser}
            >
              <button type="button" className="control__element">
                <IconMic />
              </button>
            </span>
          )}
          {loggedInUserIsPresenter && (
            <span
              title="Kick out"
              className="control is-active"
              data-control="ban"
              onClick={onKickOut}
            >
              <button type="button" className="control__element">
                <IconBan />
              </button>
            </span>
          )}
        </div>
      </div>
    </li>
  );
};
