const AGGREGATE_RESOLUTION_CAP = 1280 * 720;

export const PRESENTER_SMALL_VIEW = { width: 225, height: 130 };
export const PRESENTER_SCREENSHARING_VIEW = { width: 1280, height: 720 };
export const VISITOR_VIEW = { width: 90, height: 50 };

export const getOptimalNumberOfVisitors = () => {
  let acc = PRESENTER_SMALL_VIEW.width * PRESENTER_SMALL_VIEW.height;
  let visitors = 0;

  while (acc <= AGGREGATE_RESOLUTION_CAP) {
    visitors++;
    acc += VISITOR_VIEW.width * VISITOR_VIEW.height;
  }

  return visitors - 1;
};

export const MAX_PEOPLE_IN_CONFERENCE = Math.min(
  10,
  getOptimalNumberOfVisitors() - 1
);
