import { SessionConnectionStatus } from "./connections/connections-slice";

export enum CommandType {
  START_PRESENTATION = "START_PRESENTATION",
  STOP_PRESENTATION = "STOP_PRESENTATION",
  RESTART_PRESENTATION = "RESTART_PRESENTATION",
  STOP_SESSION = "STOP_SESSION",
  START_SESSION = "START_SESSION",
  SUBSCRIBE_TO_PRESENTATION_DATA_CHANGES = "SUBSCRIBE_TO_PRESENTATION_DATA_CHANGES",
  UPDATE_ASSETS = "UPDATE_ASSETS",
  CHANGE_SCENE = "CHANGE_SCENE",
  CHANGE_WALL = "CHANGE_WALL",
  TOGGLE_ASSET = "TOGGLE_ASSET",
  TOGGLE_ASSET_PLAYING = "TOGGLE_ASSET_PLAYING",
  ASSET_CLICKED = "ASSET_CLICKED",
  TOGGLE_PLAY_PAUSE_WALL = "TOGGLE_PLAY_PAUSE_WALL",
  CHANGE_PLAYBACK = "CHANGE_PLAYBACK",
  SESSION_OWNER_CONNECTED = "SESSION_OWNER_CONNECTED",
  SESSION_OWNER_DISCONNECTED = "SESSION_OWNER_DISCONNECTED",
  MOVE_ASSET = "MOVE_ASSET",
  PDF_NEXT_PAGE = "PDF_NEXT_PAGE",
  PDF_PREV_PAGE = "PDF_PREV_PAGE",
  SET_PRESENTER_CONFERENCE_USER_ID = "SET_PRESENTER_CONFERENCE_USER_ID",
  VIDEO_PAUSED = "VIDEO_PAUSED",
  VIDEO_PLAYING = "VIDEO_PLAYING",
  RESTART_WALL_VIDEO = "RESTART_WALL_VIDEO",
  CHANGE_VOLUME = "CHANGE_VOLUME",
  USER_CONNECTED_TO_SESSION = "USER_CONNECTED_TO_SESSION",
  USER_DISCONNECTED_FROM_SESSION = "USER_DISCONNECTED_FROM_SESSION",
  CHANGE_NAME = "CHANGE_NAME",
  SUBMIT_PIN_CODE = "SUBMIT_PIN_CODE",
  RESEND_PIN_CODE = "RESEND_PIN_CODE",
  ASK_FOR_ADMISSION = "ASK_FOR_ADMISSION",
  GRANT_ADMISSION = "GRANT_ADMISSION",
  REJECT_ADMISSION = "REJECT_ADMISSION",
  KICK_OUT_VIEWER = "KICK_OUT_VIEWER",
  JOIN_PRESENTATION = "JOIN_PRESENTATION",
  LEAVE_PRESENTATION = "LEAVE_PRESENTATION",
  UPDATE_SESSION_DATA = "UPDATE_SESSION_DATA",
  GENERATE_LINK = "GENERATE_LINK",
  INVITE_PEOPLE = "INVITE_PEOPLE",
  REGENERATE_CONFERENCE_TOKEN = "REGENERATE_CONFERENCE_TOKEN",
  SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE",
  SEND_SCREEN_SHARE = "SEND_SCREEN_SHARE",
  MUTE_USER = "MUTE_USER",
  MUTE_ALL = "MUTE_ALL",
  CHANGE_MUTE_STATE = "CHANGE_MUTE_STATE",
  REPORT_AGORA = "REPORT_AGORA"
}

const NOT_AUTHENTICATED_COMMANDS = [
  CommandType.SUBMIT_PIN_CODE,
  CommandType.RESEND_PIN_CODE
];

const AUTHENTICATED_COMMANDS = [
  CommandType.ASK_FOR_ADMISSION,
  CommandType.CHANGE_NAME,
  CommandType.JOIN_PRESENTATION,
  CommandType.LEAVE_PRESENTATION,
  CommandType.REGENERATE_CONFERENCE_TOKEN,
  CommandType.SEND_CHAT_MESSAGE,
  CommandType.MUTE_USER,
  CommandType.CHANGE_MUTE_STATE,
  CommandType.REPORT_AGORA
];

export const commandACLMap: {
  [key in SessionConnectionStatus]: CommandType[];
} = {
  [SessionConnectionStatus.OWNER]: [...Object.values(CommandType)],
  [SessionConnectionStatus.OWNER_CONTROL]: [...Object.values(CommandType)],
  [SessionConnectionStatus.NOT_AUTHENTICATED]: NOT_AUTHENTICATED_COMMANDS,
  [SessionConnectionStatus.AUTHENTICATED]: AUTHENTICATED_COMMANDS,
  [SessionConnectionStatus.JOINED_PRESENTATION]: AUTHENTICATED_COMMANDS,
  [SessionConnectionStatus.KICKED_OUT]: [],
  [SessionConnectionStatus.ALREADY_CONNECTED]: []
};
