import React from "react";
import cx from "classnames";

import { BytesRenderer } from "./bytes-renderer";

interface ProgressIndicatorProps extends React.HTMLAttributes<HTMLElement> {
  primary?: boolean;
  progress: number;
  size?: number;
  status?: string;
  time?: number;
  title?: string;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  primary,
  progress,
  size,
  status,
  time,
  title,
  ...props
}): JSX.Element => {
  const hasTitle = title && title !== "";

  return (
    <div
      className={cx("indicator-progress", {
        "--primary": primary,
        "has-title": hasTitle
      })}
      {...props}
    >
      <span
        className="indicator-progress__scale"
        style={{ ["--progress" as string]: progress ?? 0 }}
      />
      <span className="indicator-progress__body">
        {primary ? (
          <>
            {hasTitle && (
              <span className="indicator-progress__title">{title}...</span>
            )}
            {progress ?? 0} %
            {time && (
              <span style={{ marginLeft: "1em" }}>
                Estimated time: {Math.floor(time / 1000)} sec
              </span>
            )}
          </>
        ) : (
          <>
            <span>
              <span
                className="u-inline-block u-truncate u-text --bottom"
                style={{ marginRight: "1em", maxWidth: "70%" }}
              >
                {title}
              </span>
              <BytesRenderer value={size ?? 0} />
            </span>
            <span>
              {status && status !== "" && (
                <span style={{ marginRight: "1em" }}>{status}</span>
              )}
              {progress ?? 0} %
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export default ProgressIndicator;
