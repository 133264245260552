import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactCodeInput from "react-code-input";

import { WebSocketContext } from "../root/root-component";
import { getPinError } from "../web-sockets/session-selectors";
import { useNotifications } from "../ux/notifications";
import { getNotauthenticatedError } from "./visitor-selectors";

import "../../assets/stylesheets/views/visitor-errors.scss";
import "../../assets/stylesheets/views/not-authenticated-visitor.scss";
import Image from "../../assets/images/error-invalid.webp";

// FIXME: refactor ¯\(°_o)/¯

export const ErrorPage = () => {
  return (
    <div className="app app-errors">
      <header className="header">
        <h1>This link seems to be invalid.</h1>
      </header>
      <div className="bottom-container">
        <div className="left-column">
          <h2>Nothing here</h2>
          <p>
            This link seems to be invalid. Please check if the link is correct
            and try again.
          </p>
        </div>
        <img src={Image} alt="" className="visitor-error-image" />
      </div>
    </div>
  );
};

export const NotAuthenticated = () => {
  const [pinValue, setPinValue] = useState("");
  const [localError, setLocalError] = useState(false);
  const [hiddenPin, setHiddenPin] = useState(false);
  const [pinRequested, setPinRequested] = useState(false);

  const wsApi = useContext(WebSocketContext);
  const pinError = useSelector(getPinError);
  const sessionError = useSelector(getNotauthenticatedError);

  const notifications = useNotifications();

  const submitPin = useCallback(() => {
    wsApi?.submitPin(pinValue);
  }, [wsApi, pinValue]);

  const sendPin = useCallback(
    (e) => {
      e.preventDefault();
      wsApi?.sendPin();
      notifications?.success("PIN code sent to your email");
      setLocalError(false);
      setPinValue("");
      //Pin component value does not react to change, so we force rerender of the component
      setHiddenPin(true);
      setTimeout(() => {
        setHiddenPin(false);
      }, 0);

      setPinRequested(true);
    },
    [wsApi, notifications, setPinRequested]
  );

  const onPinChange = useCallback((value: string) => {
    setPinValue(value);
    setLocalError(false);
  }, []);

  useEffect(() => {
    if (pinError && pinValue.length === 4) {
      setLocalError(true);
    }
  }, [pinError, pinValue]);

  if (sessionError) {
    return <ErrorPage />;
  }

  return (
    <div className="app not-authenticated-view">
      <header>
        <h1>Welcome in the Online Lobby</h1>
      </header>

      <div className="container --narrow">
        <div className="card --primary">
          <h3 className="u-rhythm">Join Session Lobby</h3>
          <p
            style={{
              background: localError ? "red" : "white"
            }}
          >
            {localError && "Wrong pin, please try again"}
          </p>
          <div className="card-content">
            {!hiddenPin && pinRequested && (
              <ReactCodeInput
                className="code-input"
                type="password"
                fields={4}
                inputStyle={{
                  fontFamily: "monospace",
                  borderRadius: "6px",
                  border: localError
                    ? "2px solid red"
                    : pinValue.length === 4
                    ? "2px solid #28d028"
                    : "1px solid lightgrey",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
                  margin: "4px",
                  marginBottom: "4vh",
                  padding: "8px",
                  width: "42px",
                  height: "42px",
                  fontSize: "32px",
                  boxSizing: "border-box",
                  color: localError ? "red" : "black",
                  backgroundColor: "white",
                  textAlign: "center"
                }}
                autofocus
                onChange={onPinChange}
                value={pinValue}
                valid={localError}
              />
            )}
            {pinValue.length !== 4 || localError ? (
              <button
                className="button --primary"
                onClick={sendPin}
                type="button"
              >
                Request Pin Code
              </button>
            ) : (
              <button
                type="button"
                className="button --primary"
                onClick={submitPin}
              >
                Join Lobby
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
