import { Logger as logger } from "purplex-logging";
import { HtmlPortalNode, InPortal } from "react-reverse-portal";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { getConferenceUserId } from "../web-sockets/session-selectors";
import { useAgora } from "./use-agora";
import { ChatComponent } from "./chat-component";
import { VisitorAVControls } from "./visitor-av-controls";
import { WebSocketContext } from "../root/root-component";
import {
  getMutedUsers as getMutedUsersVisitor,
  getParticipants as getParticipantsVisitor,
  isScreenSharing
} from "../visitor-view/visitor-selectors";
import {
  getConferenceUserMappingToSocketId,
  getMutedUsers as getMutedUsersPresenter,
  getParticipants as getParticipantsPresenter
} from "../presenter-view/presenter-selectors";
import { PresenterVideoComponent } from "./presenter-video-component";
import { AttendeeVideoComponent } from "./attendee-video-component";
import { AttendeeOffline } from "./attendee-offline";
import { InvitePeopleDialog } from "../presenter-view/presenter-components/invite-people-dialog";

import { ReactComponent as IconClose } from "client/assets/images/16/close.svg";
import { ReactComponent as IconStop } from "client/assets/images/16/stop.svg";
import { ReactComponent as IconChat } from "client/assets/images/24/chat.svg";
import { ReactComponent as IconMic } from "client/assets/images/24/mic.svg";
import { ReactComponent as IconPeople } from "client/assets/images/24/people.svg";
import { ReactComponent as IconPeoplePlus } from "client/assets/images/24/people-plus.svg";
import { ReactComponent as IconShare } from "client/assets/images/24/share.svg";

const localStreamId = "local_stream_wrapper";
const getRemoteStreamId = (id: number) => `remote_stream_wrapper_${id}`;

interface VideoConferencePanelProps {
  sessionId: string;
  presenterId: number;
  users: { conferenceUserId: number; username: string }[];
  bigScreenPortal?: HtmlPortalNode;
  sidePanelPortal: HtmlPortalNode;
  sidebar?: boolean;
  onSidebar?: (show: boolean) => void;
}

const getUserIdFromWrapperId = (wrapperId: string, localUserId: number) => {
  if (wrapperId === localStreamId) {
    return localUserId;
  } else {
    const splitted = wrapperId.split("_");
    return parseInt(splitted[splitted.length - 1]);
  }
};

const toggle = (value: boolean) => !value;

enum VideoConferenceTab {
  People,
  Chat
}

export const VideoConferencePanel = ({
  sessionId,
  presenterId,
  users,
  bigScreenPortal,
  sidePanelPortal,
  sidebar,
  onSidebar
}: VideoConferencePanelProps) => {
  const conferenceUserId = useSelector(getConferenceUserId);
  const isPresenter = conferenceUserId === presenterId;

  const [inviteDialogOpened, setInviteDialogOpened] = useState(false);
  const [tab, changeTab] = useState(VideoConferenceTab.People);
  const [localMuted, setLocalMuted] = useState(true);
  const [localDisabledVideo, setLocalDisabledVideo] = useState(true);
  const [screenShare, setScreenShare] = useState(false);

  const sharingScreen = useSelector(isScreenSharing);
  const mutedUsers = useSelector(
    isPresenter ? getMutedUsersPresenter : getMutedUsersVisitor
  );
  const participants = useSelector(
    isPresenter ? getParticipantsPresenter : getParticipantsVisitor
  );
  const conferenceUserToSocketMapping = useSelector(
    isPresenter
      ? getConferenceUserMappingToSocketId
      : () => ({} as { [key: number]: string })
  );

  const remoteStreams = useAgora(
    localStreamId,
    getRemoteStreamId,
    sessionId,
    screenShare,
    setScreenShare,
    localMuted,
    localDisabledVideo,
    isPresenter,
    !!sharingScreen,
    presenterId
  );

  const onToggleScreenShare = useCallback(() => {
    const inverse = (value: boolean) => !value;
    setScreenShare(inverse);
  }, [setScreenShare]);

  const wsApi = useContext(WebSocketContext);
  useEffect(() => {
    if (wsApi) {
      wsApi.sendScreenShare(screenShare);
    }
  }, [screenShare, wsApi]);

  useEffect(() => {
    if (
      !isPresenter &&
      conferenceUserId &&
      mutedUsers?.includes(conferenceUserId)
    ) {
      setLocalMuted(true);
    }
  }, [isPresenter, mutedUsers, setLocalMuted, conferenceUserId]);

  const videos = useMemo(() => {
    const allVideos = [...remoteStreams.map(getRemoteStreamId), localStreamId];
    allVideos.sort((a, b) => {
      // Local stream presenter goes first
      if (isPresenter && a === localStreamId) {
        return -1;
      }
      if (isPresenter && b === localStreamId) {
        return 1;
      }

      // Or remote stream presenter goes first
      if (a.endsWith(`_${presenterId.toString()}`)) {
        return -1;
      }
      if (b.endsWith(`_${presenterId.toString()}`)) {
        return 1;
      }

      // Local stream goes then
      if (!isPresenter && a === localStreamId) {
        return -1;
      }
      if (!isPresenter && b === localStreamId) {
        return 1;
      }

      // rest sort alphabetically (by IDs)
      return a.localeCompare(b);
    });

    return allVideos;
  }, [remoteStreams, isPresenter, presenterId]);

  const onToggleMute = useCallback(() => {
    setLocalMuted((value) => {
      const newValue = !value;
      wsApi?.changeMuteState(newValue);

      return newValue;
    });
  }, [setLocalMuted, wsApi]);

  const muteAll = useCallback(() => {
    wsApi?.muteAll();
  }, [wsApi]);

  const onToggleDisableVideo = useCallback(() => {
    setLocalDisabledVideo(toggle);
  }, [setLocalDisabledVideo]);

  const changeTabToPeople = useCallback(() => {
    const action = sidebar && tab === VideoConferenceTab.People ? false : true;
    changeTab(VideoConferenceTab.People);
    onSidebar && onSidebar(action);
  }, [changeTab, sidebar, onSidebar, tab]);

  const changeTabToChat = useCallback(() => {
    const action = sidebar && tab === VideoConferenceTab.Chat ? false : true;
    changeTab(VideoConferenceTab.Chat);
    onSidebar && onSidebar(action);
  }, [changeTab, sidebar, onSidebar, tab]);

  const hideSidebar = useCallback(() => {
    onSidebar && onSidebar(false);
  }, [onSidebar]);

  const onOpenInviteDialog = useCallback(() => {
    setInviteDialogOpened(true);
  }, []);

  const onCloseInviteDialog = useCallback(() => {
    setInviteDialogOpened(false);
  }, []);

  if (!conferenceUserId) {
    logger.warn("Missing conference user id");
    return <></>;
  }

  const getPresenterAndRest = () => {
    const presenterIndex = videos.findIndex(
      (wrapperId) =>
        getUserIdFromWrapperId(wrapperId, conferenceUserId) === presenterId
    );

    // Presenter yet not joined
    if (presenterIndex === -1) {
      return { rest: videos, presenter: null };
    } else {
      const videosCpy = [...videos];
      const [presenter] = videosCpy.splice(presenterIndex, 1);
      return { presenter, rest: videosCpy };
    }
  };

  const { presenter, rest } = getPresenterAndRest();

  return (
    <>
      <button
        className={cx({
          "is-active": sidebar && tab === VideoConferenceTab.People
        })}
        data-toggle="people"
        onClick={changeTabToPeople}
      >
        <IconPeople />
        {participants && (
          <span className="badge">
            {participants.filter((participant) => participant.online).length}
          </span>
        )}
      </button>
      <button
        className={cx({
          "is-active": sidebar && tab === VideoConferenceTab.Chat
        })}
        data-toggle="chat"
        onClick={changeTabToChat}
      >
        <IconChat />
      </button>
      {isPresenter && (
        <button
          className={cx({ "is-active": screenShare })}
          data-toggle="share"
          onClick={onToggleScreenShare}
        >
          <IconShare />
          {screenShare && (
            <span className="control" data-control="stop">
              <span className="control__element">
                <IconStop />
              </span>
            </span>
          )}
        </button>
      )}
      <div data-presenter="video">
        {presenter && (
          <PresenterVideoComponent
            videoComponentWrapperId={presenter}
            loggedInUserIsPresenter={isPresenter}
            isScreenSharing={!!sharingScreen}
            muted={localMuted}
            disabledVideo={localDisabledVideo}
            onToggleDisableVideo={onToggleDisableVideo}
            onToggleMute={onToggleMute}
            bigScreenPortal={bigScreenPortal}
          />
        )}
      </div>

      <InPortal node={sidePanelPortal}>
        <section
          className="people"
          style={{
            display: tab === VideoConferenceTab.People ? "block" : "none"
          }}
        >
          <header className="people__head">
            <div>
              <h3 className="people__title">
                People
                <span className="people__meta">
                  {participants &&
                    participants.filter((participant) => participant.online)
                      .length}
                  /{participants && participants.length}
                </span>
              </h3>
              <button
                type="button"
                className="people__control --close"
                onClick={hideSidebar}
              >
                <IconClose />
              </button>
            </div>
            {isPresenter && (
              <div>
                <button
                  type="button"
                  className="people__control"
                  onClick={onOpenInviteDialog}
                >
                  <IconPeoplePlus />
                  Invite
                </button>
                <button
                  type="button"
                  className="people__control"
                  onClick={muteAll}
                >
                  <IconMic />
                  Mute all
                </button>
              </div>
            )}
          </header>
          <div className="people__body">
            <ul className="list-user">
              {rest.map((wrapperId, index) => {
                const userId = getUserIdFromWrapperId(
                  wrapperId,
                  conferenceUserId
                );
                const userInfo = users.find(
                  (user) => user.conferenceUserId === userId
                );

                if (!userInfo) {
                  return <></>;
                }

                return (
                  <AttendeeVideoComponent
                    key={index}
                    loggedInUserIsPresenter={isPresenter}
                    socketId={
                      conferenceUserToSocketMapping[userInfo.conferenceUserId]
                    }
                    userId={userInfo.conferenceUserId}
                    videoComponentWrapperId={wrapperId}
                    isLocalClient={localStreamId === wrapperId}
                    disabledVideo={localDisabledVideo}
                    userName={userInfo.username}
                    mutedUsers={mutedUsers}
                  />
                );
              })}
              {participants &&
                participants
                  .filter(({ online }) => !online)
                  .map((participant, index) => (
                    <AttendeeOffline key={index} username={participant.name} />
                  ))}
            </ul>
          </div>
        </section>
        {tab === VideoConferenceTab.Chat && (
          <ChatComponent isPresenter={isPresenter} onSidebar={onSidebar} />
        )}
      </InPortal>

      {!isPresenter && (
        <VisitorAVControls
          muted={localMuted}
          disabledVideo={localDisabledVideo}
          onToggleDisableVideo={onToggleDisableVideo}
          onToggleMute={onToggleMute}
        />
      )}

      {inviteDialogOpened && (
        <InvitePeopleDialog onClose={onCloseInviteDialog} />
      )}
    </>
  );
};
