import { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import { useContext, useEffect, useRef } from "react";
import { WebSocketContext } from "../root/root-component";

const SuperHDTreshold = 1280 * 720;
const ReportToBackendInterval = 1000;

export const useAgoraStats = (client?: IAgoraRTCClient) => {
  const hd = useRef(0);
  const superHd = useRef(0);

  const wsApi = useContext(WebSocketContext);

  useEffect(() => {
    if (client) {
      let lastCheck = Date.now();
      const statsCheck = setInterval(() => {
        const now = Date.now();
        const dt = now - lastCheck;
        lastCheck = now;

        const allVideoStats = client.getRemoteVideoStats();
        const totalResolution = Object.keys(allVideoStats).reduce(
          (memo, uid) => {
            memo +=
              allVideoStats[uid].receiveResolutionHeight *
              allVideoStats[uid].receiveResolutionWidth;

            return memo;
          },
          0
        );

        if (totalResolution > 0) {
          if (totalResolution <= SuperHDTreshold) {
            hd.current += dt;
          } else {
            superHd.current += dt;
          }
        }

        if (wsApi && (hd.current || superHd.current)) {
          wsApi.reportAgora(
            Math.round(hd.current / 1000),
            Math.round(superHd.current / 1000)
          );
        }
      }, ReportToBackendInterval);

      return () => {
        clearInterval(statsCheck);
      };
    }
  }, [client, wsApi]);
};
