import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack
} from "agora-rtc-sdk-ng";
import { Logger as logger } from "purplex-logging";

import { WebSocketContext } from "../root/root-component";
import {
  getConferenceToken,
  getConferenceUserId
} from "../web-sockets/session-selectors";
import { getAgoraId } from "../config/config-selectors";
import { getConferenceVolume } from "../volume-control/volume-control-selectors";
import {
  MAX_PEOPLE_IN_CONFERENCE,
  PRESENTER_SCREENSHARING_VIEW,
  PRESENTER_SMALL_VIEW,
  VISITOR_VIEW
} from "./resolution-configs";
import { useAgoraStats } from "./use-agora-stats";

AgoraRTC.setLogLevel(4);

export const useAgora = (
  localStreamId: string,
  getRemoteStreamId: (id: number) => string,
  sessionId: string,
  screenShare: boolean,
  setScreenShare: (value: boolean) => void,
  localMuted: boolean,
  localDisabledVideo: boolean,
  isPresenter: boolean,
  presenterSharingScreen: boolean,
  presenterId: number
) => {
  const [initialized, setInitialized] = useState(false);
  const conferenceVolume = useSelector(getConferenceVolume);

  const audioVideoStateHolder = useRef({
    screenShare,
    localMuted,
    localDisabledVideo,
    presenterSharingScreen
  });
  useEffect(() => {
    audioVideoStateHolder.current = {
      screenShare,
      localMuted,
      localDisabledVideo,
      presenterSharingScreen
    };
  }, [screenShare, localMuted, localDisabledVideo, presenterSharingScreen]);

  const agoraId = useSelector(getAgoraId);

  const localAudioTrackHolder = useRef<ILocalAudioTrack>();
  const localVideoTrackHolder = useRef<ILocalVideoTrack>();
  const localScreenshareTrack = useRef<ILocalVideoTrack>();
  const clientRef = useRef<IAgoraRTCClient>();
  useEffect(() => {
    if (!agoraId) {
      throw new Error("Missing Agora ID");
    }

    clientRef.current = AgoraRTC.createClient({
      mode: "rtc",
      codec: "h264"
    });
    setInitialized(true);
  }, [setInitialized, agoraId]);

  useAgoraStats(clientRef.current);

  const conferenceVolumeHolder = useRef(conferenceVolume);
  const remoteUsers = useRef<IAgoraRTCRemoteUser[]>([]);
  const [remoteStreams, changeRemoteStreams] = useState<number[]>([]);
  const token = useSelector(getConferenceToken);
  const conferenceUserId = useSelector(getConferenceUserId);
  const wsApi = useContext(WebSocketContext);
  const client = clientRef.current;

  useEffect(() => {
    logger.debug("Changing local volume");
    remoteUsers.current?.forEach((remoteUser) => {
      remoteUser.audioTrack?.setVolume(conferenceVolume * 100);
    });
    conferenceVolumeHolder.current = conferenceVolume;
  }, [conferenceVolume]);

  useEffect(() => {
    const client = clientRef.current;
    if (!isPresenter && client) {
      if (presenterSharingScreen) {
        logger.debug("Unsubscribing every viwer because of screen sharing");
        remoteUsers.current.forEach((remoteUser) => {
          const id = parseInt(remoteUser.uid as string);
          if (remoteUser.hasVideo && presenterId !== id) {
            client.unsubscribe(remoteUser);
          }
        });
      } else {
        remoteUsers.current.forEach((remoteUser) => {
          if (remoteUser.hasVideo) {
            const id = parseInt(remoteUser.uid as string);
            client.subscribe(remoteUser, "video").then(() => {
              remoteUser.videoTrack?.play(getRemoteStreamId(id));
            });
          }
        });
      }
    }
  }, [presenterSharingScreen, isPresenter, getRemoteStreamId, presenterId]);

  const onTokenExpired = useCallback(() => {
    if (wsApi && clientRef.current) {
      wsApi?.regenerateConferenceToken();
    }
  }, [wsApi]);

  const safeStreamCamera = useCallback(() => {
    // This has to be called this way, because agora doesn't
    // accept async functions in event handlers
    // and we can't use lambda wrappers because of referential consistency
    (async () => {
      if (clientRef.current) {
        const client = clientRef.current;

        if (localScreenshareTrack.current) {
          logger.debug(
            "Unpublishing local screensharing track so that local camera can be published"
          );
          try {
            await client.unpublish(localScreenshareTrack.current);
          } catch (reason) {
            logger.error(
              "Could not unpublish local screensharing stream",
              reason
            );
          }

          localScreenshareTrack.current.close();
          localScreenshareTrack.current = undefined;
        }

        if (
          localVideoTrackHolder.current &&
          !audioVideoStateHolder.current.localDisabledVideo
        ) {
          logger.debug("Republishing local video stream");

          try {
            await client.publish(localVideoTrackHolder.current);
          } catch (reason) {
            logger.error("Could not publish local video track", reason);
          }
        }

        // Make sure screen share is disabled when streaming camera back
        setScreenShare(false);
      }
    })();
  }, [setScreenShare]);

  const safeStreamScreen = useCallback(
    async (screenVideoTrack: ILocalVideoTrack) => {
      if (clientRef.current) {
        const client = clientRef.current;

        if (localVideoTrackHolder.current) {
          logger.debug(
            "Unpublishing local video so that, screen share can be published"
          );
          try {
            await client.unpublish(localVideoTrackHolder.current);
          } catch (reason) {
            logger.error("Could not unpublish local video stream", reason);
          }
        }

        logger.debug("Publishing screensharing track");
        try {
          client.publish(screenVideoTrack);
        } catch (reason) {
          logger.error("Could not publish screen sharing video track", reason);
        }
        localScreenshareTrack.current = screenVideoTrack;
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (clientRef.current && !audioVideoStateHolder.current.screenShare) {
        const client = clientRef.current;
        if (localDisabledVideo && localVideoTrackHolder.current) {
          logger.debug(
            "Unpublishing local video track because video is disabled"
          );
          client.unpublish(localVideoTrackHolder.current).catch((reason) => {
            logger.error("Error while unpublishing local video track", reason);
          });
        } else if (!localDisabledVideo) {
          if (!localVideoTrackHolder.current) {
            logger.debug(
              "Requesting to publish local video again, but it's non-existing, re-trying to get local stream"
            );

            try {
              const localVideo = await AgoraRTC.createCameraVideoTrack();
              localVideoTrackHolder.current = localVideo;
              localVideo.play(localStreamId);
            } catch (ex) {
              logger.debug("Could not request camera track", ex);
            }
          }

          if (localVideoTrackHolder.current) {
            logger.debug(
              "Publishing local video track because video is enabled"
            );
            client.publish(localVideoTrackHolder.current).catch((reason) => {
              logger.error("Error while publishing local video track", reason);
            });
          }
        }
      }
    })();
  }, [localDisabledVideo, localStreamId]);

  useEffect(() => {
    if (clientRef.current) {
      const client = clientRef.current;
      if (localMuted && localAudioTrackHolder.current) {
        logger.debug("Unpublishing local audio track because audio is muted");
        client.unpublish(localAudioTrackHolder.current).catch((reason) => {
          logger.error("Error while unpublishing local audio track", reason);
        });
      } else if (!localMuted && localAudioTrackHolder.current) {
        logger.debug("Publishing local audio track because audio is unmuted");
        client.publish(localAudioTrackHolder.current).catch((reason) => {
          logger.error("Error while publishing local audio track", reason);
        });
      }
    }
  }, [localMuted]);

  useEffect(() => {
    let videoTrack: ILocalVideoTrack | null = null;

    if (screenShare) {
      logger.debug("Requested screen-sharing");

      AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: PRESENTER_SCREENSHARING_VIEW
        },
        "disable"
      )
        .then((screenVideoTrack) => {
          videoTrack = screenVideoTrack;
          videoTrack.on("track-ended", safeStreamCamera);

          safeStreamScreen(videoTrack);
        })
        .catch((ex) => {
          logger.debug("Couldn't get screen video track", ex);
          safeStreamCamera();
        });
    } else if (!screenShare) {
      safeStreamCamera();
    }

    return () => {
      if (videoTrack) {
        videoTrack.off("track-ended", safeStreamCamera);
      }
    };
  }, [safeStreamScreen, safeStreamCamera, screenShare]);

  const onUserJoined = useCallback(
    (remoteUser: IAgoraRTCRemoteUser) => {
      remoteUsers.current?.push(remoteUser);

      const id = parseInt(remoteUser.uid as string);
      changeRemoteStreams((streams) => {
        if (!streams.includes(id)) {
          return [...streams, id];
        } else {
          return streams;
        }
      });
    },
    [changeRemoteStreams]
  );

  const onUserPublished = useCallback(
    (remoteUser: IAgoraRTCRemoteUser, mediaType: "audio" | "video") => {
      (async () => {
        const client = clientRef.current;
        if (client) {
          if (client.uid) {
            const id = parseInt(remoteUser.uid as string);
            await client.subscribe(remoteUser, mediaType);
            if (
              mediaType === "video" &&
              remoteUser.videoTrack &&
              (!audioVideoStateHolder.current.presenterSharingScreen ||
                id === presenterId)
            ) {
              logger.debug(`Succefully subscribed to video ${id}`);
              remoteUser.videoTrack.play(getRemoteStreamId(id));
            }
            if (mediaType === "audio" && remoteUser.audioTrack) {
              logger.debug("Succefully subscribed to audio");
              remoteUser.audioTrack.play();
              remoteUser.audioTrack.setVolume(
                conferenceVolumeHolder.current * 100
              );
            }
          } else {
            logger.warn("Missing user ID");
          }
        }
      })();
    },
    [getRemoteStreamId, presenterId]
  );

  const onUserLeft = useCallback(
    (remoteUser: IAgoraRTCRemoteUser) => {
      if (clientRef.current) {
        const id = parseInt(remoteUser.uid as string);
        remoteUsers.current = remoteUsers.current?.filter(
          ({ uid }) => uid === remoteUser.uid
        );

        changeRemoteStreams((streams) =>
          streams.filter((stream) => stream !== id)
        );
      }
    },
    [changeRemoteStreams]
  );

  useEffect(() => {
    if (token && conferenceUserId && client && initialized && agoraId) {
      logger.debug("Inititing new join procedure");

      client.on("user-joined", onUserJoined);
      client.on("user-left", onUserLeft);
      client.on("user-published", onUserPublished);
      client.on("token-privilege-will-expire", onTokenExpired);

      (async () => {
        try {
          logger.debug("Joining agora coneference");
          logger.debug(
            "Max number of people  in conference is",
            MAX_PEOPLE_IN_CONFERENCE
          );
          await client.join(agoraId, sessionId, token, conferenceUserId);
          logger.debug(`User ${conferenceUserId} has just joined ${sessionId}`);

          const encoderConfig = isPresenter
            ? PRESENTER_SMALL_VIEW
            : VISITOR_VIEW;

          const localAudio = await AgoraRTC.createMicrophoneAudioTrack();

          try {
            const localVideo = await AgoraRTC.createCameraVideoTrack({
              encoderConfig
            });
            localVideoTrackHolder.current = localVideo;
            localVideo.play(localStreamId);
          } catch (ex) {
            logger.debug("Could not request camera track", ex);
          }

          logger.debug("Local stream initialized, playing");

          wsApi?.changeMuteState(true);

          localAudioTrackHolder.current = localAudio;
        } catch (error) {
          if (error === "DYNAMIC_KEY_EXPIRED") {
            logger.debug("Dynamic key expired, regenerating");
            onTokenExpired();
          } else {
            logger.error("Could not join the Agora conference", error);
          }
        }
      })();
    }

    return () => {
      const client = clientRef.current;

      if (client) {
        logger.debug("Disconnecting from Agora client");

        client.off("user-published", onUserPublished);
        client.off("user-left", onUserLeft);
        client.off("token-privilege-will-expire", onTokenExpired);

        if (localVideoTrackHolder.current) {
          localVideoTrackHolder.current.close();
        }
        if (localScreenshareTrack.current) {
          localScreenshareTrack.current.close();
        }
        if (localAudioTrackHolder.current) {
          localAudioTrackHolder.current.close();
        }

        client.leave().then(
          () => {
            logger.debug("Succesfuly left channel");
          },
          (ex) => {
            logger.error("Error during leaving conference", ex);
          }
        );
      }
    };
  }, [
    isPresenter,
    wsApi,
    client,
    agoraId,
    localStreamId,
    sessionId,
    initialized,
    token,
    conferenceUserId,
    onUserJoined,
    onUserPublished,
    onTokenExpired,
    onUserLeft
  ]);

  return remoteStreams;
};
