import React from "react";

interface AttendeeOfflineProps {
  username: string;
}

export const AttendeeOffline = ({ username }: AttendeeOfflineProps) => {
  const [nameInitials] = username;

  return (
    <li className="list-user__item is-disabled">
      <div className="list-user__item__title" data-clamp="1">
        {username}
      </div>
      <div className="list-user__item__preview">
        <span className="list-user__item__avatar">
          <span className="avatar">{nameInitials}</span>
        </span>
      </div>
    </li>
  );
};
