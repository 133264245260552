import querystring from "query-string";

export const redirectToLogin = (
  cmsUrl: string,
  clientId: string,
  state: object
) => {
  window.location.href = `${cmsUrl}/api/oauth/authorize?${querystring.stringify(
    {
      client_id: clientId,
      response_type: "code",
      redirect_uri: `${window.location.origin}/login/redirect`,
      state: JSON.stringify(state)
    }
  )}`;
};
