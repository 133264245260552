import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { useApi } from "../api/use-api";
import { useNotifications } from "../ux/notifications";
import { ScheduledSessionsForm } from "./scheduled-sessions-form";
import moment from "moment";

export const CreateScheduledSession = () => {
  const { presentationId } = useParams();
  const parsedPresentationId = parseInt(presentationId || "");

  const history = useHistory();
  const api = useApi();
  const notifications = useNotifications();

  const onSubmit = useCallback(
    async (values) => {
      const result = await api?.createScheduledSession({
        presentationId: parsedPresentationId,
        instanceUuid: values.instance_uuid,
        title: values.title,
        date: values.date,
        description: values.description,
        invitations: values.invitations,
        startTime: moment(values.date + " " + values.start_time).format(),
        endTime: moment(values.date + " " + values.end_time).format()
      });

      if (result && result.success) {
        history.push("/");
        notifications.success("Scheduled session created");
      } else {
        notifications.error("Scheduled session created");
      }
    },
    [api, history, parsedPresentationId]
  );

  return (
    <div className="container --narrow" style={{ marginTop: "10vh" }}>
      <div className="card --primary" style={{ padding: "2.5rem 3.5rem" }}>
        <h2 className="u-rhythm" style={{ marginBottom: "1rem" }}>
          Plan a session
        </h2>
        <ScheduledSessionsForm
          onSubmit={onSubmit}
          presentationId={parsedPresentationId}
        />
      </div>
    </div>
  );
};
