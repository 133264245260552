import { createSelector } from "@reduxjs/toolkit";
import { getSession } from "../root/root-selectors";
import {
  AdmissionStatus,
  SessionConnectionStatus
} from "../../../shared/domain/connections/connections-slice";
import {
  getWallHeightProjector,
  getWallWidthProjector
} from "../presentation/presentation-projectors";

export const getConnectionData = createSelector(
  getSession,
  (state) => state.connection
);

export const getOwnerData = createSelector(getSession, (state) => state.OWNER);

export const getChat = createSelector(getOwnerData, (state) => state?.chat);

export const getParticipants = createSelector(
  getOwnerData,
  (state) => state?.participants
);

export const getMutedUsers = createSelector(
  getOwnerData,
  (state) => state?.mutedUsers
);

export const getWallProgress = createSelector(
  getOwnerData,
  (state) => state?.wallProgress
);

export const getSceneAssetsProgress = createSelector(
  getOwnerData,
  (state) => state?.sceneAssetsProgress
);

export const getSessionId = createSelector(
  getOwnerData,
  (state) => state?.sessionState?.id
);

export const getSessionData = createSelector(
  getOwnerData,
  (state) => state?.sessionState
);

export const getAssetUrls = createSelector(
  getSessionData,
  (state) => state?.assetUrls
);

export const getConferenceState = createSelector(
  getSessionData,
  (state) => state?.conferenceState
);

export const getConnectionsData = createSelector(
  getOwnerData,
  (state) => state?.connections
);

export const getConnectionsWaitingForAdmission = createSelector(
  getConnectionsData,
  (state) =>
    state?.filter(
      (connection) =>
        connection.admissionStatus === AdmissionStatus.WAITING_FOR_ADMISSION
    )
);

export const getAdmittedConnections = createSelector(
  getConnectionsData,
  (state) =>
    state?.filter(
      (connection) =>
        connection.status === SessionConnectionStatus.JOINED_PRESENTATION ||
        connection.status === SessionConnectionStatus.OWNER
    )
);

export const getConferenceUserMappingToSocketId = createSelector(
  getConnectionsData,
  (connections) => {
    return (connections || []).reduce((memo, connection) => {
      memo[connection.conferenceUserId] = connection.socketId;
      return memo;
    }, {} as { [key: number]: string });
  }
);

export const getInvitations = createSelector(getSessionData, (state) => {
  if (state?.scheduled) {
    return state?.scheduledInvitations;
  } else {
    return state?.generatedLinks.links.map((link) => link.email);
  }
});

export const getGeneratedLinks = createSelector(getSessionData, (state) =>
  state?.generatedLinks.links.map((link) => link.link)
);

export const getWallHeight = createSelector(
  getOwnerData,
  getWallHeightProjector
);

export const getWallWidth = createSelector(getOwnerData, getWallWidthProjector);

export const getIsSessionScheduled = createSelector(
  getSessionData,
  (state) => state?.scheduled
);
