import { Asset, File, AssetType, UrlAsset } from "./types/domain";
import { Logger as logger } from "purplex-logging";
import request from "request";

const processedSuffix = "_vx_processed";

export const getSaneFormatName = (asset: Asset) => {
  if (asset.formatName) {
    return `.${asset.formatName}`;
  } else {
    switch (asset.type) {
      case AssetType.ASSET_PDF:
      case AssetType.ASSET_PRESENTATION:
        return ".pdf";
      case AssetType.ASSET_TEXT:
        return "";
      default:
        logger.error(`Unknown formatName for asset - ${asset.type}`);
        throw new Error(`Unknown formatName for asset - ${asset.type}`);
    }
  }
};

export const assetToUrl = (asset: Asset, domain = "") =>
  `${domain}/assets/${asset.uuid}_${asset.fileVersion}`;

export const getCdnAssetNameWithSuffix = (asset: Asset) => {
  let suffix = "";

  // Gifs/Apngs are converted to webm on CMS
  // therefore it makes sense to take the video file
  // instead of the original one.
  if (
    asset.playAs === AssetType.ASSET_VIDEO &&
    asset.type === AssetType.ASSET_IMAGE
  ) {
    suffix = ".webm";
  }

  return `${asset.uuid}_${asset.fileVersion}${suffix}`;
};

export const getAssetThumbnailUrl = (asset: Asset) =>
  `/assets/thumbnails/assets/${asset.uuid}_${asset.fileVersion}_thumb.jpg`;

export const getProcessedAssetDestination = (asset: Asset) =>
  `${asset.uuid}_${asset.fileVersion}${processedSuffix}`;

export const getProcessedAssetUrl = (asset: Asset) => {
  if (asset.playAs === AssetType.ASSET_URL) {
    return (asset as UrlAsset).metadata.url;
  } else {
    return `/assets/${asset.uuid}_${asset.fileVersion}${processedSuffix}`;
  }
};

export const getProcessedAssetSliceUrl = (
  file: File,
  slice: number,
  backgroundVersion: number,
  instanceUuid: string,
  sceneId?: number
) =>
  `/assets/${file.uuid}_${file.fileVersion}_slice_${slice}_${instanceUuid}${
    sceneId ? `_${sceneId}` : ""
  }_${backgroundVersion}${processedSuffix}`;

export const getProcessedAssetSliceDestination = (
  asset: Asset,
  slice: number,
  backgroundVersion: number,
  sceneId: number,
  instanceUuid: string
) =>
  `${asset.uuid}_${asset.fileVersion}_slice_${slice}_${instanceUuid}_${sceneId}_${backgroundVersion}${processedSuffix}`;

export const getAssetTempPath = (asset: Asset) =>
  `${process.env.ASSETS_TEMP_PATH}/${asset.uuid}_${
    asset.fileVersion
  }${getSaneFormatName(asset)}`;

export const getProcessedAssetTempPath = (asset: Asset) =>
  `${process.env.ASSETS_TEMP_PATH}/${asset.uuid}_${
    asset.fileVersion
  }${processedSuffix}${getSaneFormatName(asset)}`;

export const getAssetSliceTempPath = (
  asset: Asset,
  slice: number,
  backgroundVersion: number,
  sceneId: number,
  instanceUuid: string
) =>
  `${process.env.ASSETS_TEMP_PATH}/${asset.uuid}_${
    asset.fileVersion
  }_slice_${slice}_${instanceUuid}_${sceneId}_${backgroundVersion}${getSaneFormatName(
    asset
  )}`;

export const getProcessedAssetSliceTempPath = (
  asset: Asset,
  slice: number,
  backgroundVersion: number,
  sceneId: number,
  instanceUuid: string
) =>
  `${process.env.ASSETS_TEMP_PATH}/${asset.uuid}_${
    asset.fileVersion
  }_slice_${slice}_${instanceUuid}_${sceneId}_${backgroundVersion}${processedSuffix}${getSaneFormatName(
    asset
  )}`;

export const checkIfUrlWorks = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    request.head(url, (err, res) => {
      if (res && res.statusCode === 200) {
        logger.debug("Checking url - got 200");
        resolve(true);
      }
      logger.debug("Checking url - got error or error code");
      resolve(false);
    });
  });
};

export const getAssetFileName = (asset: Asset) => {
  return `${asset.uuid}_${asset.fileVersion}`;
};

export const getAssetFileNameWithSuffix = (asset: Asset) => {
  let suffix = "";
  // Gifs/Apngs are converted to webm on CMS
  // therefore it makes sense to take the video file
  // instead of the original one.
  if (
    asset.playAs === AssetType.ASSET_VIDEO &&
    asset.type === AssetType.ASSET_IMAGE
  ) {
    suffix = ".webm";
  }

  return `${getAssetFileName(asset)}${suffix}`;
};
