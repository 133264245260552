import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../api/use-api";
import {
  ScheduledSessionsForm,
  ScheduledSessionsFormData
} from "./scheduled-sessions-form";

import { Logger as logger } from "purplex-logging";
import { useNotifications } from "../ux/notifications";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { DialogAnswer } from "../modal/dialog";
import moment from "moment";
import { WebSocketContext } from "../root/root-component";

export const UpdateScheduledSession = React.memo(
  (props: { sessionId: string; versionChanged: boolean }) => {
    const { sessionId, versionChanged } = props;

    //TODO refactor typing with the use of camelCase conversion
    const [scheduledSession, setScheduledSession] = useState<any>();

    const api = useApi();
    const wsApi = useContext(WebSocketContext);
    const notifications = useNotifications();
    const history = useHistory();
    const confirm = useCreateConfirmationDialog();

    const fetchData = useCallback(async () => {
      if (sessionId) {
        const { data } = await api?.getScheduledSession(sessionId);
        setScheduledSession(data);
      }
    }, [api, sessionId]);

    useEffect(() => {
      (async () => {
        await fetchData();
      })();
    }, [fetchData, versionChanged]);

    const submit = useCallback(
      async (values: ScheduledSessionsFormData) => {
        if (scheduledSession) {
          try {
            await api?.updateScheduledSession(scheduledSession.uuid, {
              presentationId: scheduledSession.presentation_id,
              instanceUuid: values.instance_uuid,
              date: values.date,
              description: values.description,
              invitations: values.invitations,
              title: values.title,
              startTime: moment(values.date + " " + values.start_time).format(),
              endTime: moment(values.date + " " + values.end_time).format()
            });
            wsApi?.updateSessionData();
            notifications.success("Scheduled session updated");
            logger.debug("Scheduled session updated");

            await fetchData();
          } catch (e) {
            notifications.error("Error while updating scheduled session");
            logger.error(e);
          }
        }
      },
      [api, scheduledSession, fetchData, notifications, wsApi]
    );

    const sendInvitations = useCallback(async () => {
      if (scheduledSession) {
        try {
          await api?.sendInvitations(scheduledSession.uuid);
          logger.debug("Invitations sent");
          notifications.success("Invitations sent");
          await fetchData();
        } catch (e) {
          notifications.success("Error while sending invitations");
          logger.error(e);
        }
      }
    }, [api, scheduledSession, fetchData, notifications]);

    const deleteSession = useCallback(async () => {
      const answer = await confirm({
        title: "Are you sure you want to cancel session?",
        description: ""
      });
      if (answer !== DialogAnswer.OK) {
        return;
      }
      await api?.deleteScheduledSession(sessionId);
      history.push("/");
    }, [api, sessionId, history, confirm]);

    return (
      <>
        <h2>Session</h2>

        <ScheduledSessionsForm
          onSubmit={submit}
          presentationId={scheduledSession?.presentation_id as number}
          onSendInvitations={sendInvitations}
          onDeleteInvitations={deleteSession}
          initialValues={
            scheduledSession && {
              ...scheduledSession,
              start_time: moment(scheduledSession.start_time).format("HH:mm"),
              end_time: moment(scheduledSession.end_time).format("HH:mm"),
              invitations: scheduledSession.invitations.join(",")
            }
          }
        />
      </>
    );
  }
);
