import React from "react";
import cx from "classnames";
import { AssetType } from "shared/types/domain";

import { CachedImage } from "./cached-assets/cached-img";

export const ImageAsset = ({
  type,
  url,
  glow,
  cropOffsetY,
  cropOffsetX,
  cropHeight,
  cropWidth,
  width,
  height,
  isWall
}: {
  url: string;
  glow: boolean;
  type: AssetType;
  cropHeight?: number;
  cropWidth?: number;
  cropOffsetX?: number;
  cropOffsetY?: number;
  width?: number;
  height?: number;
  isWall?: boolean;
}) => (
  <div
    style={{
      width: isWall ? "100%" : cropWidth,
      height: cropHeight,
      overflow: isWall ? "auto" : "hidden",
      position: "absolute"
    }}
  >
    <CachedImage
      className={cx({ glow, [type]: true })}
      src={url}
      alt=""
      draggable="false"
      style={{
        width,
        height,
        maxWidth: "none",
        position: "relative",
        top: -(cropOffsetY || 0),
        left: -(cropOffsetX || 0)
      }}
    />
  </div>
);
