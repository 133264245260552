import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface LoadingIndicatorProps {
  withText?: boolean;
  size?: number;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const withText = props.withText !== undefined ? props.withText : false;
  const size = props.size !== undefined ? props.size : 2;

  return (
    <div className="u-flex u-align --middle" style={{ margin: "1rem 0" }}>
      <FontAwesomeIcon
        icon={["fad", "spinner-third"]}
        style={{ color: "#004bff", fontSize: `${size}rem`, marginRight: 8 }}
        spin
      />
      {withText && <span>Loading ...</span>}
    </div>
  );
};
