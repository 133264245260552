import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCameraHome as fadCameraHome,
  faCaretCircleLeft as fadCaretCircleLeft,
  faCaretCircleRight as fadCaretCircleRight,
  faCheckCircle as fadCheckCircle,
  faClipboardList as fadClipboardList,
  faCommentsAlt as fadCommentsAlt,
  faDesktop as fadDesktop,
  faExclamationTriangle as fadExclamationTriangle,
  faEye as fadEye,
  faFilter as fadFilter,
  faInfoCircle as fadInfoCircle,
  faMicrophone as fadMicrophone,
  faMicrophoneSlash as fadMicrophoneSlash,
  faPauseCircle as fadPauseCircle,
  faPlayCircle as fadPlayCircle,
  faSlash as fadSlash,
  faSpinnerThird as fadSpinnerThird,
  faTrashAlt as fadTrashAlt,
  faUndo as fadUndo,
  faUserCircle as fadUserCircle,
  faUserFriends as fadUserFriends,
  faUsersClass as fadUsersClass,
  faVideo as fadVideo,
  faVideoSlash as fadVideoSlash,
  faVolume as fadVolume,
  faVolumeMute as fadVolumeMute
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faAngleDown as falAngleDown,
  faAngleRight as falAngleRight
} from "@fortawesome/pro-light-svg-icons";
import {
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faCaretDown as fasCaretDown,
  faArrowCircleLeft as fasArrowCircleLeft,
  faArrowCircleRight as fasArrowCircleRight,
  faFilePdf as fasFilePdf,
  faFilter as fasFilter
} from "@fortawesome/pro-solid-svg-icons";
import {
  faUserCircle as farUserCircle,
  faImages as farImages,
  faLink as farLink,
  faMusic as farMusic
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  // Duotone
  fadCameraHome,
  fadCaretCircleLeft,
  fadCaretCircleRight,
  fadCheckCircle,
  fadClipboardList,
  fadCommentsAlt,
  fadDesktop,
  fadExclamationTriangle,
  fadEye,
  fadFilter,
  fadInfoCircle,
  fadMicrophone,
  fadMicrophoneSlash,
  fadPauseCircle,
  fadPlayCircle,
  fadSlash,
  fadSpinnerThird,
  fadTrashAlt,
  fadUndo,
  fadUserCircle,
  fadUserFriends,
  fadUsersClass,
  fadVideo,
  fadVideoSlash,
  fadVolume,
  fadVolumeMute,

  // Light
  falAngleDown,
  falAngleRight,

  // Solid
  fasAngleLeft,
  fasAngleRight,
  fasArrowCircleLeft,
  fasArrowCircleRight,
  fasFilePdf,
  fasFilter,

  //Regular
  farUserCircle,
  fasCaretDown,
  farImages,
  farLink,
  farMusic
);
