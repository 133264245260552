import * as PDFJS from "pdfjs-dist";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Logger as logger } from "purplex-logging";
import { AssetType } from "shared/types/domain";

PDFJS.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.js";

export const PdfAsset = ({
  url,
  page,
  glow
}: {
  url: string;
  page: number;
  width: number;
  height: number;
  glow: boolean;
}) => {
  const canvas = useRef<null | HTMLCanvasElement>(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [error, setError] = useState(false);
  const [document, setDocumnet] = useState<null | PDFJS.PDFDocumentProxy>(null);

  useEffect(() => {
    (async () => {
      try {
        setDocumnet(await PDFJS.getDocument(url).promise);
      } catch (ex) {
        setError(true);
        logger.error("Could not parser PDF", ex);
      }
    })();
  }, [url]);

  useEffect(() => {
    (async () => {
      const canvasContext = canvas.current?.getContext("2d");
      const documentPage = await document?.getPage(page);

      if (canvasContext && documentPage) {
        setDimensions({
          width: documentPage.view[2],
          height: documentPage.view[3]
        });
        logger.debug(`Rendering PDF page ${page}`);
        documentPage?.render({
          canvasContext,
          viewport: documentPage.getViewport({ scale: 1 })
        });
      }
    })();
  }, [page, canvas, document]);

  if (error) {
    return <div>Invalid PDF file.</div>;
  } else {
    return (
      <canvas
        className={cx({ glow, [AssetType.ASSET_PDF]: true })}
        ref={canvas}
        width={dimensions.width}
        height={dimensions.height}
      />
    );
  }
};
