import { Slide, toast } from "react-toastify";
import { useState } from "react";

type Notifications = {
  info: (message: string) => void;
  error: (message: string) => void;
  success: (message: string) => void;
  warn: (message: string) => void;
};

export const useNotifications = (): Notifications => {
  const options = {
    closeButton: false,
    hideProgressBar: true,
    transition: Slide
  };

  const [toastObject, _] = useState<Notifications>({
    info: (message: string) => {
      toast.info(message, options);
    },
    error: (message: string) => {
      toast.error(message, options);
    },
    success: (message: string) => {
      toast.success(message, options);
    },
    warn: (message: string) => {
      toast.warn(message, options);
    }
  });

  return toastObject;
};
