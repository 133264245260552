import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

import { SessionState } from "../../../../shared/domain/session/session-slice";
import ProgressIndicator from "../../ux/progress-indicator";
import { Logger as logger } from "purplex-logging";

export const AssetsStatus = (props: { sessionState: SessionState }) => {
  const { sessionState } = props;
  const { totalProgress } = sessionState;

  useEffect(() => {
    logger.debug(`Asset processing progress - ${totalProgress}`)
  }, [totalProgress]);
  return (
    <div className="card --tertiary">
      <h4>Processing presentation</h4>
      <p>
        We are processing your presentation, this process can take up to a day
        depending on the size of your presentation and the number of users
        waiting in line. You can come back at a later time, processing will
        continue after the window is closed.
      </p>

      <h5>{`Total progress: ${Math.floor(sessionState.totalProgress * 100)}% (${
        sessionState.totalNumberOfAssets - sessionState.processedAssets.length
      }/${sessionState.totalNumberOfAssets}) files`}</h5>
      <ProgressIndicator
        primary
        progress={Math.floor(sessionState.totalProgress * 100)}
        title="Preparing presentation"
      />
      {sessionState.processedAssets.every((asset) => !asset.active) && (
        <p
          className="message --warning --primary u-flex"
          style={{ marginTop: "1rem" }}
        >
          <FontAwesomeIcon
            icon={["fad", "exclamation-triangle"]}
            size="lg"
            swapOpacity
          />
          It is very busy in the moment. You are in queue.
        </p>
      )}
    </div>
  );
};
