import React, { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { DialogTemplate, DialogBody, DialogFooter } from "../../modal/dialog";
import { WebSocketContext } from "../../root/root-component";
import { ChipInput } from "../../ux/chip-input";
import { getIsSessionScheduled } from "../presenter-selectors";
import { PresentationLink } from "./presentation-link";

export const InvitePeopleDialog = (props: { onClose: () => void }) => {
  const { onClose } = props;

  const [emails, setEmails] = useState("");

  const isScheduled = useSelector(getIsSessionScheduled);

  const wsApi = useContext(WebSocketContext);

  const onSendInvites = useCallback(async () => {
    wsApi?.invitePeople(emails);
    setEmails("");
    onClose();
  }, [emails, wsApi, onClose]);

  const scheduledSessionContent = (
    <>
      <DialogBody>
        <ChipInput
          name="Email"
          onChange={setEmails}
          placeholder="Email"
          value={emails}
          uniqueValues={true}
        />
      </DialogBody>
      <DialogFooter>
        <button
          type="button"
          className="button --primary"
          onClick={onSendInvites}
        >
          Send invite
        </button>
      </DialogFooter>
    </>
  );

  const testRunContent = (
    <DialogBody>
      <div style={{ display: "grid", height: "100%", placeItems: "center" }}>
        <PresentationLink />
      </div>
    </DialogBody>
  );

  return (
    <DialogTemplate title="Invite people" onClose={onClose}>
      {isScheduled ? scheduledSessionContent : testRunContent}
    </DialogTemplate>
  );
};
