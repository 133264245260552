import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Logger as logger } from "purplex-logging";
import { ScheduledSessionListItem } from "../../../shared/types/api-types";
import { ScheduledSessionModel } from "../../../shared/types/scheduled-session";
import "../../assets/stylesheets/views/session.scss";
import { useApi } from "../api/use-api";
import { DialogAnswer } from "../modal/dialog";
import { useCreateConfirmationDialog } from "../modal/dialog-context";
import { LoadingIndicator } from "../ux/loading-indicator";

export const ScheduledSessionsList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduledSessions, setScheduledSessions] = useState<
    ScheduledSessionListItem[]
  >([]);

  const api = useApi();
  const history = useHistory();
  const confirm = useCreateConfirmationDialog();

  const fetchList = useCallback(async () => {
    setLoading(true);
    logger.debug("Loading scheduled sessions");
    const localScheduledSessions = await api?.listScheduledPresentations();
    setScheduledSessions(
      localScheduledSessions.data.sort(
        (a: ScheduledSessionModel, b: ScheduledSessionModel) =>
          new Date(a.date) > new Date(b.date) ? 1 : -1
      )
    );
    logger.debug("Loading finished");
    setLoading(false);
  }, [api]);

  useEffect(() => {
    (async () => {
      await fetchList();
    })();
  }, [fetchList]);

  const deleteSession = useCallback(
    async (sessionId: string) => {
      const answer = await confirm({
        title: "Are you sure you want to cancel session?",
        description: ""
      });
      if (answer !== DialogAnswer.OK) {
        return;
      }
      await api?.deleteScheduledSession(sessionId);
      await fetchList();
      history.push("/");
    },
    [api, history, confirm, fetchList]
  );

  return (
    <div className="container --narrow" data-view="session">
      <section
        className="card --primary"
        data-session="card"
        data-qa="card-session-list"
      >
        <header className="card__head">
          <h2 className="card__title">Available Presentations</h2>
        </header>

        <div
          className="card__body"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <table className="table" data-session="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Name</th>
                    <th colSpan={2}>Valid till</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduledSessions &&
                    scheduledSessions.map((session) => {
                      return (
                        <tr
                          key={session.uuid}
                          onClick={() => {
                            history.push(
                              `/presentation/authenticated/${session.uuid}`
                            );
                          }}
                          data-qa={`tr-${session.title}-session`}
                        >
                          <td data-qa="td-status">
                            <span
                              className={`label ${cx({
                                "--danger": session.status === "ERROR",
                                "--info": session.status === "PROCESSING",
                                "--success": session.status === "READY"
                              })}`}
                              data-qa="span-status"
                            >
                              {session.status}
                            </span>
                          </td>
                          <td data-qa="td-name">
                            <span
                              className="u-flex u-justify --between u-align --middle"
                              data-qa="span-title"
                            >
                              {session.title}
                              {session.presentationMissing && (
                                <span
                                  title="Delete Session"
                                  className="label --danger --primary stamp"
                                  data-session="stamp"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteSession(session.uuid);
                                  }}
                                  data-qa="span-missing-presentation"
                                >
                                  Presentation missing in CMS
                                  <FontAwesomeIcon
                                    icon={["fad", "trash-alt"]}
                                    size="2x"
                                  />
                                </span>
                              )}
                            </span>
                          </td>
                          <td data-qa="td-valid">{session.date}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={["fal", "angle-right"]}
                              size="2x"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </section>
    </div>
  );
};
