import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import * as ValidateLib from "validate.js";

import { processEmailString } from "../../../shared/utils";

import { ReactComponent as IconClose } from "client/assets/images/16/close-circle.svg";
import { noop } from "rxjs";

const sanitizeString = (value: string) => {
  return value.replace(/ /g, "").replace(/;/g, "").replace(/,/g, "");
};

const endsWithSeparator = (value: string) => {
  return value.endsWith(" ") || value.endsWith(",") || value.endsWith(";");
};

export const ChipInput = (props: {
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  uniqueValues?: boolean;
}) => {
  const { placeholder, value, onChange, uniqueValues } = props;

  const [localValues, setLocalValues] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !endsWithSeparator(e.target.value) ||
        !inputRef.current ||
        sanitizeString(e.target.value) === ""
      ) {
        return;
      }

      if (uniqueValues) {
        if (!localValues.includes(sanitizeString(e.target.value))) {
          onChange(`${localValues},${sanitizeString(e.target.value)}`);
        }

        inputRef.current.value = "";
        return;
      }

      onChange(`${localValues},${sanitizeString(e.target.value)}`);
      inputRef.current.value = "";
    },
    [localValues, inputRef, onChange, uniqueValues]
  );

  const removeItemHandler = useCallback(
    (removedItem: string) => {
      onChange(localValues.filter((item) => item !== removedItem).join(","));
    },
    [onChange, localValues]
  );

  const onKeyDownHandler = useCallback(
    (e) => {
      const isInputEmpty = inputRef.current?.value === "";

      if (e.key === "Backspace" && isInputEmpty) {
        onChange(localValues.slice(0, localValues.length - 1).join(","));
      }
      if (e.key === "Enter" && inputRef.current) {
        if (uniqueValues) {
          if (!localValues.includes(sanitizeString(inputRef.current.value))) {
            onChange(
              `${localValues},${sanitizeString(inputRef.current.value)}`
            );
          }

          inputRef.current.value = "";
          return;
        }

        onChange(`${localValues},${sanitizeString(inputRef.current.value)}`);
        inputRef.current.value = "";
      }
    },
    [onChange, localValues, uniqueValues]
  );

  const onFocusHandler = useCallback(noop, []);

  const onBlurHandler = useCallback(() => {
    if (inputRef.current) {
      if (uniqueValues) {
        if (!localValues.includes(sanitizeString(inputRef.current.value))) {
          onChange(`${localValues},${sanitizeString(inputRef.current.value)}`);
        }

        inputRef.current.value = "";
        return;
      }

      onChange(`${localValues},${sanitizeString(inputRef.current.value)}`);
      inputRef.current.value = "";
    }
  }, [onChange, inputRef, localValues, uniqueValues]);

  useEffect(() => {
    setLocalValues(processEmailString(value));
  }, [value]);

  return (
    <>
      <div className="tag-group --start">
        {value &&
          localValues.map((item, index) => (
            <EmailChip
              title={item}
              onRemove={() => removeItemHandler(item)}
              key={index}
            />
          ))}
      </div>
      <input
        className="form-element --transparent"
        placeholder={placeholder}
        ref={inputRef}
        onChange={onInputChange}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
      />
    </>
  );
};

interface EmailChipProps {
  onRemove?: () => void;
  title: string;
}

export const EmailChip: React.FC<EmailChipProps> = ({
  onRemove,
  title
}): JSX.Element => {
  const isInvalid = ValidateLib.validate(
    { from: title },
    {
      from: {
        email: true
      }
    }
  );

  return (
    <span
      className={cx("tag", {
        "--danger": isInvalid,
        "--success": !isInvalid
      })}
    >
      <span data-clamp="1">{title}</span>
      <button type="button" className="tag__control" onClick={onRemove}>
        <IconClose />
      </button>
    </span>
  );
};
