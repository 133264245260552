import React from "react";

export const BytesRenderer = ({ value }: { value: number }) => {
  if (value) {
    const units = ["Bytes", "kB", "MB", "GB", "TB"];
    const i = Math.min(
      parseInt(Math.floor(Math.log(value) / Math.log(1024)).toString(), 10),
      units.length - 1
    );
    return <>{`${(value / 1024 ** i).toFixed(i ? 2 : 0)} ${units[i]}`}</>;
  }
  return <>N/A</>;
};
