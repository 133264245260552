import { createSelector } from "@reduxjs/toolkit";

import { getAuth } from "../root/root-selectors";
import { Logger as logger } from "purplex-logging";

export const getToken = createSelector(getAuth, (auth) => {
  logger.debug("Getting token");
  return auth.token;
});

export const getUserFullName = createSelector(getAuth, (auth) => {
  if (auth.user) {
    return `${auth.user.firstName} ${auth.user.lastName}`;
  } else {
    return "";
  }
});

export const getUserId = createSelector(
  getAuth,
  (auth) => auth.user && auth.user.id
);
