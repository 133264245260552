import React, { useState, useEffect, useCallback } from "react";
import { Logger as logger } from "purplex-logging";
import { SceneTransition } from "shared/domain/presentation/presentation-selectors";
import { BackgroundComponent } from "./presentation-components/background-component";
import { useGetApiOrMock } from "./use-get-api-or-mock";

export const PresentationSceneSwitchOverlay = ({
  transition,
  wallNumber,
  volume,
  authenticated
}: {
  transition?: SceneTransition;
  wallNumber: number;
  volume: number;
  authenticated?: boolean;
}) => {
  const [
    livingTransition,
    setLivingTransition
  ] = useState<null | SceneTransition>(null);
  const [opacity, setOpacity] = useState(0);
  const [isActiveLayer, setIsActiveLayer] = useState(false);

  useEffect(() => {
    logger.debug(`Transition has changed ${transition}`);

    let inDOMTimeoutTask: null | NodeJS.Timeout = null;

    if (transition) {
      setIsActiveLayer(true);
      setLivingTransition(transition);

      // Change opacity in next render cycle -
      // Just so we are sure animation is setup properly
      setTimeout(() => {
        setOpacity(1);
      });
    } else {
      setOpacity(0);

      // The effect should not rely on transition time
      // because we want the value when the transition was setup
      if (livingTransition) {
        inDOMTimeoutTask = setTimeout(() => {
          setIsActiveLayer(false);
        }, livingTransition.transitionTime / 2);
      }
    }

    return () => {
      if (inDOMTimeoutTask) {
        clearTimeout(inDOMTimeoutTask);
        inDOMTimeoutTask = null;
      }
    };
      // eslint-disable-next-line
  }, [transition]);

  const api = useGetApiOrMock(authenticated);

  const onTimeUpdated = useCallback(
    (assetUuid: string, value: number, duration: number) => {
      api?.changePlayback(value, duration, undefined, assetUuid);
    },
    [api]
  );

  const onPaused = useCallback(
    (assetUuid: string) => {
      api?.videoPaused(undefined, assetUuid);
    },
    [api]
  );
  const onPlaying = useCallback(
    (assetUuid: string) => {
      logger.debug("Playing scene transition video");
      api?.videoPlaying(undefined, assetUuid);
    },
    [api]
  );

  return (
    <div
      className="sceneSwitchOverlay"
      style={{
        zIndex: isActiveLayer ? 1 : 0,
        transition: livingTransition
          ? `opacity ${livingTransition.transitionTime / 2}ms`
          : undefined,
        opacity
      }}
    >
      {isActiveLayer && livingTransition && livingTransition.background && (
        <BackgroundComponent
          wallNumber={wallNumber}
          background={livingTransition.background}
          muted={false}
          volume={volume}
          onTimeUpdated={onTimeUpdated}
          onPaused={onPaused}
          onPlaying={onPlaying}
        />
      )}
    </div>
  );
};
